import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import {
  API_GENDERS_MAP,
  API_NON_BINARY_BODY_TEMPLATE_MAP,
  HTTP_ENDPOINTS,
  QUERY_KEYS,
} from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  clientId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),

  fullName: yup.string().required(),

  firstName: yup.string().required(),

  lastName: yup.string().required(),

  dateOfBirth: yup.string().nullable(),

  emails: yup.array().of(yup.string()).required(),

  email: yup.string().nullable(),

  phones: yup.array().of(yup.string()).required(),

  phone: yup.string().nullable(),

  pinCode: yup.string().nullable(),

  canAddCreditCard: yup.bool().required(),

  gender: yup.string().nullable(),

  nonBinaryBodyTemplate: yup.string().nullable(),

  creditCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        cardNumber: yup.string().required(),
        billingZip: yup.string().nullable(),
      }),
    )
    .required(),

  clinicId: yup.number().nullable(),

  address1: yup.string().nullable(),

  city: yup.string().nullable(),

  state: yup.string().nullable(),

  profileImageUrl: yup.string().nullable(),
});

const composeResponse = (res) => {
  const client = res?.data?.data || {};
  const cards = client?.card_on_files || [];

  return {
    id: client.id,

    fullName: client.full_name,

    firstName: client.firstname,

    lastName: client.lastname,

    dateOfBirth: client.date_of_birth || null,

    emails: [
      client.email,
      client.email_2,
      client.email_3,
      client.email_4,
    ].filter(Boolean),

    email: client.email || null,

    phones: [
      client.phoneNumber,
      client.phoneNumber_2,
      client.phoneNumber_3,
      client.phoneNumber_4,
    ].filter(Boolean),

    phone: client.phoneNumber || null,

    pinCode: client.pincode || null,

    canAddCreditCard: Boolean(client.can_add_card),

    gender: API_GENDERS_MAP?.[client.gender] || null,

    nonBinaryBodyTemplate:
      API_NON_BINARY_BODY_TEMPLATE_MAP?.[client.non_binary_body_template] ||
      null,

    creditCards: cards?.map((card) => ({
      id: card.id,
      cardNumber: card.card_number,
      billingZip: card.billing_zip || null,
    })),

    clinicId: client.clinic_id,

    address1: client.address_line_1 || null,

    city: client.city || null,

    state: client.state || null,

    profileImageUrl: client.user_image_url || null,
  };
};

function createClientQueryKey({ clientId }) {
  return [QUERY_KEYS.client, clientId];
}

export function useClientQuery(payload, options = {}) {
  return useQuery(
    createClientQueryKey({
      clientId: payload?.clientId,
    }),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(HTTP_ENDPOINTS.getClient(req.clientId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
