import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { packedMapOr, removeNullishFromShape } from "../../utilities/general";
import { composeServerDate } from "../../utilities/api";

export const PROCEDURE_TYPE = {
  cosmetic: "cosmetic",
  health: "health",
};

export const PROCEDURE_CREATED_FROM = {
  ePrescribe: "ePrescribe",
};

const requestSchema = yup.object({
  id: yup.number().nullable(),
  createdFrom: yup.string().nullable(),
  patientId: yup.number().required(),
  procedureType: yup.string().required(),
  appointmentId: yup.number().nullable(),
  serviceId: yup.number().nullable(),
  providerId: yup.number().required(),
  clinicId: yup.number().required(),
  procedureArea: yup.string().nullable(),
  procedureName: yup.string().nullable(),
  procedureDate: yup.mixed().test({
    test: (v) =>
      typeof v === "string" ||
      v instanceof Date ||
      v === null ||
      v === undefined,
  }),
  images: yup
    .object({
      front: yup.string().nullable(),
      image45: yup.string().nullable(),
      image45Left: yup.string().nullable(),
      image45Right: yup.string().nullable(),
      image90: yup.string().nullable(),
      image90Left: yup.string().nullable(),
      image90Right: yup.string().nullable(),
      back: yup.string().nullable(),
      back45Left: yup.string().nullable(),
      back45Right: yup.string().nullable(),
    })
    .nullable(),
  afterImages: yup
    .object({
      front: yup.string().nullable(),
      image45Left: yup.string().nullable(),
      image45Right: yup.string().nullable(),
      image90Left: yup.string().nullable(),
      image90Right: yup.string().nullable(),
      back: yup.string().nullable(),
      back45Left: yup.string().nullable(),
      back45Right: yup.string().nullable(),
    })
    .nullable(),
  questionnaireIds: yup.array().of(yup.number()),
  consentIds: yup.array().of(yup.number()),
  notes: yup.string().nullable(),
  type: yup.number().nullable(),
  patientImage: yup.string().nullable(),
});

export function useProcedureCreateUpdateMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.post(
        HTTP_ENDPOINTS.procedure.create(),
        removeNullishFromShape({
          id: req.id,
          created_from: req.createdFrom,
          procedure_name: req.procedureName,
          clinic_id: req.clinicId,
          user_id: req.providerId,
          patient_id: req.patientId,
          procedure_type: req.procedureType,
          appointment_id: req.appointmentId,
          service_id: req.serviceId,
          note: req.notes,
          type: req.procedureArea,
          procedure_after_photo: packedMapOr(
            removeNullishFromShape({
              patient_image_left_45: req.afterImages?.image45Left,
              patient_image_right_45: req.afterImages?.image45Right,
              patient_image_front: req.afterImages?.front,
              patient_image_left: req.afterImages?.image90Left,
              patient_image_right: req.afterImages?.image90Right,
              patient_image_back: req.afterImages?.back,
              patient_image_back_left_45: req.afterImages?.back45Left,
              patient_image_back_right_45: req.afterImages?.back45Right,
            }),
            null,
          ),
          procedure_images: packedMapOr(
            removeNullishFromShape({
              patient_image_front: req.images?.front,
              patient_image_left_45: req.images?.image45Left,
              patient_image_right_45: req.images?.image45Right,
              patient_image_left: req.images?.image90Left,
              patient_image_right: req.images?.image90Right,
              patient_image_back: req.images?.back,
              patient_image_back_left_45: req.images?.back45Left,
              patient_image_back_right_45: req.images?.back45Right,
            }),
            null,
          ),
          consent_ids: req.consentIds,
          questionnaire_ids: req.questionnaireIds,
          procedure_image_45: req.images?.image45,
          procedure_image: req.images?.image90,
          procedure_date: req.procedureDate
            ? composeServerDate(req.procedureDate, true)
            : null,
          charting_category_id: req.type || null,
          patient_image: req.patientImage || null,
        }),
      );
    },
    {
      ...options,
      onSuccess: (...args) => {
        [
          QUERY_KEYS.procedure,
          QUERY_KEYS.patientProcedures,
          QUERY_KEYS.patientConsents,
          QUERY_KEYS.patientQuestionnaires,
          QUERY_KEYS.patientUpcomingAppointments,
          QUERY_KEYS.servicesWithRelatedConsentsAndQuestionnaires,
          QUERY_KEYS.patientQuestionnaires.getByEntity,
          QUERY_KEYS.patientConsents.getByEntity,
        ].forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          });
        });

        options?.onSuccess?.(...args);
      },
    },
  );
}
