import React from "react";
import PropTypes from "prop-types";
import classes from "./Question.module.scss";
import { AnswerChoices } from "./shared/AnswerChoices/AnswerChoices";
import {
  MULTI_QUESTION_TYPES,
  SIMPLE_QUESTION_TYPES,
} from "../../../../api/questionnaires/config";
import { QUESTIONNAIRE_TYPES } from "../../../../consts/api";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Footer } from "./shared/Footer/Footer";
import { AnswerText } from "./shared/AnswerText/AnswerText";
import { ChoiceImage } from "./shared/ChoiceImage/ChoiceImage";
import { AnswerScale } from "./shared/AnswerScale/AnswerScale";
import { AnswerFile } from "./shared/AnswerFile/AnswerFile";
import { QUESTIONNAIRE_YES_NO_ANSWERS } from "../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";

export function Question({
  question,
  answer,
  onNext,
  onPrev,
  onFinishLater,
  updateAnswer,
  canGoNext,
  canGoPrev,
  isSubmitting,
  isQuestionnaireFilled,
}) {
  const { tCommon } = useAppTranslation.Common();

  const yesNoOptions = [
    {
      value: QUESTIONNAIRE_YES_NO_ANSWERS.yes,
      slot: tCommon("label.yes"),
    },
    {
      value: QUESTIONNAIRE_YES_NO_ANSWERS.no,
      slot: tCommon("label.no"),
    },
  ];

  const renderFooter = (onSubmit, isQuestionNotFilled, onFinishLater) => (
    <Footer
      onPrev={onPrev}
      onNext={onSubmit}
      onFinishLater={onFinishLater}
      isSubmitVisible={!canGoNext}
      isPrevDisabled={!canGoPrev}
      isSubmitting={isSubmitting}
      isQuestionNotFilled={isQuestionNotFilled}
      isQuestionnaireFilled={isQuestionnaireFilled}
    />
  );

  const submitAnswer = (answerValuesUpdater, options = {}) => {
    const nextAnswers = answerValuesUpdater();
    if (options.saveForLater) {
      onFinishLater(nextAnswers);
    } else {
      onNext(nextAnswers);
    }
  };

  const answerComponentsMap = {
    [QUESTIONNAIRE_TYPES.simple]: {
      [SIMPLE_QUESTION_TYPES.yesNo]: (
        <AnswerChoices
          isMulti={false}
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
            reason: answer?.reason,
          }}
          options={yesNoOptions}
          renderFooter={renderFooter}
          onSubmit={({ choices, reason }, options) => {
            submitAnswer(() => updateAnswer({ choices, reason }), options);
          }}
        />
      ),
      [SIMPLE_QUESTION_TYPES.multiText]: (
        <AnswerChoices
          isMulti={question.isMultiSelection}
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
          }}
          options={question.choices?.map((choice) => ({
            value: choice.text,
            slot: choice.text,
          }))}
          renderFooter={renderFooter}
          onSubmit={({ choices }, options) => {
            submitAnswer(() => updateAnswer({ choices }), options);
          }}
        />
      ),
      [SIMPLE_QUESTION_TYPES.multiImage]: (
        <AnswerChoices
          isMulti={question.isMultiSelection}
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
          }}
          options={question.choices?.map((c) => ({
            value: String(c.imageUrl),
            slot: (
              <ChoiceImage
                url={c.imageUrl}
                label={question.isImageLabelsVisible ? c.imageLabel : undefined}
              />
            ),
          }))}
          renderFooter={renderFooter}
          onSubmit={({ choices }, options) => {
            submitAnswer(() => updateAnswer({ choices }), options);
          }}
        />
      ),
    },
    [QUESTIONNAIRE_TYPES.multi]: {
      [MULTI_QUESTION_TYPES.yesNo]: (
        <AnswerChoices
          isMulti={false}
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
            reason: answer?.reason,
          }}
          options={yesNoOptions}
          renderFooter={renderFooter}
          onSubmit={({ choices, reason }, options) => {
            submitAnswer(() => updateAnswer({ choices, reason }), options);
          }}
        />
      ),
      [MULTI_QUESTION_TYPES.textBox]: (
        <AnswerText
          isRequired={question.isRequired}
          initialValues={{
            text: answer?.choices?.[0],
          }}
          renderFooter={renderFooter}
          onSubmit={({ text }, options) => {
            submitAnswer(() => updateAnswer({ choices: [text] }), options);
          }}
        />
      ),
      [MULTI_QUESTION_TYPES.singleChoice]: (
        <AnswerChoices
          isMulti={false}
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
          }}
          renderFooter={renderFooter}
          options={question.choices?.map((choice) => ({
            value: choice.text,
            slot: choice.text,
          }))}
          onSubmit={({ choices }, options) => {
            submitAnswer(() => updateAnswer({ choices }), options);
          }}
        />
      ),
      [MULTI_QUESTION_TYPES.multiChoice]: (
        <AnswerChoices
          isMulti
          isRequired={question.isRequired}
          initialValues={{
            choices: answer?.choices,
          }}
          renderFooter={renderFooter}
          options={question.choices?.map((choice) => ({
            value: choice.text,
            slot: choice.text,
          }))}
          onSubmit={({ choices }, options) => {
            submitAnswer(() => updateAnswer({ choices }), options);
          }}
        />
      ),
      [MULTI_QUESTION_TYPES.opinionScale]: (
        <AnswerScale
          withReason={question.isCommentsOnScaleAllowed}
          isRequired={question.isRequired}
          initialValues={{
            choice: answer?.choices?.[0],
            reason: answer?.reason,
          }}
          renderFooter={renderFooter}
          options={question.choices?.map((c) => ({
            label: c.text,
            value: c.text,
          }))}
          onSubmit={({ choice, reason }, options) => {
            submitAnswer(
              () =>
                updateAnswer({
                  choices: [choice],
                  reason,
                }),
              options,
            );
          }}
        />
      ),
      [MULTI_QUESTION_TYPES.fileUpload]: (
        <AnswerFile
          isRequired={question.isRequired}
          initialValues={{
            file: answer?.choices?.[0],
          }}
          renderFooter={renderFooter}
          onSubmit={({ file }, options) => {
            submitAnswer(() => updateAnswer({ choices: [file] }), options);
          }}
        />
      ),
    },
  };

  const answerComponent =
    answerComponentsMap[question.questionnaireType]?.[question.type];

  return (
    <div className={classes.root}>
      {answerComponent ? (
        <>
          <div>
            <h3 className={classes.question}>
              {question.question}
              {question.isRequired && <span>*</span>}
            </h3>
            {question.isDescriptionVisible && question.description && (
              <p className={classes.description}>{question.description}</p>
            )}
          </div>
          {answerComponent}
        </>
      ) : (
        <AlertBox>
          {tCommon("fillPatientQuestionnaire.error.noQuestion")}
        </AlertBox>
      )}
    </div>
  );
}

Question.propTypes = {
  question: PropTypes.object.isRequired,
  answer: PropTypes.object,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  updateAnswer: PropTypes.func.isRequired,
  canGoPrev: PropTypes.bool.isRequired,
  canGoNext: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
