import { usePatientQuestionnaireQuery } from "../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireQuery";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";

export function usePatientQuestionnaire({
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
}) {
  const { tCommon } = useAppTranslation.Common();

  const { data: patientQuestionnaire, isFetching } =
    usePatientQuestionnaireQuery({
      payload: {
        patientQuestionnaireId: patientQuestionnaireId,
      },
      options: {
        enabled:
          Boolean(patientQuestionnaireId) && !preloadedPatientQuestionnaire,
        onError: () => {
          uiNotification.error(tCommon("error.fetchQuestionnaire"));
        },
      },
    });

  return {
    data: preloadedPatientQuestionnaire || patientQuestionnaire,
    isLoading: isFetching,
  };
}
