import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useDictation } from "./hooks/useDictation";
import { MicMuteIcon } from "../../assets/Icons/MicMuteIcon";
import { MicIcon } from "../../assets/Icons/MicIcon";
import classes from "./Dictation.module.scss";
import { VoiceIcon } from "../../assets/Icons/VoiceIcon";

export function Dictation({ value, onChange }) {
  const { tCommon } = useAppTranslation.Common();

  const dictation = useDictation({
    text: value,
    onReadText: onChange,
  });

  const preparedPreviewText = (() => {
    if (dictation.partialText) {
      return dictation.partialText.trim();
    }
    return "";
  })();

  return (
    <div>
      <Button
        variant="outlined"
        color={dictation.isRecording ? "error" : undefined}
        leftAdornment={dictation.isRecording ? <MicMuteIcon /> : <MicIcon />}
        onClick={dictation.onToggle}
      >
        {dictation.isRecording
          ? tCommon("label.stopDictation")
          : tCommon("label.startDictation")}
      </Button>
      <div className={classes.content}>
        {preparedPreviewText ? (
          <div className={classes.valueWrap}>
            {dictation.isRecording && (
              <div className={cx(classes.dot, classes.mt5)} />
            )}
            {preparedPreviewText}
          </div>
        ) : (
          <div>
            {dictation.isRecording ? (
              <div className={classes.placeholderRecording}>
                <div className={classes.dot} />
                <div>{tCommon("label.recording")}...</div>
              </div>
            ) : (
              <div className={classes.placeholder}>
                <VoiceIcon width="21px" />
                {preparedPreviewText || tCommon("label.dictationPlaceholder")}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

Dictation.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
