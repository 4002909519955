import { Maybe } from "../../utilities/fp";
import { DEFAULT_MATCHED_PATTERN } from "./config";

/**
 * @typedef {{
 *   label: string;
 *   description: string | null;
 *   value: string | number;
 *}} SubstituteItem
 *
 * @typedef {{
 *    match: string;
 *    substitutes: SubstituteItem[]
 * }} Pattern
 */

/**
 * @param {string} text
 * @param {string} match
 * @returns {boolean}
 */
export function isPatternPresent(text, match) {
  return text.lastIndexOf(match) !== -1;
}

/**
 * @param {string} text
 * @param {string} match
 * @param {string | number} valueForSubstitution
 * @returns {string}
 */
export function substituteByMatch(text, match, valueForSubstitution) {
  if (isPatternPresent(text, match)) {
    const lastIndexOfPattern = text?.lastIndexOf(match);
    if (lastIndexOfPattern !== -1) {
      return (
        text.slice(0, lastIndexOfPattern) +
        valueForSubstitution +
        text.slice(lastIndexOfPattern + match.length)
      );
    }
  }
  return text;
}

/**
 * @param {string} text
 * @param {Pattern[]} patterns
 * @returns {Pattern | null}
 */
export function getMatchedPattern(text, patterns) {
  if (patterns?.length > 0 && text) {
    const nextMatchedPatterns = patterns.filter((p) => {
      return isPatternPresent(text, p.match);
    });

    return (
      nextMatchedPatterns?.[nextMatchedPatterns.length - 1] ||
      DEFAULT_MATCHED_PATTERN
    );
  }

  return DEFAULT_MATCHED_PATTERN;
}

/**
 * @param {string} text
 * @param {string} match
 * @returns {string}
 */
export function getSubstitutionSearchTerm(text, match) {
  const lastIndexOfPattern = text?.lastIndexOf(match);

  if (lastIndexOfPattern !== -1) {
    return Maybe.of(text)
      .map((x) => x.slice(lastIndexOfPattern))
      .map((x) => x.split(match))
      .map((x) => x[x.length - 1])
      .map((x) => x.split(/\s/))
      .map((x) => x[0])
      .map((x) => x.toLowerCase())
      .orElse("")
      .value();
  }

  return "";
}

/**
 * @param {SubstituteItem} substituteItem
 * @param {string} searchTerm
 * @returns {boolean}
 */
export function substitutesFilterPredicate(substituteItem, searchTerm) {
  return Boolean(
    substituteItem.label.toLowerCase().includes(searchTerm) ||
      substituteItem?.description?.toLowerCase()?.includes(searchTerm),
  );
}
