import { useEffect, useRef, useState } from "react";
import _ from "lodash";
// eslint-disable-next-line import/no-restricted-paths
import { getMicAudioStream } from "../../../_legacy/Transcribe/transcribe/audio";
import {
  closeSocket,
  streamAudioToWebSocket,
  // eslint-disable-next-line import/no-restricted-paths
} from "../../../_legacy/Transcribe/transcribe/websocketUtils";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
// eslint-disable-next-line import/no-restricted-paths
import { tonkenizeTranscript } from "../../../_legacy/Transcribe/transcribe/Transcript";

export function useDictation({ text, onReadText }) {
  const { tCommon } = useAppTranslation.Common();
  const [isRecording, setIsRecording] = useState(false);
  const [partialText, setPartialText] = useState("");

  const textRef = useRef(text);

  const prepareText = (t = "") => {
    return t.replace("you was admitted", "he was admitted");
  };

  const isRightMostBorder = (token) => {
    const entities = token.labels;
    let isRightMost = false;

    _.forEach(entities, (e) => {
      if (
        e["Category"] === "PROTECTED_HEALTH_INFORMATION" ||
        e["Category"] === "MEDICAL_CONDITION"
      ) {
        if (e["EndOffset"] === token.end || e["EndOffset"] === token.end - 1) {
          isRightMost = true;
        }
      }
    });

    return isRightMost;
  };

  const updateTranscript = async (data) => {
    const preparedText = prepareText(data?.text || "");

    if (data?.isPartial) {
      setPartialText(preparedText);
    } else {
      const { wordTokens } = await tonkenizeTranscript(
        preparedText,
        data?.results,
      );

      const segment = {
        startTime: data?.results?.[0]?.StartTime,
        words: wordTokens,
      };

      let finalDiction = textRef.current || "";

      if (segment) {
        _.map(segment, (seg) => {
          _.map(seg, (token) => {
            let display = "";
            display = isRightMostBorder(token) ? token.text : token.text + " ";
            finalDiction += display;
          });
        });
      }

      setPartialText("");
      onReadText(finalDiction);
    }
  };

  const onStartRecording = () => {
    setIsRecording(true);
    getMicAudioStream()
      .then((micAudioStream) => {
        streamAudioToWebSocket(micAudioStream, updateTranscript);
      })
      .catch(() => {
        uiNotification.error(tCommon("error.audioNotSupported"));
        setIsRecording(false);
      });
  };

  const onStopRecording = async () => {
    closeSocket();
    setIsRecording(false);
  };

  const onToggle = () => {
    if (isRecording) {
      onStopRecording();
    } else {
      onStartRecording();
    }
  };

  useEffect(() => {
    textRef.current = text;
  }, [text]);

  return {
    isRecording,
    onToggle,
    partialText,
  };
}
