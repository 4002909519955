import { useEffect, useMemo, useState } from "react";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import {
  getMatchedPattern,
  getSubstitutionSearchTerm,
  substituteByMatch,
  substitutesFilterPredicate,
} from "../utilities";
import { DEFAULT_MATCHED_PATTERN } from "../config";

/**
 * @param {{
 *    text: string;
 *    onTextChange: (nextText: string) => void;
 *    patterns: Pattern[];
 * }} param0
 * @returns {{
 *    changeText: (nextText: string) => void;
 *    isPatternFound: boolean;
 *    availableSubstitutes: SubstituteItem[];
 *    substitutePattern: (item: SubstituteItem) => void;
 * }}
 */
export function usePatternAutocomplete({
  text,
  onTextChange,
  patterns,
  setListOpen,
}) {
  const [matchedPattern, setMatchedPattern] = useState(DEFAULT_MATCHED_PATTERN);
  const [_, debouncedValue, setDebouncedValue] = useDebouncedState("", 300);
  const [substitutionsSearchTerm, setSubstitutionsSearchTerm] = useState("");

  const handleTextChange = (e) => {
    onTextChange(e.target.value);
    setDebouncedValue(e.target.value);
  };

  const substitutePattern = (itemForSubstitution) => {
    if (matchedPattern !== DEFAULT_MATCHED_PATTERN) {
      onTextChange(
        substituteByMatch(
          text,
          matchedPattern.match,
          itemForSubstitution.value,
        ),
      );
    }
    setDebouncedValue("");
    setMatchedPattern(DEFAULT_MATCHED_PATTERN);
    setListOpen(false);
  };

  const availableSubstitutes = useMemo(
    () =>
      matchedPattern?.substitutes?.filter((s) =>
        substitutesFilterPredicate(s, substitutionsSearchTerm),
      ) || [],
    [matchedPattern?.substitutes, substitutionsSearchTerm],
  );

  useEffect(() => {
    const pattern = getMatchedPattern(debouncedValue, patterns);
    setMatchedPattern(pattern);
    if (pattern) {
      setListOpen(true);
    }
  }, [debouncedValue, patterns]);

  useEffect(() => {
    if (matchedPattern) {
      setSubstitutionsSearchTerm(
        getSubstitutionSearchTerm(debouncedValue, matchedPattern.match),
      );
    }
  }, [matchedPattern?.match, debouncedValue]);

  return {
    changeText: handleTextChange,
    isPatternFound: matchedPattern !== DEFAULT_MATCHED_PATTERN,
    availableSubstitutes,
    substitutePattern,
  };
}
