import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./PatternAutocomplete.module.scss";
import { Textarea } from "../../shared/Textarea/Textarea";
import { usePatternAutocomplete } from "./hooks/usePatternAutocomplete";
import { useClickOutside } from "../../utilities/hooks/useClickOutside";

export function PatternAutocomplete({
  patterns,
  value,
  onChange,
  containerClassName,
  className,
  listClassName,
  placeholder,
}) {
  var textareaRef = useRef(null);
  var listRef = useRef(null);

  var [listOpen, setListOpen] = useState(false);

  var { changeText, isPatternFound, availableSubstitutes, substitutePattern } =
    usePatternAutocomplete({
      text: value,
      onTextChange: onChange,
      patterns,
      setListOpen,
    });

  var onSubstitutePattern = (item) => {
    substitutePattern(item);
    textareaRef.current?.focus?.();
  };

  useClickOutside(listRef, () => {
    if (listOpen) {
      setListOpen(false);
    }
  });

  return (
    <div className={cx(classes.root, containerClassName)}>
      <Textarea
        ref={textareaRef}
        value={value}
        onChange={changeText}
        placeholder={placeholder}
        className={className}
      />
      {listOpen && isPatternFound && availableSubstitutes.length > 0 && (
        <div ref={listRef} className={cx(classes.list, listClassName)}>
          {availableSubstitutes.map((s, i) => (
            <button
              key={`${s.label}-${i}`}
              onClick={() => onSubstitutePattern(s)}
            >
              <b>{s.label}</b>
              {s.description && <span>{s.description}</span>}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

const substitutePropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

const patternPropType = PropTypes.shape({
  match: PropTypes.string.isRequired,
  substitutes: PropTypes.arrayOf(substitutePropType).isRequired,
});

PatternAutocomplete.propTypes = {
  patterns: PropTypes.arrayOf(patternPropType).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  listClassName: PropTypes.string,
  placeholder: PropTypes.string,
};
