import React from "react";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { Header } from "./shared/Header/Header";
import classes from "./styles.module.scss";
import { Empty } from "../../shared/Empty/Empty";
import { Question } from "./boxes/Question/Question";
import { useQuestionnaire } from "./hooks/useQuestionnaire";

export function ModalPatientQuestionnaireFill({
  isOpen,
  onClose,
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
  onSuccess,
}) {
  const { tCommon } = useAppTranslation.Common();

  const {
    currentQuestion,
    currentAnswer,
    navigator,
    updateAnswer,
    onNext,
    onFinishLater,
    isSubmitting,
    isLoading,
    questionnaireStatus,
  } = useQuestionnaire({
    onSuccess,
    patientQuestionnaireId,
    preloadedPatientQuestionnaire,
  });

  return (
    <Modal
      size="large"
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={<Header />}
    >
      {patientQuestionnaireId || preloadedPatientQuestionnaire ? (
        <>
          {isLoading ? (
            <Empty position="center">{tCommon("label.loading")}...</Empty>
          ) : currentQuestion ? (
            <Question
              key={currentQuestion.id}
              question={currentQuestion}
              answer={currentAnswer}
              updateAnswer={updateAnswer}
              onNext={onNext}
              onPrev={navigator.goBackward}
              onFinishLater={onFinishLater}
              canGoNext={navigator.canGoForward}
              canGoPrev={navigator.canGoBackward}
              isSubmitting={isSubmitting}
              isQuestionnaireFilled={questionnaireStatus === "filled"}
            />
          ) : null}
        </>
      ) : (
        <Empty position="center">
          {tCommon("fillPatientQuestionnaire.notFound")}
        </Empty>
      )}
    </Modal>
  );
}
