import { useMemo } from "react";
import { usePatientQuestionnaire } from "./helpers/usePatientQuestionnaire";
import { useSubmit } from "./helpers/useSubmit";
import {
  buildIdToAdaptedQuestionMap,
  buildPath,
  questionsAdapter,
} from "../utilities";
import { useAnswers } from "./helpers/useAnswers";
import { useNavigator } from "./helpers/useNavigator";
import { extractQuestionnaireStatus } from "../../../utilities/api";

export function useQuestionnaire({
  patientQuestionnaireId,
  preloadedPatientQuestionnaire,
  onSuccess,
}) {
  const patientQuestionnaire = usePatientQuestionnaire({
    patientQuestionnaireId,
    preloadedPatientQuestionnaire,
  });

  const submit = useSubmit();

  const adaptedQuestions = useMemo(
    () => questionsAdapter(patientQuestionnaire.data),
    [patientQuestionnaire.data],
  );

  const [answers, getUpdateHandler] = useAnswers(patientQuestionnaire);

  const idToAdaptedQuestionMap = useMemo(
    () => buildIdToAdaptedQuestionMap(adaptedQuestions),
    [adaptedQuestions],
  );

  const navigator = useNavigator(
    buildPath(adaptedQuestions, patientQuestionnaire.data?.step_history),
  );

  const currentQuestion = idToAdaptedQuestionMap[navigator.head];
  const currentAnswer = answers?.[currentQuestion.id];

  const onNext = (nextAnswers) => {
    const headAnswer = nextAnswers[navigator.head];

    const nextLogicKey = idToAdaptedQuestionMap?.[
      navigator.head
    ]?.choices?.find((x) => x.text === headAnswer?.choices?.[0])?.id;

    if (navigator.canGoForward) {
      navigator.goForward(nextLogicKey);
    } else {
      submit.initiate(nextAnswers, idToAdaptedQuestionMap).then(() => {
        onSuccess?.();
      });
    }
  };

  const onFinishLater = (nextAnswers) => {
    submit
      .initiate(nextAnswers, idToAdaptedQuestionMap, navigator.history)
      .then(() => {
        onSuccess?.();
      });
  };

  const updateAnswer = getUpdateHandler(currentQuestion?.id);

  return {
    currentQuestion,
    currentAnswer,
    navigator: {
      canGoBackward: navigator.canGoBackward,
      canGoForward: navigator.canGoForward,
      goBackward: navigator.goBackward,
    },
    updateAnswer,
    onNext,
    onFinishLater,
    isSubmitting: submit.isLoading,
    isLoading: patientQuestionnaire.isLoading,
    questionnaireStatus: extractQuestionnaireStatus(
      patientQuestionnaire.data || {},
    ),
  };
}
