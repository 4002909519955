import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  procedureId: yup.number().required(),
});

export function useProcedureDeleteMutation(options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });
      return http.delete(HTTP_ENDPOINTS.procedure.delete(req.procedureId));
    },
    {
      ...options,
      onSuccess: (...args) => {
        [
          QUERY_KEYS.procedure,
          QUERY_KEYS.patientProcedures,
          QUERY_KEYS.patientConsents,
          QUERY_KEYS.patientQuestionnaires,
          QUERY_KEYS.patientUpcomingAppointments,
          QUERY_KEYS.servicesWithRelatedConsentsAndQuestionnaires,
          QUERY_KEYS.patientQuestionnaires.getByEntity,
          QUERY_KEYS.patientConsents.getByEntity,
        ].forEach((key) => {
          queryClient.invalidateQueries({
            queryKey: [key],
          });
        });
        options?.onSuccess?.(...args);
      },
    },
  );
}
