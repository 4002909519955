import {
  MULTI_QUESTION_TYPES,
  SIMPLE_QUESTION_TYPES,
} from "../../../../api/questionnaires/config";
import {
  QUESTIONNAIRE_YES_NO_ANSWERS,
  usePatientQuestionnaireAnswersMutation,
} from "../../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnaireAnswersMutation";
import { QUESTIONNAIRE_TYPES } from "../../../../consts/api";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { removeNullishFromShape } from "../../../../utilities/general";

export function useSubmit() {
  const { tCommon } = useAppTranslation.Common();
  const { mutateAsync, isLoading } = usePatientQuestionnaireAnswersMutation();

  const getScoreValues = (answer, question) => {
    if (
      question.questionnaireType === QUESTIONNAIRE_TYPES.multi &&
      question.type === MULTI_QUESTION_TYPES.opinionScale
    ) {
      return {
        comment: answer.reason,
        score: answer.choices?.[0] || null,
      };
    }

    if (
      (question.type === SIMPLE_QUESTION_TYPES.yesNo ||
        question.type === MULTI_QUESTION_TYPES.yesNo) &&
      answer.choices?.[0] === QUESTIONNAIRE_YES_NO_ANSWERS.yes
    ) {
      return {
        comment: answer.reason,
      };
    }

    return {};
  };

  const initiate = async (answers, questions, navHistory = []) => {
    const answerEntries = Object.entries(answers || {});

    if (answerEntries.length > 0) {
      try {
        const patientQuestionnaireId =
          Object.values(questions)?.[0]?.patientQuestionnaireId;

        if (patientQuestionnaireId) {
          const payload = {
            patient_questionnaire_id: patientQuestionnaireId,
            answers: answerEntries.reduce((carry, [questionId, answer]) => {
              const question = questions[questionId];

              return [
                ...carry,
                removeNullishFromShape({
                  question_id: Number(questionId),
                  question_type: question.type,
                  answer: answer.choices,
                  ...getScoreValues(answer, question),
                }),
              ];
            }, []),
            step_history: navHistory.map(Number),
          };

          await mutateAsync(payload);

          if (navHistory?.length > 0) {
            uiNotification.success(
              tCommon("success.saveQuestionnaireAnswersPartial"),
            );
          } else {
            uiNotification.success(tCommon("success.saveQuestionnaireAnswers"));
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        uiNotification.error(tCommon("error.saveQuestionnaireAnswers"));
        throw e;
      }
    }
  };

  return {
    initiate,
    isLoading,
  };
}
