/**
 * @template {Base} T
 * @param {T} value
 * @param  {...(value: T) => T} fns
 * @returns {T}
 */
export const pipe = (value, ...fns) => fns.reduce((r, f) => f(r), value);

/**
 * @template {Base} T
 * @param {T} values
 * @returns {T}
 */
export const identity = (value) => value;

/**
 * @template {Base} T
 * @param {(T) => T} elseClause
 * @param {Array<[(T) => boolean, (T) => T]>} ifClauses
 * @retuns (T) => T
 */
export const cond =
  (elseClause, ...ifClauses) =>
  (value) => {
    return (
      ifClauses.find((ifClause) => ifClause[0](value))?.[1]?.(value) ||
      elseClause(value)
    );
  };

export class Match {
  #value;
  #matches;

  constructor(x, m) {
    this.#value = x;
    this.#matches = m;
  }

  static of(x) {
    return new Match(x);
  }

  on(x, fn) {
    if (this.#value === x) {
      return new Match(fn(this.#value), true);
    }
    return this;
  }

  or(x) {
    if (!this.#matches) {
      return new Match(x);
    }
    return this;
  }

  join() {
    return this.#value;
  }
}

export class Maybe {
  #value;

  constructor(value) {
    this.#value = value;
  }

  static of(value) {
    return new Maybe(value);
  }

  isNone() {
    return this.#value === null || this.#value === undefined;
  }

  value() {
    return this.#value;
  }

  map(f) {
    if (this.isNone()) {
      return this;
    }
    return Maybe.of(f(this.#value));
  }

  orElse(fallback) {
    if (this.isNone()) {
      return Maybe.of(fallback);
    }
    return this;
  }
}

export const not = (value) => !value;

/**
 * @param {number} value
 */
export const positive = (value) => value > 0;

export const strDefault = () => "";
