import * as _ from "lodash";
import { detectEntity } from "../comprehend/DetectEntities";

export const tonkenizeTranscript = async (transcipt, results) => {
  let itemList = _.filter(results[0].Alternatives[0].Items, (item) => {
    return item.Type === "pronunciation";
  });

  var originalTranscript = "";
  var transcriptWordList = [];

  let wordList = transcipt.split(" ");
  var runningIndex = 0;

  _.map(wordList, (word, i) => {
    transcriptWordList.push({
      text: word,
      start: runningIndex,
      end: runningIndex + word.length,
      labels: [],
      confidence: itemList[i].Confidence,
    });

    originalTranscript = originalTranscript + word + " ";
    runningIndex = runningIndex + word.length + 1;
  });

  let response = await detectEntity(originalTranscript);
  let entities = response["Entities"];

  _.map(transcriptWordList, (word) => {
    _.map(entities, (e) => {
      if (
        parseInt(e["BeginOffset"]) === parseInt(word.start) ||
        parseInt(e["EndOffset"]) === parseInt(word.end) ||
        parseInt(e["EndOffset"]) === parseInt(word.end) - 1 ||
        (parseInt(e["BeginOffset"]) <= parseInt(word.start) &&
          parseInt(e["EndOffset"]) >= parseInt(word.end))
      ) {
        word.labels.push(e);
      }

      _.map(e["Attributes"], (attr) => {
        if (
          parseInt(attr["BeginOffset"]) === parseInt(word.start) ||
          parseInt(attr["EndOffset"]) === parseInt(word.end) ||
          parseInt(attr["EndOffset"]) === parseInt(word.end) - 1 ||
          (parseInt(attr["BeginOffset"]) <= parseInt(word.start) &&
            parseInt(attr["EndOffset"]) >= parseInt(word.end))
        ) {
          word.labels.push(e);
        }
      });
    });
  });

  return { wordTokens: transcriptWordList, segmentEntities: entities };
};
