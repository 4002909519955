/* eslint-disable react/no-string-refs */
import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { format } from "date-fns";
import Lightbox from "react-images";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import { SketchField, Tools } from "react-sketch";
import axios from "axios";
import validator from "validator";
import { isEqual } from "lodash";
import ReactTooltip from "react-tooltip";
import {
  getClientProfileDetail,
  fireClient,
  doNotDisturb,
  changePortaAccess,
  getClientCardData,
  saveClientCard,
  resendWelcomeEmail,
  resetPortalPassword,
  deleteClient,
  exportClientPDF,
  sendPostInstructions,
  getMembershipData,
  addMonthyMembership,
  cancelMembership,
  applyCouponCode,
  updateMembershipCC,
  pastDueInvoiceData,
  changeMembershipClinic,
  updateMembershipHoldStatus,
  searchProduct,
  getTreatmentTemplates,
  getTemplateDetails,
  savePAYGTreatmentPlan,
  getPlanDataByID,
  saveMonthlyTreatmentPlan,
  getProgramData,
  applyStartProgramDiscount,
  changeTreatmentPlanClinic,
  viewPriceBreakUp,
  startTreatmentProgram,
  cancelTreatmentPlan,
  savePlanAsTemplate,
  updatePlanCard,
  getPrescribeOnlyDetails,
  updatePrescribeOnly,
  doThisAction,
  getNewMembershipData,
  newMembershipType,
  fetchAgreement,
  changeMembershipDrawDay,
  saveClinicOfClient,
  saveNewPrescription,
  savePharmacy,
  generatePrescriptionPDF,
  faxPrescriptionPDF,
  downloadMembershipAgreement,
  readClientProfileData,
  writeClientProcedureData,
  readClientProcedureData,
  readClientProfileAndProcedureData,
  verifyPortalEmail,
  checkScriptSurePatient,
  createScriptSurePatient,
  getClearentKey,
  getAppointmentData,
  sendPreInstructionsInAppointment,
  onClosePrescriptionIframe,
} from "../../../Actions/Clients/clientsAction.js";
import {
  saveProcedurePrescription,
  deleteProcedurePrescription,
  sendProcedurePrescription,
  emptyProcedureReducer,
} from "../../../Actions/Procedures/procedureActions.js";
import {
  emptyMembershipWallet,
  getMembershipMultiTier,
} from "../../../Actions/Settings/membershipWalletActions.js";
import {
  signProcedure,
  exportEmptyData,
} from "../../../Actions/Settings/settingsActions.js";
import {
  getSetupIntentForAccount,
  getClientCardDataStripe,
  getSetupIntent,
} from "../../../Actions/Stripe/StripeActions.js";
import {
  checkIfPermissionAllowed,
  numberFormat,
  showFormattedDate,
  displayName,
  isNumber,
  toggleBodyScroll,
  validateEmail,
  getDateFormat,
  isFormSubmit,
  dateFormatPicker,
  getNumberWithOrdinal,
  isInt,
  capitalizeFirstLetter,
  autoScrolling,
  getClearentToken,
  initializeClearentSdk,
} from "../../../Utils/services.js";
import defVImage from "../../../_legacy/images/no-image-vertical.png";
import picClose from "../../../_legacy/images/popupClose.png";
import CustomDatePicker from "../../Common/CustomDatePicker.js";
import MembershipBadge from "../../Common/MembershipBadge.js";
import TreatmentPlan from "../TreatmentPlan.js";
import { getSingleTimelineData } from "../SingleTimelineDemo.js";
import CosmeticTimelinePhotoMarking from "../Timeline/CosmeticTimelinePhotoMarking.js";
import CosmeticTimelinePhotoAfter from "../Timeline/CosmeticTimelinePhotoAfter.js";
import ScriptSureFrame from "../Eprescribe/ScriptSureFrame.js";
import { accountStatusResponseInterceptor } from "../../AccountHoldRedirect/AccountHoldRedirectInterceptor.js";
import {
  getMembershipPaymentDetails,
  resetMembershipDetailsData,
  getMembershipPaymentDetailsWithCoupon,
} from "../../../Actions/Clients/membershipActions.js";
import { MembershipProratedFeeInfo } from "../MembershipProratedFeeInfo.js";
import StickyNotesModal from "../../ClientNotes/StickyNotesModal.js";
import { geClientNotes } from "../../../Actions/ClientNotes/clientNotesActions.js";
import { unwrapOr } from "../../../utilities/general.js";
import { shortenClinicName } from "../../../helpers/general";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import { svg } from "../../../assets/svg.js";
import { Disabled } from "../../../shared/Disabled/Disabled.js";
import { Tooltip } from "../../../shared/Tooltip/Tooltip.js";
import { DEFAULT_MEMBERSHIP_COLOR } from "../../../consts/general.js";
import ClientMembership from "../ClientMembership/ClientMembership.js";
import { phoneUtil } from "../../../utilities/phone.js";
import { ModalCreateQualiphyProcedure } from "../../../widgets/ModalCreateQualiphyProcedure/ModalCreateQualiphyProcedure.js";
import { http } from "../../../services/HttpService.js";
import StripePaymentForm from "../../Sales/FrontdeskCheckout/StripePaymentForm.js";
import ClientCreditCards from "../components/ClientCreditCards/ClientCreditCards.js";
import { InputPhone } from "../../../shared/InputPhone/InputPhone.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { withCurrentAccountQuery } from "../../../api/queries/useAccountQuery.js";
import { ModalAgreement } from "./components/ModalAgreement/ModalAgreement.js";
import { Loader } from "./components/Loader/Loader.js";
import { Timeline } from "./components/Timeline/Timeline.js";
import { ROUTES } from "../../../consts/routes.js";
import { history } from "../../../history.js";
import { PATIENT_CONSENTS_ENTITY_TYPES } from "../../../api/consents/patientConsents/usePatientConsentsQuery.js";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery.js";
import { CLIENTS_ROUTES } from "../../../consts/routes.js";
import TIMER_ICON from "../Documents/icons/TimerIcon.js";
import { HTTP_ENDPOINTS } from "../../../consts/api.js";
import { DeletePrescribedPlan } from "./shared/DeletePrescribedPlan/DeletePrescribedPlan.js";
import { withGlobalSettings } from "../../../hocs/withGlobalSettings.js";

const clientProfileInstance = axios.create();

clientProfileInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (!error.response) {
      return { data: { data: "", message: "file_type_error", status: 400 } };
    }
  },
);

clientProfileInstance.interceptors.response.use(
  accountStatusResponseInterceptor,
);

const initPaygElem = () => {
  return {
    product_name: "",
    product_id: "",
    units: "",
    rep_val: "",
    rep_by: "day",
  };
};

const initPaygElemClass = () => {
  return {
    product_name: "simpleInput",
    product_id: "simpleInput",
    units: "simpleInput",
    rep_val: "simpleInput p-r-5",
    rep_by: "simpleInput",
  };
};

const initMonthlyElem = () => {
  return {
    product_name: "",
    units: "",
    month: "",
    product_id: "",
  };
};

const initMonthlyElemClass = () => {
  return {
    product_name: "simpleInput",
    units: "simpleInput",
    month: "simpleInput",
    product_id: "simpleInput",
  };
};

var dayOfMonth = moment().format("D");

var numOfMonths = moment().format("M");

if (dayOfMonth > 28) {
  dayOfMonth = 1;
}

if (numOfMonths > 12) {
  numOfMonths = 1;
}

const initPrescriptionField = () => {
  return {
    medicine_name: "",
    dosage: "",
    frequency: "",
    duration: "",
    medicineNameClass: "setting-input-box",
    dosageClass: "setting-input-box",
    frequencyClass: "setting-input-box",
    durationClass: "setting-input-box",
  };
};

const initNewPrescriptionField = () => {
  return {
    medicine_name: "",
    form: "",
    strength: "",
    quantity: "",
    frequency: "",
    dosage: "",
    refills: "",
    medicationClass: "tableInput p-l-5 p-r-5",
    formClass: "tableInput p-l-5 p-r-5",
    strengthClass: "tableInput p-l-5 p-r-5",
    quantityClass: "tableInput p-l-5 p-r-5",
    directionsClass: "tableInput p-l-5 p-r-5",
    doseClass: "tableInput p-l-5 p-r-5",
    refillsClass: "tableInput p-l-5 p-r-5",
  };
};

window.ClearentTokenSuccess = (raw, json) => {
  localStorage.setItem("jwt", json.payload["mobile-jwt"].jwt);
};

window.ClearentTokenError = () => { };

let _self;

class ClientProfile extends Component {
  constructor(props) {
    super(props);
    toggleBodyScroll(false);
    autoScrolling(true);
    this.runCarousel = this.runCarousel.bind(this);
    this.selectProduct = this.selectProduct.bind(this);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const userData = JSON.parse(localStorage.getItem("userData"));
    let showSigPopup = userData.user.show_signature_popup;

    let defTimeLine = localStorage.getItem("defTimeLine");
    if (!defTimeLine) {
      if (userData) {
        defTimeLine =
          userData.account_preference !== undefined &&
            userData.account_preference.default_template !== undefined
            ? userData.account_preference.default_template
            : "health";
      } else {
        defTimeLine = "health";
      }
      localStorage.setItem("defTimeLine", defTimeLine);
    }
    const ePrescriptionSetup = localStorage.getItem("ePrescriptionSetup")
      ? JSON.parse(localStorage.getItem("ePrescriptionSetup"))
      : null;

    this.state = {
      ePrescriptionSetup: ePrescriptionSetup,
      clientID: this.props.match.params.id,
      globalLang: languageData.global,
      roomTextData: languageData.rooms,
      showLoader: true,
      clientData: [],
      userData: userData,
      showModal: false,
      modalMessage: "",
      modalAction: "",
      modalActType: "",
      isSliderCheck: false,
      defTimeLine: defTimeLine ? defTimeLine : "",
      appendedImagesData: [],
      def_no_image_vertical: defVImage,
      fireData: [],
      fireStatus: null,
      portalAccessData: [],
      portalAccessStatus: null,
      showPrescriptionModal: false,
      prescriptionField: [initPrescriptionField()],
      prescriptionValue: [],
      prescriptionProcedureId: 0,
      showCCPopup: false,
      cardData: [],
      editCardId: null,
      savedTime: null,
      resendStatus: null,
      showResetPassModal: false,
      resetPassClass: "setting-input-box p-r-40",
      resetPortalPasswordStatus: null,
      deleteData: [],
      deleteStatus: null,
      lightboxIsOpen: false,
      currentImageIndex: 0,
      lightboxImage: null,
      resetPassType: "password",
      eyeClass: "client-treat-cal pass-hidden",
      apptIDToSend: "",
      patientSkinScore: {},
      showSkinModal: false,
      modalSkinMessage: "",
      showMembershipModal: false,
      showMembershipSection: false,
      showNewCardSection: false,
      waiveOffOTFee: false,
      treatment_name: "",

      membership_tier: "single",
      membership_tier_id: "",
      membership_tier_idClass: "simpleSelect",
      membership_tier_types: [],
      membership_tier_type_products: "",
      membership_on_all_product: 0,
      discount_percentage: 0,
      monthly_membership_type: "free",
      monthly_membership_fees: 0,
      one_time_membership_setup: 0,
      total_membership_with_waive: 0,
      total_membership_without_waive: 0,
      subscription_started_at: new Date(),
      subscription_started_atClass: "simpleInput",
      recurly_program_name: "",
      patientEmailClass: "simpleInput",
      patientEmail: "",
      showImmediateMembershipModal: false,
      showFutureMembershipModal: false,
      couponCodeClass: "simpleInput",
      couponCode: "",
      discountCouponApplied: 0,
      totalDiscount: 0,
      drawDate: dayOfMonth,
      drawMonth: 1,
      isMembershipOn: 0,
      languageData: languageData.clients,

      showSigPopup: showSigPopup ? 1 : 0,
      showSignModal: false,
      canvasClass: "signature-box sig-div",
      inputOut: "input-outer no-display",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset no-display",
      changeClass: "new-blue-btn no-margin Change",
      uploadedSignature: "",
      uploadedSignature_url: "",

      save_sign: false,
      pastDueInvoiceData: {},

      membership_clinic_list: [],
      membership_clinic_id: "",
      membership_clinic_name: "",
      membership_clinic_idClass: "simpleSelect",
      member_payment_optionsClass: "simpleSelect",
      showMembershipClinicSection: false,
      membership_change_clinic_id: "",
      membership_id: "",
      holdStatus: 2,
      pause: false,
      resume: false,
      membershipId: 1,
      membershipDataTime: new Date(),
      selected_payment_option: "both",
      membership_payment_frequency: "both",
      membership_yearly_fees: 0,
      membership_monthly_fees: 0,
      payment_frequency: "",

      membership_subscription_name: "",
      membership_subscription_color: "#F5CB42",
      membership_subscription_failed_color: "#FF0000",

      showTreatmentModal: false,
      showCreateTreatModal: false,
      treatType: "payg",
      showPaygSection: true,
      showMonthlySection: false,
      expirePlanAfter: 0,
      treatmentStartOn: new Date(),
      multiplePaygList: [initPaygElem()],
      multiplePaygClassList: [initPaygElemClass()],
      showSearchResult: false,
      selProIndex: 0,
      showTemplateConfirmation: false,
      showPreviewBtn: false,
      showGenerateBtn: false,
      multipleMonthlyListDefObj: initMonthlyElem(),
      multipleMonthlyList: [],
      skinGoal: "",
      multipleMonthlyClassDefObj: initMonthlyElemClass(),
      multipleMonthlyClassList: [],
      skinGoalClass: "simpleInput",
      prescribeOnly: "0",
      requestType: "add",
      requestMode: "new",
      treatmentPlanID: 0,
      showPreviewDetails: false,
      showStartProgram: false,
      cardType: "new",
      chargeType: "later",
      canExpireProduct: 0,
      taxAmount: "",
      discountValue: "",
      htmlGenerator: "",
      discountType: "percentage",
      returnFromStartProgram: false,
      showNewCardInput: false,
      cardNumberArray: [],
      proClinicOptData: "",
      startProTotal: 0,
      startProMonthly: 0,
      discountOuterClass: "TP-discount-outer",
      showPriceBreakModal: false,
      taxOuterClass: "TP-discount-outer",
      subscriptionID: 0,
      showChangePlanCCPopup: false,
      showSaveTemplate: false,
      templateName: "",
      templateNameClass: "simpleInput",
      showPresOnlyPopup: false,
      treatmentAction: "",
      collapseShowIndex: null,
      showAgreement: false,
      agreement: false,
      agreementData: {},
      stripe_token: "",

      show_clinics: false,
      patient_clinic_id: 0,
      clinicName: "",
      showNewPrescriptionModal: false,
      newPrescriptionField: [initNewPrescriptionField()],
      newPrescriptionValue: [],
      shipto_ohers_addr: "",
      ship_to: "patient",
      prescription_note: "",
      prescription_note_class: "setting-textarea-box auto-height",
      pharmacy_id_class: "setting-select-box",
      date_needed_class: "setting-input-box",
      shipto_ohers_addr_class: "setting-input-box m-t-15",
      date_needed: new Date(),
      pharmacy_name: "",
      pharmacy_fax: "",
      pharmacy_fax_error: false,
      pharmacy_phone: "",
      pharmacy_phone_error: false,
      pharmacy_name_class: "setting-input-box",
      pharmacy_fax_class: "setting-input-box",
      pharmacy_phone_class: "setting-input-box",
      defaultCountry: localStorage.getItem("cCode"),
      showNewPrescriptionModalInViewMode: false,

      showSignatureCanvas: false,
      membershipCanvasClass: "signature-box sig-div",
      membershipCanvasInputOut: "input-outer no-display",
      isClearMembershipCanvasButton: true,
      isResetMembershipCanvasButton: false,
      membership_canvas_signature: "",
      membership_canvas_signature_url: "",
      downloadMembershipAgreementData: null,

      clientScopes: "cardOnFiles,patientInsurence",
      singleTimelineData: getSingleTimelineData(),
      isShowCosmeticPhotoAfterFlag: false,
      isShowCosmeticPhotoMarkingFlag: false,
      procedureIdForPopup: 0,

      procedureDataText: true,
      pagesize: 5,
      page: 1,
      next_page_url: "",
      mClearentError: {
        cardNumberError: false,
        expiryNumberError: false,
        cvvError: false,
      },
      memClearentError: {
        cardNumberError: false,
        expiryNumberError: false,
        cvvError: false,
      },
      treatStartClearentError: {
        cardNumberError: false,
        expiryNumberError: false,
        cvvError: false,
      },
      treatChangeClearentError: {
        cardNumberError: false,
        expiryNumberError: false,
        cvvError: false,
      },
      clearentError: {
        cardNumberError: false,
        expiryNumberError: false,
        cvvError: false,
      },
      cardNumberValue: "",
      cardExpiryValue: "",
      cardCVV: "",

      showEprescribeForm: false,
      showEprescribeIframe: false,
      showFieldsPopup: false,
      firstname: "",
      lastname: "",
      date_of_birth: "",
      gender: "1",
      phoneNumber: "",
      phoneNumberError: false,
      address_line_1: "",
      city: "",
      state: "",
      pincode: "",
      middlename: "",

      firstname_class: "newInputField",
      lastname_class: "newInputField",
      date_of_birth_class: "newInputField",
      gender_class: "newInputField",
      phoneNumber_class: "newInputField",
      address_line_1_class: "newInputField",
      city_class: "newInputField",
      state_class: "newInputField",
      pincode_class: "newInputField",
      middlename_class: "newInputField",
      isTouchMD: userData.account.account_preference.is_touch_md,
      plan_selected: userData.account.account_subscription.plan_code,
      appointmentData: [],
      clearent_email_id: "",
      stickyNotes: null,
      cardSelected: "",
      procedurePeriod: {
        startDate: "",
        endDate: "",
      },
      categoryFilter: { label: "All Categories", value: "" },
      providerFilter: { label: "All Providers", value: "" },
      procedureSearch: "",
      allProvidersOptions: [],
    };

  }

  openLightbox = (index, event, src) => {
    event.preventDefault();
    this.setState({
      currentImageIndex: index,
      lightboxIsOpen: true,
      lightboxImage: src,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImageIndex: 0,
      lightboxIsOpen: false,
      lightboxImage: null,
    });
  };

  currentImageLightbox = (index) => {
    this.setState({ currentImageIndex: index });
  };

  componentDidMount() {
    _self = this;
    this.setState({
      showLoader: true,
      showCCPopup: false,
      showMembershipModal: false,
      showImmediateMembershipModal: false,
      showFutureMembershipModal: false,
      showTreatmentModal: false,
      showCreateTreatModal: false,
      treatType: "payg",
      showPaygSection: true,
      showMonthlySection: false,
      showTemplateConfirmation: false,
      showPreviewBtn: false,
      showGenerateBtn: false,
      multipleMonthlyList: [],
      returnFromStartProgram: false,
      showPriceBreakModal: false,
      canExpireProduct: 0,
      subscriptionID: 0,
      showChangePlanCCPopup: false,
      showSaveTemplate: false,
      templateName: "",
      showPresOnlyPopup: false,
      treatmentAction: "",
      procedureDataText: true,
    });

    const isNotReloadClientProfile = this.props.match.params
      .isNotReloadClientProfile
      ? this.props.match.params.isNotReloadClientProfile
      : 0;
    let isCallProfileApi = 1;
    let isCallProcedureApi = 1;
    if (
      this.props.ClientsReducer &&
      this.props.ClientsReducer.clientId == this.state.clientID
    ) {
      if (
        isNotReloadClientProfile == 1 &&
        this.props.ClientsReducer.clientProfileData
      ) {
        isCallProfileApi = 0;
      }
      if (this.props.ClientsReducer.isReloadClientProcedure === false) {
        isCallProcedureApi = 0;
      }
    }

    if (isCallProfileApi == 0 && isCallProcedureApi == 0) {
      this.props.readClientProfileAndProcedureData();
    } else {
      if (isCallProfileApi == 0) {
        this.props.readClientProfileData();
      } else {
        if (
          this.props.match.params.isPrescribed &&
          this.props.match.params.procedureID
        ) {
          this.setState(
            { eprescribedProcedureID: this.props.match.params.procedureID },
            () => {
              this.handleEprescribe();
            },
          );
        } else {
          this.props.getClientProfileDetail(
            this.state.clientID,
            this.state.clientScopes,
          );
        }
      }

      if (isCallProcedureApi == 0) {
        this.props.readClientProcedureData();
      }
    }

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
      });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        this.setState({
          stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
        });
      });
    }

    const datePicker1 = document.getElementsByClassName(
      "react-datepicker__input-container",
    )[0];
    datePicker1.childNodes[0].setAttribute("readOnly", true);

    if (
      this.state.isTouchMD == 1 &&
      this.state.plan_selected == "practice_booking"
    ) {
      let formData = {
        params: {
          id: this.state.clientID,
          pagesize: this.state.pagesize,
          page: 1,
        },
      };
      this.props.getAppointmentData(formData);
    }
    this.props.geClientNotes({
      params: {
        patient_id: this.state.clientID,
        sortby: "created",
      },
    });

    http.get(HTTP_ENDPOINTS.getProviders()).then((res) => {
      const allProvidersOptions = res.data.data.map((provider) => ({
        label: provider.full_name,
        value: provider.id,
      }));
      this.setState({ allProvidersOptions });
    });
  }

  _readClientProfile = (props, state) => {
    if (props.clientData.id != state.clientID) {
      props.getClientProfileDetail(state.clientID, state.clientScopes);
      return { showLoader: true };
    }

    let src = "/images/appmale.png";

    if (props.clientData.gender != "0") {
      src = "/images/appfemale.png";
    }

    let clientID = "";
    let clientName = "";
    let patientSkinScore = {};
    let modalSkinMessage = "";

    if (
      props.clientData !== undefined &&
      props.clientData.user_image !== "" &&
      props.clientData.user_image_url !== ""
    ) {
      src = props.clientData.user_image_url;
    }

    if (props.clientData !== undefined && props.clientData) {
      clientID = props.clientData.id;

      clientName = displayName(props.clientData);
      if (props.clientData.patients_skin_score) {
        patientSkinScore = props.clientData.patients_skin_score;
        modalSkinMessage =
          props.clientData.patients_skin_score.skin_discription;
      }
    }

    let recentlyViewedSaved = JSON.parse(
      localStorage.getItem("recentlyViewedData"),
    );

    if (recentlyViewedSaved) {
      let clientIDArray = [];

      if (recentlyViewedSaved.length > 0) {
        recentlyViewedSaved.map((obj) => {
          if (obj.profile_id === props.clientData.id) {
            obj.profile_name = clientName;
            obj.profile_pic = src;
          }
          clientIDArray.push(obj.profile_id);
        });
      }

      localStorage.removeItem("recentlyViewedData");
      localStorage.setItem(
        "recentlyViewedData",
        JSON.stringify(recentlyViewedSaved),
      );

      if (clientIDArray.indexOf(clientID) === -1) {
        let recentlyViewedData = {};
        recentlyViewedData["profile_pic"] = src;
        recentlyViewedData["profile_id"] = clientID;
        recentlyViewedData["profile_name"] = clientName;
        recentlyViewedSaved.push(recentlyViewedData);

        localStorage.removeItem("recentlyViewedData");
        localStorage.setItem(
          "recentlyViewedData",
          JSON.stringify(recentlyViewedSaved),
        );
      }
    } else {
      let viewedArray = [];
      let recentlyViewedData = {};
      recentlyViewedData["profile_pic"] = src;
      recentlyViewedData["profile_id"] = clientID;
      recentlyViewedData["profile_name"] = clientName;
      viewedArray[0] = recentlyViewedData;

      localStorage.setItem("recentlyViewedData", JSON.stringify(viewedArray));
    }

    let defTimeLine = localStorage.getItem("defTimeLine");
    if (!defTimeLine) {
      if (props.clientData) {
        defTimeLine =
          props.clientData.account_preference !== undefined &&
            props.clientData.account_preference.default_template !== undefined
            ? props.clientData.account_preference.default_template
            : "health";
      } else {
        defTimeLine = "health";
      }
      localStorage.setItem("defTimeLine", defTimeLine);
    }

    let isMembershipOn = props.clientData.is_monthly_membership
      ? props.clientData.is_monthly_membership
      : 0;

    if (
      state.clientData &&
      state.clientData.active_treamtment_plan === 0 &&
      state.clientData.active_treamtment_plan !==
      props.clientData.active_treamtment_plan
    ) {
      defTimeLine = "treatmentPlan";
    }

    if (state.requestType && state.requestType === "edit") {
      defTimeLine = "treatmentPlan";
    }

    if (state.returnFromStartProgram && state.returnFromStartProgram === true) {
      defTimeLine = "treatmentPlan";
    }

    let showSigPopup = state.showSigPopup;
    let canvasClass = "signature-box sig-div";
    let inputOut = "input-outer no-display";
    let clearClass = "new-white-btn no-margin clear";
    let resetClass = "new-blue-btn reset ";
    let changeClass = "new-blue-btn no-margin Change no-display";
    let signature_url = "";
    let signature = "";

    if (props.clientData.login_user) {
      showSigPopup = props.clientData.login_user.show_signature_popup ? 1 : 0;
      if (props.clientData.login_user.signature_url) {
        canvasClass = "signature-box sig-div no-display";
        inputOut = "input-outer";
        clearClass = "new-white-btn no-margin clear no-display";
        resetClass = "new-blue-btn reset no-display";
        changeClass = "new-blue-btn no-margin Change";
        signature_url = props.clientData.login_user.signature_url;
        signature = props.clientData.login_user.signature;
      }
    }

    let clinicName = "";
    let patient_clinic_id = props.clientData.clinic_id;
    if (props.clientData.clinics && props.clientData.clinic_id) {
      let clinic = props.clientData.clinics.find(
        (e) => e.id == props.clientData.clinic_id,
      );
      if (clinic) {
        clinicName = shortenClinicName(clinic.clinic_name);
      }
    }
    let returnState = {
      showLoader: false,
      defTimeLine: defTimeLine,
      patientSkinScore: patientSkinScore,
      modalSkinMessage: modalSkinMessage,
      showCCPopup: false,
      showMembershipModal: false,
      showAgreement: false,
      showImmediateMembershipModal: false,
      showFutureMembershipModal: false,
      isMembershipOn: isMembershipOn,

      uploadedSignature: "",
      uploadedSignature_url: "",
      save_sign: false,
      showSigPopup: showSigPopup,
      canvasClass: canvasClass,
      inputOut: inputOut,
      clearClass: clearClass,
      resetClass: resetClass,
      changeClass: changeClass,
      signature_url: signature_url,
      signature: signature,
      clearent_email_id: props.clientData.email,

      showTreatmentModal: false,
      showCreateTreatModal: false,
      treatType: "payg",
      showPaygSection: true,
      showMonthlySection: false,
      showTemplateConfirmation: false,
      showPreviewBtn: false,
      showGenerateBtn: false,
      multipleMonthlyList: [],
      requestType: "add",
      treatmentPlanID: props.clientData.current_treatment_plan
        ? props.clientData.current_treatment_plan.id
        : 0,
      returnFromStartProgram: false,
      startProTotal: props.clientData.current_treatment_plan
        ? props.clientData.current_treatment_plan.total_amount
        : 0,
      startProMonthly: props.clientData.current_treatment_plan
        ? props.clientData.current_treatment_plan.monthly_amount
        : 0,
      showPriceBreakModal: false,
      showChangePlanCCPopup: false,
      showSaveTemplate: false,
      templateName: "",
      showPresOnlyPopup: false,
      treatmentAction: "",
      clinicName: clinicName,
      patient_clinic_id: patient_clinic_id,
    };

    returnState.clientData = props.clientData;
    returnState.showLoader = false;

    returnState.membership_subscription_name = props.clientData
      .membership_subscription_name
      ? props.clientData.membership_subscription_name
      : state.membership_subscription_name;
    returnState.membership_subscription_color = props.clientData
      .membership_subscription_color
      ? props.clientData.membership_subscription_color
      : state.membership_subscription_color;
    returnState.membership_subscription_failed_color = props.clientData
      .membership_subscription_failed_color
      ? props.clientData.membership_subscription_failed_color
      : state.membership_subscription_failed_color;
    return returnState;
  };

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (props.clientData && props.clientData !== state.clientData) {
      returnState = _self._readClientProfile(props, state);
      if (
        props.clientProcedureData &&
        props.clientProcedureData !== state.clientProcedureData
      ) {
        let returnStateProcedure = _self._readClientProcedure(props, state);
        returnState = { ...returnState, ...returnStateProcedure };
      }
      return returnState;
    }

    if (
      props.closeIframeData != undefined &&
      props.closeIframeData !== state.closeIframeData
    ) {
      props.emptyProcedureReducer();
      let returnState = {};
      returnState.procedureDataText = true;
      returnState.showLoader = false;
      returnState.closeIframeData = props.closeIframeData;
      return returnState;
    }

    if (
      props.isReloadClientProcedure === true &&
      state.procedureDataText === false
    ) {
      if (
        window.location &&
        window.location.pathname &&
        window.location.pathname.indexOf("/clients/profile/" + state.clientID) >
        -1
      ) {
        let returnState = {};
        returnState.procedureDataText = true;
        return returnState;
      }
    }

    if (props.procedureDataText === false || props.showLoader === false) {
      if (props.procedureDataText === false) {
        autoScrolling(false);
        returnState.procedureDataText = false;
      }
      if (props.showLoader === false) {
        returnState.showLoader = false;
      }
      props.exportEmptyData();
      props.emptyProcedureReducer();
      return returnState;
    }

    if (
      props.fireData !== undefined &&
      props.fireData.status === 200 &&
      props.fireData.data !== state.fireData
    ) {
      return {
        fireData: props.fireData,
        fireStatus: props.fireData.message,
      };
    }

    if (
      props.portalAccessData !== undefined &&
      props.portalAccessData.status === 200 &&
      props.portalAccessData.data !== state.portalAccessData
    ) {
      return {
        portalAccessData: props.portalAccessData,
        portalAccessStatus: props.portalAccessData.message,
      };
    }

    if (
      props.cardData !== undefined &&
      props.cardData.status === 200 &&
      props.cardData.data !== state.cardData
    ) {
      let cardData = props.cardData.data;
      let clinic_id =
        cardData.cards_data && cardData.cards_data.length
          ? cardData.cards_data[0].clinic_id
          : 0;
      if (
        props.cardData.stripe_connection == "clinic" &&
        state.userData &&
        state.userData.account &&
        state.userData.account.pos_gateway == "clearent"
      ) {
        let clinic = cardData.clinics.find((x) => x.id == clinic_id);
        if (!clinic) {
          clinic = cardData.clinics[0];
        }
        if (clinic && clinic.publickey) {
          initializeClearentSdk(clinic.publickey, "card-payment-form");
        } else {
          uiNotification.error("POS is not setup for this clinic");
        }
      } else if (
        cardData.stripe_connection == "global" &&
        state.userData &&
        state.userData.account &&
        state.userData.account.pos_gateway == "clearent"
      ) {
        initializeClearentSdk(
          cardData.default_clinic_publickey,
          "card-payment-form",
        );
      }
      return {
        cardData: props.cardData.data,
        showCCPopup: true,
        showLoader: false,
      };
    } else if (
      props.cardData !== undefined &&
      props.cardData.status !== 200 &&
      props.cardData.data !== state.cardData
    ) {
      return {
        cardData: props.cardData.data,
        showLoader: false,
      };
    }

    if (
      props.saveCCData !== undefined &&
      props.saveCCData.status === 200 &&
      props.saveCCData.data !== state.saveCCData
    ) {
      return {
        savedTime: props.saveCCData.data,
        showCCPopup: false,
        clearent_email_id: "",
        clearent_zip: "",
      };
    } else if (
      props.saveCCData !== undefined &&
      props.saveCCData.status !== 200
    ) {
      return {
        showLoader: false,
      };
    }

    if (
      props.resendEmailData !== undefined &&
      props.resendEmailData.status === 200 &&
      props.resendEmailData.data !== state.resendEmailData
    ) {
      return {
        resendEmailData: props.resendEmailData.data,
        resendStatus: props.resendEmailData.data,
      };
    }

    if (
      props.resetPortalPasswordData !== undefined &&
      props.resetPortalPasswordData.status === 200 &&
      props.resetPortalPasswordData.data !== state.resetPortalPasswordData
    ) {
      return {
        resetPortalPasswordData: props.resetPortalPasswordData.data,
        resetPortalPasswordStatus: props.resetPortalPassword.data,
      };
    }

    if (
      props.deleteData !== undefined &&
      props.deleteData.status === 200 &&
      props.deleteData.data !== state.deleteData
    ) {
      return {
        deleteData: props.deleteData.data,
        deleteStatus: props.deleteData.data,
      };
    }

    if (
      props.exportClientPDFData !== undefined &&
      props.exportClientPDFData.status === 200 &&
      props.exportClientPDFData.data !== state.exportClientPDFData
    ) {
      let returnState = {};
      returnState.exportClientPDFData = props.exportClientPDFData.data;
      returnState.showLoader = false;

      if (
        props.exportClientPDFData.data &&
        props.exportClientPDFData.data.file
      ) {
        window.open(props.exportClientPDFData.data.file);
      }

      return returnState;
    } else if (
      props.exportClientPDFData !== undefined &&
      props.exportClientPDFData.status !== 200 &&
      props.exportClientPDFData.data !== state.exportClientPDFData
    ) {
      return {
        showLoader: false,
        exportClientPDFData: props.exportClientPDFData.data,
      };
    }

    if (
      props.sendPostInsData !== undefined &&
      props.sendPostInsData.status === 200 &&
      props.sendPostInsData.data !== state.sendPostInsData
    ) {
      return {
        sendPostInsData: props.sendPostInsData.data,
        showPreInsModal: false,
        showLoader: false,
      };
    } else if (
      props.sendPostInsData !== undefined &&
      props.sendPostInsData.status !== 200 &&
      props.sendPostInsData.data !== state.sendPostInsData
    ) {
      return {
        sendPostInsData: props.sendPostInsData.data,
        showPreInsModal: false,
        showLoader: false,
      };
    }

    if (
      props.healthProcedurePrescription !== undefined &&
      props.healthProcedurePrescription.status === 200 &&
      props.healthProcedurePrescription.data !==
      state.healthProcedurePrescription
    ) {
      toggleBodyScroll(false);
      props.emptyProcedureReducer();
      return {
        savedTime: props.healthProcedurePrescription.data,
        showPrescriptionModal: false,
        isSendEmail: false,
        prescriptionProcedureId: 0,
      };
    } else if (
      props.healthProcedurePrescription !== undefined &&
      props.healthProcedurePrescription.status !== 200
    ) {
      props.emptyProcedureReducer();
      return {
        showLoader: false,
      };
    }

    if (
      props.membershipData !== undefined &&
      props.membershipData.status === 200 &&
      props.membershipData.data !== state.membershipData &&
      state.membershipDataTime !== props.membershipDataTime
    ) {
      props.exportEmptyData();
      let monthly_membership_type = "free";
      let monthly_membership_fees = 0;
      let one_time_membership_setup = 0;
      let recurly_program_name = "";
      let membership_tier = "single";
      let membership_tier_id = "";
      let membership_on_all_product = 0;
      let membership_tier_types = [];
      let membership_tier_type_products = "";
      let discount_percentage = 0;
      let membership_payment_frequency = "";
      let membership_yearly_fees = 0;
      let membership_monthly_fees = 0;
      let total_membership_with_waive = 0;
      let total_membership_without_waive = 0;

      let membershipType = props.membershipData.data.type;
      let membership_clinic_name = props.membershipData.data
        .membership_clinic_name
        ? props.membershipData.data.membership_clinic_name
        : "";
      let membership_clinic_list = props.membershipData.data.clinic_list
        ? props.membershipData.data.clinic_list
        : [];
      let membership_clinic_id = props.membershipData.data.clinic_id
        ? props.membershipData.data.clinic_id
        : "";

      document.body.style.overflow = "hidden";

      if (membershipType === "start") {
        let patientEmail =
          state.clientData && state.clientData.email
            ? state.clientData.email
            : "";

        monthly_membership_type =
          props.membershipData.data.mothly_membership_type;
        membership_payment_frequency =
          props.membershipData.data.membership_tier == "single"
            ? props.membershipData.data.membership_payment_options
            : "";
        monthly_membership_fees =
          props.membershipData.data.membership_tier === "single" &&
            monthly_membership_type === "paid" &&
            (membership_payment_frequency?.toLowerCase() === "monthly" ||
              membership_payment_frequency?.toLowerCase() === "both") &&
            props.membershipData.data.mothly_membership_fees
            ? props.membershipData.data.mothly_membership_fees
            : 0;
        one_time_membership_setup =
          props.membershipData.data.membership_tier === "single"
            ? props.membershipData.data.one_time_membership_setup
            : 0;
        recurly_program_name = props.membershipData.data.recurly_program_name;
        membership_tier = props.membershipData.data.membership_tier;
        membership_tier_types =
          props.membershipData.data.membership_tier === "multiple" &&
            props.membershipData.data.membership_tier_types !== undefined
            ? props.membershipData.data.membership_tier_types
            : [];
        membership_yearly_fees =
          props.membershipData.data.membership_tier === "single" &&
            monthly_membership_type === "paid" &&
            (membership_payment_frequency?.toLowerCase() === "yearly" ||
              membership_payment_frequency?.toLowerCase() === "both") &&
            props.membershipData.data.yearly_membership_fees
            ? props.membershipData.data.yearly_membership_fees
            : 0;

        total_membership_with_waive =
          membership_payment_frequency?.toLowerCase() === "monthly"
            ? monthly_membership_fees
            : membership_payment_frequency?.toLowerCase() === "yearly"
              ? membership_yearly_fees
              : 0;
        total_membership_without_waive =
          total_membership_with_waive + one_time_membership_setup;

        if (
          props.membershipData.data.membership_tier === "multiple" &&
          membership_tier_types.length > 0
        ) {
          let membershipTierTypeProducts = [];
          membership_tier_id = membership_tier_types[0].id;
          membership_payment_frequency =
            membership_tier_types[0].membership_payment_options;
          membership_yearly_fees = membership_tier_types[0].price_per_year
            ? membership_tier_types[0].price_per_year
            : 0;
          membership_monthly_fees = membership_tier_types[0].price_per_month
            ? membership_tier_types[0].price_per_month
            : 0;
          if (membership_tier_types[0].tier_products) {
            membership_tier_types[0].tier_products.map((obj) => {
              const product_name =
                obj.product && obj.product.product_name
                  ? obj.product.product_name
                  : "";
              if (product_name) {
                membershipTierTypeProducts.push(
                  product_name + " - " + obj.units + " units",
                );
              }
            });
          }
          membership_tier_type_products = membershipTierTypeProducts.join(", ");
          monthly_membership_fees = membership_tier_types[0]["price_per_month"]
            ? membership_tier_types[0]["price_per_month"]
            : 0;
          one_time_membership_setup = membership_tier_types[0][
            "one_time_setup_fee"
          ]
            ? membership_tier_types[0]["one_time_setup_fee"]
            : 0;
          discount_percentage = membership_tier_types[0]["discount_percentage"]
            ? membership_tier_types[0]["discount_percentage"]
            : 0;
          membership_on_all_product = membership_tier_types[0]
            .discount_percentage
            ? membership_tier_types[0].discount_percentage
            : 0;

          total_membership_with_waive =
            membership_payment_frequency?.toLowerCase() === "monthly"
              ? monthly_membership_fees
              : membership_payment_frequency?.toLowerCase() === "yearly"
                ? membership_yearly_fees
                : 0;
          total_membership_without_waive =
            total_membership_with_waive + one_time_membership_setup;
        }

        if (props.membershipData.data.is_membership_enable == 0) {
          uiNotification.error(
            state.globalLang.message_cannot_subscribe_any_new_membership_plans,
          );
          return {
            showLoader: false,
          };
        }

        return {
          membershipData: props.membershipData.data,
          showImmediateMembershipModal: false,
          showFutureMembershipModal: false,
          showMembershipModal: true,
          showAgreement: false,
          patientEmailClass: "simpleInput",
          waiveOffOTFee: false,
          patientEmail: patientEmail,
          monthly_membership_type: monthly_membership_type,
          monthly_membership_fees: monthly_membership_fees,
          one_time_membership_setup: one_time_membership_setup,
          total_membership_with_waive: total_membership_with_waive,
          total_membership_without_waive: total_membership_without_waive,
          recurly_program_name: recurly_program_name,
          membership_tier: membership_tier,
          membership_tier_id: membership_tier_id,
          membership_tier_idClass: "simpleSelect",
          membership_tier_types: membership_tier_types,
          membership_tier_type_products: membership_tier_type_products,
          membership_on_all_product: membership_on_all_product,
          discount_percentage: discount_percentage,
          couponCodeClass: "simpleInput",
          couponCode: "",
          discountCouponApplied: 0,
          totalDiscount: 0,
          drawDate: dayOfMonth,
          drawMonth: numOfMonths,
          showLoader: false,
          membership_clinic_list: membership_clinic_list,
          membership_clinic_id: membership_clinic_id,
          membership_clinic_name: membership_clinic_name,
          membership_clinic_idClass: "simpleSelect",
          showMembershipClinicSection: false,
          holdStatus: props.membershipData.data.membership_hold_status,
          membershipId: props.membershipData.data.membership_id,
          membershipDataTime: props.membershipDataTime,
          membership_payment_frequency: membership_payment_frequency,
          membership_yearly_fees: membership_yearly_fees,
          membership_monthly_fees: membership_monthly_fees,
          selected_payment_option: membership_payment_frequency,
          payment_frequency: props.membershipData.data.payment_frequency,

          showSignatureCanvas: true,
          membershipCanvasClass: "signature-box sig-div",
          membershipCanvasInputOut: "input-outer no-display",
          isClearMembershipCanvasButton: true,
          isResetMembershipCanvasButton: false,
          membership_canvas_signature: "",
          membership_canvas_signature_url: "",
        };
      } else if (membershipType === "future") {
        return {
          membershipData: props.membershipData.data,
          showMembershipModal: false,
          showAgreement: false,
          showImmediateMembershipModal: false,
          showFutureMembershipModal: true,
          showLoader: false,
          appliedCoupon: props.membershipData.data.applied_coupon,
          total_discount: props.membershipData.data.total_discount,
          membership_on_all_product: props.membershipData.data
            .membership_tier_discount
            ? props.membershipData.data.membership_tier_discount
            : 0,
          membership_clinic_list: membership_clinic_list,
          membership_clinic_id: membership_clinic_id,
          membership_clinic_name: membership_clinic_name,
          membership_clinic_idClass: "simpleSelect",
          showMembershipClinicSection: false,
          membership_id: props.membershipData.data.membership_id
            ? props.membershipData.data.membership_id
            : "",
          holdStatus: props.membershipData.data.membership_hold_status,
          membershipId: props.membershipData.data.membership_id,
          payment_frequency: props.membershipData.data.payment_frequency,
          membershipDataTime: props.membershipDataTime,
        };
      } else if (membershipType === "immediate") {
        let membership_tier_type_products = "";
        let membershipTierTypeProducts = [];
        if (
          props.membershipData.data.membership_products &&
          props.membershipData.data.membership_products.length > 0
        ) {
          props.membershipData.data.membership_products.map((obj) => {
            const product_name =
              obj.product && obj.product.product_name
                ? obj.product.product_name
                : "";
            if (product_name) {
              membershipTierTypeProducts.push(
                product_name + " - " + obj.units + " units",
              );
            }
          });
        }
        membership_tier_type_products = membershipTierTypeProducts.join(", ");
        return {
          membershipData: props.membershipData.data,
          pastDueInvoiceData: props.membershipData.data.past_due_invoice_data,
          showMembershipModal: false,
          showAgreement: false,
          showImmediateMembershipModal: true,
          showFutureMembershipModal: false,
          showMembershipSection: true,
          showNewCardSection: false,
          showLoader: false,
          appliedCoupon: props.membershipData.data.applied_coupon,
          total_discount: props.membershipData.data.total_discount,
          membership_on_all_product: props.membershipData.data
            .membership_tier_discount
            ? props.membershipData.data.membership_tier_discount
            : 0,
          membership_clinic_list: membership_clinic_list,
          membership_clinic_id: membership_clinic_id,
          membership_clinic_name: membership_clinic_name,
          membership_clinic_idClass: "simpleSelect",
          showMembershipClinicSection: false,
          membership_id: props.membershipData.data.membership_id
            ? props.membershipData.data.membership_id
            : "",
          holdStatus: props.membershipData.data.membership_hold_status,
          membershipId: props.membershipData.data.membership_id,
          payment_frequency: props.membershipData.data.payment_frequency,
          membershipDataTime: props.membershipDataTime,
          membershipTierTypeProducts: membership_tier_type_products,
        };
      } else {
        return {
          membershipData: props.membershipData.data,
          showLoader: false,
          holdStatus: props.membershipData.data.membership_hold_status,
          membershipId: props.membershipData.data.membership_id,
          payment_frequency: props.membershipData.data.payment_frequency,
          membershipDataTime: props.membershipDataTime,
        };
      }
    } else if (
      props.membershipData !== undefined &&
      props.membershipData.status !== 200 &&
      props.membershipData.data !== state.membershipData &&
      state.membershipDataTime !== props.membershipDataTime
    ) {
      props.exportEmptyData();
      return {
        membershipData: props.membershipData.data,
        showLoader: false,
        holdStatus: props.membershipData.data.membership_hold_status,
        membershipId: props.membershipData.data.membership_id,
        payment_frequency: props.membershipData.data.payment_frequency,
        membershipDataTime: props.membershipDataTime,
      };
    }
    if (
      props.addMembershipData !== undefined &&
      props.addMembershipData.status === 200 &&
      props.addMembershipData.data !== state.addMembershipData
    ) {
      document.body.style.overflow = "";
      return {
        addMembershipData: props.addMembershipData.data,
        addMembershipMessage: props.addMembershipData.message,
        agreementData: {},
      };
    } else if (
      props.addMembershipData !== undefined &&
      props.addMembershipData.status !== 200 &&
      props.addMembershipData.data !== state.addMembershipData
    ) {
      return {
        showLoader: false,
        addMembershipData: props.addMembershipData.data,
        addMembershipMessage: "",
      };
    }

    if (
      props.cancelMembershipData !== undefined &&
      props.cancelMembershipData.status === 200 &&
      props.cancelMembershipData.data !== state.cancelMembershipData
    ) {
      document.body.style.overflow = "";
      return {
        cancelMembershipData: props.cancelMembershipData.data,
        cancelMembershipMessage: props.cancelMembershipData.message,
      };
    } else if (
      props.cancelMembershipData !== undefined &&
      props.cancelMembershipData.status !== 200 &&
      props.cancelMembershipData.data !== state.cancelMembershipData
    ) {
      document.body.style.overflow = "";
      return {
        showLoader: false,
        cancelMembershipData: props.cancelMembershipData.data,
        cancelMembershipMessage: "",
      };
    }

    if (
      props.couponData !== undefined &&
      props.couponData.status === 200 &&
      props.couponData.data !== state.couponData
    ) {
      let total_membership_with_waive =
        state.total_membership_with_waive -
        props.couponData.data.total_discount;
      let total_membership_without_waive =
        state.total_membership_without_waive -
        props.couponData.data.total_discount;
      return {
        couponData: props.couponData.data,
        showLoader: false,
        discountCouponApplied: 1,
        totalDiscount: props.couponData.data.total_discount,
        total_membership_with_waive: total_membership_with_waive,
        total_membership_without_waive: total_membership_without_waive,
      };
    } else if (
      props.couponData !== undefined &&
      props.couponData.status !== 200 &&
      props.couponData.data !== state.couponData
    ) {
      return {
        showLoader: false,
        couponData: props.couponData.data,
        discountCouponApplied: 0,
      };
    }

    if (
      props.MDSignData !== undefined &&
      props.MDSignData.status === 201 &&
      props.MDSignData.data !== state.MDSignData
    ) {
      props.exportEmptyData();
      return {
        MDSignData: props.MDSignData.data,
        showLoader: false,
        savedTime: moment().format("X"),
      };
    } else if (
      props.MDSignData !== undefined &&
      props.MDSignData.status !== 201 &&
      props.MDSignData.data !== state.MDSignData
    ) {
      props.exportEmptyData();
      return {
        showLoader: false,
        MDSignData: props.MDSignData.data,
      };
    }

    if (
      props.updateWalletCCData !== undefined &&
      props.updateWalletCCData.status === 200 &&
      props.updateWalletCCData.data !== state.updateWalletCCData
    ) {
      document.body.style.overflow = "";
      return {
        updateWalletCCData: props.updateWalletCCData.data,
        updateWalletCCMessage: props.updateWalletCCData.message,
        showImmediateMembershipModal: true,
      };
    } else if (
      props.updateWalletCCData !== undefined &&
      props.updateWalletCCData.status !== 200 &&
      props.updateWalletCCData.data !== state.updateWalletCCData
    ) {
      document.body.style.overflow = "";
      return {
        showLoader: false,
        updateWalletCCData: props.updateWalletCCData.data,
        updateWalletCCMessage: "",
      };
    }

    if (props.message != undefined && props.messageTime != state.messageTime) {
      props.history.push(`/clients`);
      return {
        messageTime: props.messageTime,
      };
    }

    if (
      props.isChangedMembershipClinic != undefined &&
      props.isChangedMembershipClinic === true
    ) {
      props.exportEmptyData();
      let membership_clinic_name = "";
      if (state.membership_clinic_list.length > 0) {
        let membership_clinic = state.membership_clinic_list.find(
          (x) => x.id == state.membership_change_clinic_id,
        );
        if (membership_clinic) {
          membership_clinic_name = membership_clinic.name;
        }
      }
      return {
        showLoader: false,
        showMembershipClinicSection: false,
        membership_clinic_id: state.membership_change_clinic_id,
        membership_clinic_name: membership_clinic_name,
      };
    }
    if (
      props.agreementData != undefined &&
      state.agreementData != props.agreementData &&
      props.agreementDataTime != state.agreementDataTime
    ) {
      props.exportEmptyData();

      let returnState = {};
      returnState.showLoader = false;

      if (props.agreementData.data) {
        returnState.agreementData = props.agreementData;
        returnState.activeModal = true;
        returnState.showAgreement = true;
        return returnState;
      } else {
        props.addMonthyMembership(state.clientID, state.membershipFormData);
      }
    }

    if (
      props.productData !== undefined &&
      props.productData.status === 200 &&
      props.productData.data !== state.productData
    ) {
      return {
        productData: props.productData.data,
        showSearchResult: true,
      };
    }

    if (
      props.templatesData !== undefined &&
      props.templatesData.status === 200 &&
      props.templatesData.data !== state.templatesData
    ) {
      return {
        showLoader: false,
        templatesData: props.templatesData.data,
        showTreatmentModal: true,
        showMembershipModal: false,
        showImmediateMembershipModal: false,
      };
    } else if (
      props.templatesData !== undefined &&
      props.templatesData.status !== 200 &&
      props.templatesData.data !== state.templatesData
    ) {
      return {
        showLoader: false,
        templatesData: props.templatesData.data,
      };
    }

    if (
      props.templatesDetails !== undefined &&
      props.templatesDetails.status === 200 &&
      props.templatesDetails.data !== state.templatesDetails
    ) {
      return {
        templatesDetails: props.templatesDetails.data,
      };
    } else if (
      props.templatesDetails !== undefined &&
      props.templatesDetails.status !== 200 &&
      props.templatesDetails.data !== state.templatesDetails
    ) {
      return {
        showLoader: false,
        templatesDetails: props.templatesDetails.data,
      };
    }

    if (
      props.savePaygData !== undefined &&
      props.savePaygData.status === 200 &&
      props.savePaygData.data !== state.savePaygData
    ) {
      return {
        savePaygData: props.savePaygData.data,
        showLoader: false,
        savePaygStatus: props.savePaygData.data,
        showCreateTreatModal: false,
        showTreatmentModal: false,
      };
    } else if (
      props.savePaygData !== undefined &&
      props.savePaygData.status !== 200 &&
      props.savePaygData.data !== state.savePaygData
    ) {
      return {
        savePaygData: props.savePaygData.data,
        showLoader: false,
        savePaygStatus: "",
      };
    }

    if (
      props.planDetails !== undefined &&
      props.planDetails.status === 200 &&
      props.planDetails.data !== state.planDetails
    ) {
      return {
        planDetails: props.planDetails.data,
        showLoader: false,
      };
    } else if (
      props.planDetails !== undefined &&
      props.planDetails.status !== 200 &&
      props.planDetails.data !== state.planDetails
    ) {
      return {
        planDetails: props.planDetails.data,
        showLoader: false,
      };
    }

    if (
      props.programDetails !== undefined &&
      props.programDetails.status === 200 &&
      props.programDetails.data !== state.programDetails
    ) {
      let startProgramCardNumber =
        props.programDetails.data &&
          props.programDetails.data.credit_card_details
          ? props.programDetails.data.credit_card_details
          : null;
      let cardNumberArray = [];

      if (startProgramCardNumber) {
        cardNumberArray = startProgramCardNumber;
      }

      let clinicOptData = "";

      if (
        props.programDetails.data &&
        props.programDetails.data.clinics &&
        props.programDetails.data.clinics.length > 0
      ) {
        clinicOptData = props.programDetails.data.clinics.map((obj, idx) => {
          return (
            <option key={idx} value={obj.id}>
              {shortenClinicName(obj.clinic_name)}
            </option>
          );
        });
      }

      return {
        selectedClearentCard:
          props.programDetails.data?.credit_card_details?.[0]?.card_on_file,
        programDetails: props.programDetails.data,
        showLoader: false,
        showStartProgram: true,
        cardType:
          state.userData.account.pos_gateway === "stripe"
            ? props.cardList && props.cardList.length
              ? "saved"
              : "new"
            : props.programDetails.data &&
              props.programDetails.data.credit_card_details
              ? "saved"
              : "new",
        showNewCardInput:
          props.programDetails.data &&
            props.programDetails.data.credit_card_details
            ? true
            : false,
        canExpireProduct:
          props.programDetails.data &&
            props.programDetails.data.accountPreference &&
            props.programDetails.data.accountPreference
              .expire_product_at_month_end
            ? 1
            : 0,
        discountValue:
          props.programDetails.data &&
            props.programDetails.data.treatMentPlan &&
            props.programDetails.data.treatMentPlan.discount_value
            ? props.programDetails.data.treatMentPlan.discount_value
            : "",
        discountType:
          props.programDetails.data &&
            props.programDetails.data.treatMentPlan &&
            props.programDetails.data.treatMentPlan.discount_type
            ? props.programDetails.data.treatMentPlan.discount_type
            : "percentage",
        cardNumberArray: cardNumberArray,
        proClinicOptData: clinicOptData,
        planClinic:
          props.programDetails.data &&
            props.programDetails.data.treatMentPlan &&
            props.programDetails.data.treatMentPlan.clinic_id
            ? props.programDetails.data.treatMentPlan.clinic_id
            : 0,
        startProTotal:
          props.programDetails.data &&
            props.programDetails.data.treatMentPlan &&
            props.programDetails.data.treatMentPlan.total_amount
            ? props.programDetails.data.treatMentPlan.total_amount
            : 0,
        startProMonthly:
          props.programDetails.data &&
            props.programDetails.data.treatMentPlan &&
            props.programDetails.data.treatMentPlan.monthly_amount
            ? props.programDetails.data.treatMentPlan.monthly_amount
            : 0,
        discountOuterClass: "TP-discount-outer",
        taxAmount:
          props.programDetails.data &&
            props.programDetails.data.default_clinic_tax
            ? props.programDetails.data.default_clinic_tax
            : 0,
        taxOuterClass: "TP-discount-outer",
      };
    } else if (
      props.programDetails !== undefined &&
      props.programDetails.status !== 200 &&
      props.programDetails.data !== state.programDetails
    ) {
      return {
        programDetails: props.programDetails.data,
        showLoader: false,
      };
    }

    if (
      props.startDiscountData !== undefined &&
      props.startDiscountData.status === 200 &&
      props.startDiscountData.data !== state.startDiscountData
    ) {
      return {
        startDiscountData: props.startDiscountData.data,
        showLoader: false,
        startProTotal: props.startDiscountData.data
          ? props.startDiscountData.data.total_amount
          : 0,
        startProMonthly: props.startDiscountData.data
          ? props.startDiscountData.data.monthly_amount
          : 0,
      };
    } else if (
      props.startDiscountData !== undefined &&
      props.startDiscountData.status !== 200 &&
      props.startDiscountData.data !== state.startDiscountData
    ) {
      return {
        startDiscountData: props.startDiscountData.data,
        showLoader: false,
      };
    }

    if (
      props.priceBreakUpData !== undefined &&
      props.priceBreakUpData.status === 200 &&
      props.priceBreakUpData.data !== state.priceBreakUpData
    ) {
      return {
        priceBreakUpData: props.priceBreakUpData.data,
        showLoader: false,
        showPriceBreakModal: true,
      };
    } else if (
      props.priceBreakUpData !== undefined &&
      props.priceBreakUpData.status !== 200 &&
      props.priceBreakUpData.data !== state.priceBreakUpData
    ) {
      return {
        priceBreakUpData: props.priceBreakUpData.data,
        showLoader: false,
      };
    }

    if (
      props.startProgram !== undefined &&
      props.startProgram.status === 200 &&
      props.startProgram.data !== state.startProgram
    ) {
      return {
        startProgram: props.startProgram.data,
        showLoader: false,
        startProStatus: props.startProgram.data,
        showStartProgram: false,
      };
    } else if (
      props.startProgram !== undefined &&
      props.startProgram.status !== 200 &&
      props.startProgram.data !== state.startProgram
    ) {
      return {
        startProgram: props.startProgram.data,
        showLoader: false,
        startProStatus: "",
      };
    }

    if (
      props.cancelPlanData !== undefined &&
      props.cancelPlanData.status === 200 &&
      props.cancelPlanData.data !== state.cancelPlanData
    ) {
      return {
        cancelPlanData: props.cancelPlanData.data,
        showLoader: false,
        cancelPlanStatus: props.cancelPlanData.data,
        showModal: false,
      };
    } else if (
      props.cancelPlanData !== undefined &&
      props.cancelPlanData.status !== 200 &&
      props.cancelPlanData.data !== state.cancelPlanData
    ) {
      return {
        cancelPlanData: props.cancelPlanData.data,
        showLoader: false,
        cancelPlanStatus: "",
      };
    }

    if (
      props.saveAsTemplateData !== undefined &&
      props.saveAsTemplateData.status === 200 &&
      props.saveAsTemplateData.data !== state.saveAsTemplateData
    ) {
      return {
        saveAsTemplateData: props.saveAsTemplateData.data,
        showLoader: false,
        showSaveTemplate: false,
        showPreviewDetails: true,
        templateName: "",
      };
    } else if (
      props.saveAsTemplateData !== undefined &&
      props.saveAsTemplateData.status !== 200 &&
      props.saveAsTemplateData.data !== state.saveAsTemplateData
    ) {
      return {
        saveAsTemplateData: props.saveAsTemplateData.data,
        showLoader: false,
        templateName: "",
      };
    }

    if (
      props.updatePlanCardData !== undefined &&
      props.updatePlanCardData.status === 200 &&
      props.updatePlanCardData.data !== state.updatePlanCardData
    ) {
      return {
        updatePlanCardData: props.updatePlanCardData.data,
        showLoader: false,
        showChangePlanCCPopup: false,
        updatePlanCardStatus: props.updatePlanCardData.data,
      };
    } else if (
      props.updatePlanCardData !== undefined &&
      props.updatePlanCardData.status !== 200 &&
      props.updatePlanCardData.data !== state.updatePlanCardData
    ) {
      return {
        updatePlanCardData: props.updatePlanCardData.data,
        showLoader: false,
        updatePlanCardStatus: "",
      };
    }

    if (
      props.presOnlyDetails !== undefined &&
      props.presOnlyDetails.status === 200 &&
      props.presOnlyDetails.data !== state.presOnlyDetails
    ) {
      let monthArray = [];
      let treatStartOnMonth = props.presOnlyDetails.data.start_month
        ? props.presOnlyDetails.data.start_month.toString().padStart(2, "0")
        : "";
      let treatStartOn =
        parseInt(props.presOnlyDetails.data.start_year) +
        "-" +
        treatStartOnMonth +
        "-" +
        "01";
      let expireAfter =
        props.presOnlyDetails.data && props.presOnlyDetails.data.duration;
      if (
        props.presOnlyDetails.data &&
        props.presOnlyDetails.data.plan_type?.toLowerCase() === "monthly"
      ) {
        for (let i = 1; i <= expireAfter; i++) {
          let tempArray = [];
          tempArray.push(moment(treatStartOn).format("MMMM, YYYY"));
          treatStartOn = moment(treatStartOn)
            .add(1, "month")
            .format("YYYY-MM-DD");
          monthArray.push(tempArray);
        }
      }
      return {
        presOnlyDetails: props.presOnlyDetails.data,
        showLoader: false,
        showPresOnlyPopup: true,
        pMonthArray: monthArray,
      };
    } else if (
      props.presOnlyDetails !== undefined &&
      props.presOnlyDetails.status !== 200 &&
      props.presOnlyDetails.data !== state.presOnlyDetails
    ) {
      return {
        presOnlyDetails: props.presOnlyDetails.data,
        showLoader: false,
      };
    }

    if (
      props.updatePresOnlyData !== undefined &&
      props.updatePresOnlyData.status === 200 &&
      props.updatePresOnlyData.data !== state.updatePresOnlyData
    ) {
      return {
        updatePresOnlyData: props.updatePresOnlyData.data,
        showLoader: false,
        showPreviewDetails: false,
        updatePresOnlyStatus: props.updatePresOnlyData.data,
      };
    } else if (
      props.updatePresOnlyData !== undefined &&
      props.updatePresOnlyData.status !== 200 &&
      props.updatePresOnlyData.data !== state.updatePresOnlyData
    ) {
      return {
        updatePresOnlyData: props.updatePresOnlyData.data,
        showLoader: false,
        updatePresOnlyStatus: "",
      };
    }

    if (
      props.performActionData !== undefined &&
      props.performActionData.status === 200 &&
      props.performActionData.data !== state.performActionData
    ) {
      let returnState = {};
      returnState.performActionData = props.performActionData.data;
      returnState.showLoader = false;

      if (
        state.treatmentAction === "print" ||
        state.treatmentAction === "download"
      ) {
        window.open(props.performActionData.data, "_blank");
      }

      if (state.treatmentAction === "send_to_patient") {
        uiNotification.clear();
        uiNotification.success(
          state.globalLang[props.performActionData.message],
        );
      }

      return returnState;
    } else if (
      props.performActionData !== undefined &&
      props.performActionData.status !== 200 &&
      props.performActionData.data !== state.performActionData
    ) {
      return {
        performActionData: props.performActionData.data,
        showLoader: false,
      };
    }
    if (
      props.selectedQuestionnaireData != undefined &&
      props.selectedQuestionnaireDataTime != state.selectedQuestionnaireDataTime
    ) {
      return {
        selectedQuestionnaireData: props.selectedQuestionnaireData.data,
        selectedQuestionnaireDataTime: props.selectedQuestionnaireDataTime,
        showLoader: false,
      };
    }

    if (
      props.newMembershipData != undefined &&
      props.newMembershipDataTime != state.newMembershipDataTime
    ) {
      let returnState = {};

      props.newMembershipData.data &&
        props.newMembershipData.data.memberships.length > 0 &&
        props.newMembershipData.data.memberships.map((obj, index) => {
          returnState["showMembershipClinic-" + index] = false;
          returnState["showDrawDay-" + index] = false;
        });
      returnState.newMembershipData = props.newMembershipData.data.memberships;
      returnState.membership_clinic_list =
        props.newMembershipData.data.clinic_list;
      returnState.newMembershipDataTime = props.newMembershipDataTime;
      returnState.showLoader = false;
      return returnState;
    }

    if (
      props.addNewMembershipData != undefined &&
      props.addNewMembershipDataTime != state.addNewMembershipDataTime
    ) {
      return {
        addNewMembershipData: props.addNewMembershipData,
        addNewMembershipDataTime: props.addNewMembershipDataTime,
        showImmediateMembershipModal: false,
        showMembershipModal: true,
        showLoader: false,
      };
    }
    if (
      props.clientClinicData !== undefined &&
      props.clientClinicData.status === 200 &&
      props.clientClinicDataTime != state.clientClinicDataTime
    ) {
      let returnState = {};
      returnState.showLoader = false;
      returnState.clientClinicData = props.clientClinicData.data;
      let clinic = state.clientData.clinics.find(
        (e) => e.id == state.patient_clinic_id,
      );
      if (clinic) {
        returnState.clinicName = clinic.clinic_name;
      }
      returnState.clientClinicDataTime = props.clientClinicDataTime;
      returnState.show_clinics = false;
      return returnState;
    } else if (
      props.clientClinicData !== undefined &&
      props.clientClinicData.status !== 200 &&
      props.clientClinicData.data !== state.clientClinicData
    ) {
      return {
        clientClinicData: props.clientClinicData.data,
        showLoader: false,
      };
    }

    if (
      props.newPresData !== undefined &&
      props.newPresData.status === 200 &&
      props.newPresData.data !== state.newPresData
    ) {
      toggleBodyScroll(false);
      document.body.style = "";
      return {
        newPresData: props.newPresData.data,
        showLoader: false,
        showNewPrescriptionModal: false,
        newPresStatus: props.newPresData.data,
      };
    } else if (
      props.newPresData !== undefined &&
      props.newPresData.status !== 200 &&
      props.newPresData.data !== state.newPresData
    ) {
      return {
        newPresData: props.newPresData.data,
        showLoader: false,
      };
    }

    if (
      props.pharmacyData !== undefined &&
      props.pharmacyData.status === 200 &&
      props.pharmacyData.data !== state.pharmacyData
    ) {
      if (state.clientData && state.clientData.pharmacy_list) {
        state.clientData.pharmacy_list.push({
          id: props.pharmacyData.data,
          name: state.pharmacy_name,
        });
      }
      return {
        pharmacyData: props.pharmacyData.data,
        showLoader: false,
        showPharmacyModal: false,
        showNewPrescriptionModal: true,
        pharmacyDataStatus: props.pharmacyData.data,
        pharmacy_id: props.pharmacyData.data,
      };
    } else if (
      props.pharmacyData !== undefined &&
      props.pharmacyData.status !== 200 &&
      props.pharmacyData.data !== state.pharmacyData
    ) {
      return {
        pharmacyData: props.pharmacyData.data,
        showLoader: false,
      };
    }

    if (
      props.generatePDFData !== undefined &&
      props.generatePDFData.status === 200 &&
      props.generatePDFData.data !== state.generatePDFData
    ) {
      document.body.style = "";
      let returnState = {};
      returnState.generatePDFData = props.generatePDFData.data;
      returnState.showLoader = false;

      if (props.generatePDFData.data && props.generatePDFData.data.file) {
        returnState.prescription_pdf_url = props.generatePDFData.data.file;
        returnState.generatePDFStatus = moment();
        window.open(props.generatePDFData.data.file);
      }

      return returnState;
    } else if (
      props.generatePDFData !== undefined &&
      props.generatePDFData.status !== 200 &&
      props.generatePDFData.data !== state.generatePDFData
    ) {
      return {
        generatePDFData: props.generatePDFData.data,
        showLoader: false,
      };
    }

    if (
      props.faxData !== undefined &&
      props.faxData.status === 200 &&
      props.faxData.data !== state.faxData
    ) {
      return {
        faxData: props.faxData.data,
        showLoader: false,
      };
    } else if (
      props.faxData !== undefined &&
      props.faxData.status !== 200 &&
      props.faxData.data !== state.faxData
    ) {
      return {
        faxData: props.faxData.data,
        showLoader: false,
      };
    }

    if (
      props.downloadMembershipAgreementData &&
      props.downloadMembershipAgreementData !==
      state.downloadMembershipAgreementData
    ) {
      if (props.downloadMembershipAgreementData.agreement_pdf_path) {
        window.open(
          props.downloadMembershipAgreementData.agreement_pdf_path,
          "_blank",
        );
      }
      return {
        downloadMembershipAgreementData: props.downloadMembershipAgreementData,
        showLoader: false,
      };
    }

    if (
      props.selectedQuestionnaireData != undefined &&
      props.selectedQuestionnaireDataTime != state.selectedQuestionnaireDataTime
    ) {
      return {
        selectedQuestionnaireData: props.selectedQuestionnaireData.data,
        selectedQuestionnaireDataTime: props.selectedQuestionnaireDataTime,
        showLoader: false,
      };
    }

    if (
      props.verifyPortalEmailData !== undefined &&
      props.verifyPortalEmailData.status === 200 &&
      props.verifyPortalEmailData.data !== state.verifyPortalEmailData
    ) {
      return {
        verifyPortalEmailData: props.verifyPortalEmailData.data,
        verifyPortalEmailStatus: props.verifyPortalEmailData.data,
      };
    }
    if (
      props.clearentKey !== undefined &&
      props.clearentKeyTime != state.clearentKeyTime
    ) {
      return {
        clearentKey: props.clearentKey.data.publickey,
        clearentKeyTime: props.clearentKeyTime,
        showLoader: false,
      };
    }

    if (
      props.scriptSureData !== undefined &&
      props.scriptSureData.status === 200 &&
      props.scriptSureData.data !== state.scriptSureData
    ) {
      if (props.scriptSureData.data.open_pupup) {
        return {
          scriptSureData: props.scriptSureData.data,
          showLoader: false,
          showEprescribeIframe: false,

          firstname: "",
          lastname: "",
          date_of_birth: "",
          gender: "1",
          phoneNumber: "",
          address_line_1: "",
          city: "",
          state: "",
          pincode: "",
          middlename: "",

          firstname_class: "newInputField",
          lastname_class: "newInputField",
          date_of_birth_class: "newInputField",
          gender_class: "newInputField",
          phoneNumber_class: "newInputField",
          address_line_1_class: "newInputField",
          city_class: "newInputField",
          state_class: "newInputField",
          pincode_class: "newInputField",
          middlename_class: "newInputField",

          showFieldsPopup: true,
        };
      } else {
        document.body.style.overflow = "hidden";

        return {
          scriptSureData: props.scriptSureData.data,
          showLoader: false,
          showEprescribeIframe: true,
          showFieldsPopup: false,
        };
      }
    } else if (
      props.scriptSureData !== undefined &&
      props.scriptSureData.status !== 200 &&
      props.scriptSureData.data !== state.scriptSureData
    ) {
      return {
        scriptSureData: props.scriptSureData.data,
        showLoader: false,
      };
    }

    if (
      props.appointmentData !== undefined &&
      props.appointmentDataTime != state.appointmentDataTime
    ) {
      returnState.appointmentDataTime = props.appointmentDataTime;
      if (state.appointmentData.length == 0) {
        if (props.appointmentData.data.next_page_url != null) {
          returnState.next_page_url = props.appointmentData.data.next_page_url;
        } else {
          returnState.next_page_url = props.appointmentData.data.next_page_url;
        }
        returnState.appointmentData = props.appointmentData.data.data;
      } else if (state.appointmentData.length > 0) {
        if (props.appointmentData.data.next_page_url != null) {
          returnState.next_page_url = props.appointmentData.data.next_page_url;
        } else {
          returnState.next_page_url = props.appointmentData.data.next_page_url;
        }
        returnState.appointmentData = [
          ...state.appointmentData,
          ...props.appointmentData.data.data,
        ];
      }
      returnState.showLoader = false;
      autoScrolling(false);
      return returnState;
    }

    if (
      props.customerNotesFetchedAt &&
      props.customerNotesFetchedAt !== state.customerNotesFetchedAt &&
      props.customerNotes
    ) {
      returnState = {
        ...returnState,
        stickyNotes: props.customerNotes.filter((noteDetails) => {
          const { is_sticky, patient_note_user } = noteDetails;
          const { is_acknowledged, patient_note_user_snooze } =
            patient_note_user || {};
          const { is_snoozed } = patient_note_user_snooze || {};
          return Boolean(is_sticky && !(is_acknowledged || is_snoozed));
        }),
        customerNotesFetchedAt: props.customerNotesFetchedAt,
      };
      return returnState;
    }

    if (props.dndStatus !== state.dndStatus) {
      props.getClientProfileDetail(state.clientID, state.clientScopes);
    }

    return null;
  }

  openModal = (event, membershipID) => {
    let message = event.target.dataset.message;
    let mtype = event.target.dataset.mtype;
    let action = event.target.dataset.action;
    let checked = "";

    if (action === "changePlanClinic") {
      this.setState({
        modalMessage: message,
        modalAction: action,
        modalActType: mtype,
        showModal: true,
        isSliderCheck: checked,
        showFutureMembershipModal: false,
        planClinic: event.target.value,
      });
    } else {
      this.setState({
        modalMessage: message,
        modalAction: action,
        modalActType: mtype,
        showModal: true,
        isSliderCheck: checked,
        showFuImmediatetureMembershipModal: false,
        selectedMembershipID: membershipID,
      });
    }
  };

  dismissModal = () => {
    let show = false;

    if (this.state.modalAction === "updateFireStatus") {
      let ref = "ref_1";
      this.refs[ref].checked = !this.refs[ref].checked;
    }

    if (this.state.modalAction === "updatePatientPortalAccess") {
      let ref = "ref_3";
      this.refs[ref].checked = !this.refs[ref].checked;
    }

    if (this.state.modalAction === "cancelMembership") {
      show = true;
    }

    if (this.state.modalAction === "changePlanClinic") {
      let clinicID = this.state.modalActType;

      this.setState({
        modalMessage: "",
        modalAction: "",
        modalActType: "",
        showModal: false,
        showFutureMembershipModal: show,
        planClinic: clinicID,
      });
    } else {
      this.setState({
        modalMessage: "",
        modalAction: "",
        modalActType: "",
        showModal: false,
        showFutureMembershipModal: show,
      });
    }
  };

  handleModalAction = () => {
    if (
      this.state.modalAction &&
      this.state.modalAction === "updateFireStatus"
    ) {
      this.updateFireStatus();
    }

    if (
      this.state.modalAction &&
      this.state.modalAction === "updatePatientPortalAccess"
    ) {
      this.updatePatientPortalAccess();
    }

    if (this.state.modalAction && this.state.modalAction === "deleteClient") {
      this.deleteClient();
      this.setState({
        modalMessage: "",
        modalAction: "",
        modalActType: "",
        showModal: false,
        showLoader: true,
      });
    }

    if (
      this.state.modalAction &&
      this.state.modalAction === "deleteProcedurePrescription"
    ) {
      this.deleteProcedurePrescription();
    }

    if (
      this.state.modalAction &&
      this.state.modalAction === "cancelMembership"
    ) {
      this.cancelMembership();
    }

    if (
      this.state.modalAction &&
      this.state.modalAction === "cancelStartedMembership"
    ) {
      this.cancelMembership();
    }

    if (
      this.state.modalAction &&
      this.state.modalAction === "changePlanClinic"
    ) {
      this.changePlanClinic();
    }

    if (this.state.modalAction && this.state.modalAction === "cancelPlan") {
      this.cancelPlan();
    }
  };

  updateFireStatus = () => {
    this.props.fireClient(this.state.clientID);
    this.setState({
      modalMessage: "",
      modalAction: "",
      modalActType: "",
      showModal: false,
      showLoader: true,
    });
  };

  updatePatientPortalAccess = () => {
    this.props.changePortaAccess(this.state.clientID);
    this.setState({
      modalMessage: "",
      modalAction: "",
      modalActType: "",
      showModal: false,
      showLoader: true,
    });
  };

  changeTimelinePref = (type) => {
    this.setState({ defTimeLine: type });

    if (type !== "treatmentPlan") {
      localStorage.setItem("defTimeLine", type);
    }
  };

  openProcedureDetail = (proID, proType) => {
    if (proType && proType === "cosmetic") {
      return (
        <div>
          {this.props.history.push(
            `/clients/procedure/edit/${proID}/${this.state.clientID}/profile`,
          )}
        </div>
      );
    } else if (proType && proType === "health") {
      return (
        <div>
          {this.props.history.push(
            `/clients/procedure-health/edit/${proID}/${this.state.clientID}/profile/false`,
          )}
        </div>
      );
    }
  };

  viewProcedureDetail = (proID, proType) => {
    if (proType && proType === "cosmetic") {
      return <div></div>;
    } else if (proType && proType === "health") {
      return (
        <div>
          {this.props.history.push(
            `/clients/procedure-health-detail/${proID}/${this.state.clientID}/profile`,
          )}
        </div>
      );
    }
  };

  viewQuestionnaires = (procedureId) => {
    history.push(ROUTES.patientQuestionnaires.manyByEntity({
      type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
      id: procedureId,
    }));
  };

  viewQuestionnairesList = (obj) => {
    if (obj) {
      history.push(ROUTES.patientQuestionnaires.manyByEntity({
        type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
        id: obj.id,
      }));
    }
  };

  viewConsents = (procedureId) => {
    history.push(ROUTES.patientConsents.manyByEntity({
      type: PATIENT_CONSENTS_ENTITY_TYPES.procedure,
      id: procedureId,
    }));
  };

  runCarousel = (e, imageCount) => {
    e.preventDefault();

    if (imageCount && imageCount > 0) {
      let targetNode =
        e.target.parentNode.parentNode.parentNode.children[0].children[0]
          .childNodes;
      let nodeLength = targetNode.length;

      for (let i = 0; i < nodeLength; i++) {
        if (targetNode[i].classList.contains("active")) {
          targetNode[i].classList.remove("active");

          if (i === nodeLength - 1) {
            i = -1;
          }

          targetNode[i + 1].classList.add("active");
          return;
        }
      }
    }
  };

  viewNotes = (proID) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/notes/${proID}/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  getMembershipPaymentDetails = () => {
    let clinic_id = this.state.membership_clinic_id;
    let subscription_type = this.state.selected_payment_option;
    if (!clinic_id || subscription_type?.toLowerCase() === "both") {
      return;
    }

    const subscription_started_at =
      this.state.selected_payment_option?.toLowerCase() === "yearly"
        ? moment()
        : moment(this.state.subscription_started_at);
    const data = {
      clinic_id,
      waive_off_one_time_fee: Number(this.state.waiveOffOTFee),
      start_date: subscription_started_at.format("Y-MM-DD"),
      membership_tier: this.state.membership_tier,
      membership_tier_id:
        this.state.membership_tier === "multiple"
          ? this.state.membership_tier_id
          : null,
      payment_frequency: subscription_type,
      draw_day: this.state.drawDate || null,
    };

    this.setState({ showLoader: true });

    if (this.state.discountCouponApplied) {
      this.props.getMembershipPaymentDetailsWithCoupon(this.state.clientID, {
        ...data,
        coupon_code: this.state.couponCode,
      });
    } else {
      this.props.getMembershipPaymentDetails(data);
    }
  };

  getDrawDayPaymentDetails = (
    clinic_id,
    membership_id,
    draw_day,
    started_at,
    drawMonth,
    applied_coupon,
    is_resume,
    chargeFullFee,
  ) => {
    this.setState({ showLoader: true });
    let data = {
      clinic_id,
      membership_id,
      draw_day: parseInt(draw_day),
      start_date: started_at,
      payment_frequency: this.state.selected_payment_option,
      hold_billing_cycles: drawMonth ? drawMonth : undefined,
      charge_full_fee: Number(chargeFullFee),
    };
    if (is_resume) {
      data.is_resume = true;
    }

    if (!applied_coupon) {
      this.props.getMembershipPaymentDetails(data);
    } else {
      this.props.getMembershipPaymentDetailsWithCoupon(
        this.state.clientID,
        data,
      );
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.fireStatus !== null &&
      this.state.fireStatus !== "" &&
      this.state.fireStatus !== prevState.fireStatus
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
      });
    }

    if (
      prevState.portalAccessStatus &&
      this.state.portalAccessStatus !== prevState.portalAccessStatus
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
      });
    }

    if (
      this.state.savedTime !== null &&
      this.state.savedTime !== "" &&
      this.state.savedTime !== prevState.savedTime
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        savedTime: null,
        editCardId: null,
      });
    }

    if (
      this.state.resendStatus !== null &&
      this.state.resendStatus !== "" &&
      this.state.resendStatus !== prevState.resendStatus
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        resendStatus: null,
      });
    }

    if (
      this.state.resetPortalPasswordStatus !== null &&
      this.state.resetPortalPasswordStatus !== "" &&
      this.state.resetPortalPasswordStatus !==
      prevState.resetPortalPasswordStatus
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        resendStatus: null,
      });
    }

    if (
      this.state.deleteStatus !== null &&
      this.state.deleteStatus !== "" &&
      this.state.deleteStatus !== prevState.deleteStatus
    ) {
      setTimeout(
        function() {
          return <div>{this.props.history.push(`/clients`)}</div>;
        }.bind(this),
        1000,
      );
    }

    if (
      prevState.addMembershipData &&
      this.state.addMembershipMessage &&
      this.state.addMembershipData !== prevState.addMembershipData
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      prevState.addMembershipData &&
      prevState.addMembershipData.status === 200
    ) {
      this.setState({
        showAgreement: false,
        showMembershipModal: false,
      });
    }

    if (
      this.state.cancelMembershipData !== null &&
      this.state.cancelMembershipData !== "" &&
      this.state.cancelMembershipData !== prevState.cancelMembershipData &&
      this.state.cancelMembershipMessage !== null &&
      this.state.cancelMembershipMessage !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.updateWalletCCData !== null &&
      this.state.updateWalletCCData !== "" &&
      this.state.updateWalletCCData !== prevState.updateWalletCCData &&
      this.state.updateWalletCCMessage !== null &&
      this.state.updateWalletCCMessage !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
      });
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.savePaygData !== null &&
      this.state.savePaygData !== "" &&
      this.state.savePaygData !== prevState.savePaygData &&
      this.state.savePaygStatus !== null &&
      this.state.savePaygStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails:
          this.state.submitType && this.state.submitType === "preview"
            ? true
            : false,
        defTimeLine: "treatmentPlan",
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.templatesDetails &&
      this.state.templatesDetails !== prevState.templatesDetails
    ) {
      this.generateTemplateHTML(this.state.templatesDetails, "template");
    }

    if (
      prevProps.planDetailsStatus === "loading" &&
      this.props.planDetailsStatus === "success"
    ) {
      this.generateTemplateHTML(this.state.planDetails, "treatment");
    }

    if (
      this.state.startProgram !== null &&
      this.state.startProgram !== "" &&
      this.state.startProgram !== prevState.startProgram &&
      this.state.startProStatus !== null &&
      this.state.startProStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "treatmentPlan",
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.cancelPlanData !== null &&
      this.state.cancelPlanData !== "" &&
      this.state.cancelPlanData !== prevState.cancelPlanData &&
      this.state.cancelPlanStatus !== null &&
      this.state.cancelPlanStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "treatmentPlan",
        subscriptionID: 0,
        treatmentPlanID: 0,
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.updatePlanCardData !== null &&
      this.state.updatePlanCardData !== "" &&
      this.state.updatePlanCardData !== prevState.updatePlanCardData &&
      this.state.updatePlanCardStatus !== null &&
      this.state.updatePlanCardStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "treatmentPlan",
        returnFromStartProgram: true,
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.updatePresOnlyData !== null &&
      this.state.updatePresOnlyData !== "" &&
      this.state.updatePresOnlyData !== prevState.updatePresOnlyData &&
      this.state.updatePresOnlyStatus !== null &&
      this.state.updatePresOnlyStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "treatmentPlan",
        returnFromStartProgram: true,
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.newPresData !== null &&
      this.state.newPresData !== "" &&
      this.state.newPresData !== prevState.newPresData &&
      this.state.newPresStatus !== null &&
      this.state.newPresStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "health",
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.generatePDFData !== null &&
      this.state.generatePDFData !== "" &&
      this.state.generatePDFData !== prevState.generatePDFData &&
      this.state.generatePDFStatus !== null &&
      this.state.generatePDFStatus !== ""
    ) {
      this.setState({
        showLoader: true,
        fireStatus: null,
        portalAccessStatus: null,
        showPreviewDetails: false,
        defTimeLine: "health",
      });

      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      this.state.verifyPortalEmailStatus !== null &&
      this.state.verifyPortalEmailStatus !== "" &&
      this.state.verifyPortalEmailStatus !== prevState.verifyPortalEmailStatus
    ) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );

      this.setState({
        showLoader: true,
        resendStatus: null,
      });
    }

    if (this.state.membershipDataTime !== prevState.membershipDataTime) {
      this.getMembershipPaymentDetails();
    }

    if (
      this.props.couponData &&
      !isEqual(prevProps.couponData, this.props.couponData)
    ) {
      this.getMembershipPaymentDetails();
    }

    if (
      prevProps.paymentDetailData &&
      this.props.paymentDetailData &&
      prevProps.paymentDetailData.status === "loading" &&
      this.props.paymentDetailData.status !== "loading"
    ) {
      this.setState({ showLoader: false });
    }

    if (!prevProps.addMembershipData && this.props.addMembershipData) {
      this.props.getClientProfileDetail(
        this.state.clientID,
        this.state.clientScopes,
      );
    }

    if (
      prevProps.cardList !== this.props.cardList &&
      this.props.cardList?.length === 1
    ) {
      this.setState({ cardSelected: this.props.cardList[0]?.id });
    }
  };

  hidePrescription = () => {
    this.setState({ showPrescriptionModal: false });
    toggleBodyScroll(false);
  };

  addMultiplePrescription = () => {
    let prescriptionField = this.state.prescriptionField;
    prescriptionField.push(initPrescriptionField());
    this.setState({ prescriptionField: prescriptionField });
  };

  saveProcedurePrescription = () => {
    if (isFormSubmit()) {
      let error = false;
      let errorMedicineName = false;
      let errorDoase = false;
      let prescriptionField = this.state.prescriptionField;
      prescriptionField.map((obj, idx) => {
        if (
          obj.medicine_name === "" ||
          obj.medicine_name === null ||
          obj.medicine_name === undefined
        ) {
          prescriptionField[idx]["medicineNameClass"] =
            "setting-input-box field_error";
          error = true;
        } else {
          if (obj.medicine_name.trim() === "") {
            prescriptionField[idx]["medicineNameClass"] =
              "setting-input-box field_error";
            error = true;
            errorMedicineName = true;
          } else {
            prescriptionField[idx]["medicineNameClass"] = "setting-input-box";
          }
        }
        if (
          obj.dosage === "" ||
          obj.dosage === null ||
          obj.dosage === undefined
        ) {
          prescriptionField[idx]["dosageClass"] =
            "setting-input-box field_error";
          error = true;
        } else {
          if (obj.dosage.trim() === "") {
            prescriptionField[idx]["dosageClass"] =
              "setting-input-box field_error";
            error = true;
            errorDoase = true;
          } else {
            prescriptionField[idx]["dosageClass"] = "setting-input-box";
          }
        }
      });
      this.setState({ prescriptionField: prescriptionField });
      if (error) {
        if (errorMedicineName) {
          uiNotification.clear();
          uiNotification.error("Please enter Medicine name");
        } else if (errorDoase) {
          uiNotification.clear();
          uiNotification.error("Please enter Dosage");
        }
        return false;
      }
      this.setState({ showLoader: true });
      let formData = {
        prescription_data: prescriptionField,
      };
      this.props.saveProcedurePrescription(
        this.state.prescriptionProcedureId,
        formData,
      );
    }
  };

  sendProcedurePrescription = () => {
    if (isFormSubmit()) {
      this.setState({ showLoader: true });
      this.props.sendProcedurePrescription(
        this.state.prescriptionProcedureId,
        this.state.clientID,
      );
    }
  };

  deleteProcedurePrescription = () => {
    this.setState({ showLoader: true, showModal: false });
    this.props.deleteProcedurePrescription(this.state.prescriptionProcedureId);
  };

  deleteMultiplePrescription = (index) => {
    if (index > -1) {
      let prescriptionField = this.state.prescriptionField;
      if (prescriptionField.length == 1) {
        return false;
      }
      prescriptionField.splice(index, 1);
      this.setState({ prescriptionField: prescriptionField });
    }
  };

  handleChangeDrawDateChange = (e, data) => {
    let value = this.state.drawDate;
    if (e) {
      this.handleInputChange(e);
      value = e.target.value;
    }
    this.getDrawDayPaymentDetails(
      data.clinic_id,
      data.membership_id,
      value,
      data.subscription_started_at,
    );
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (
      event.target.name === "firstname" ||
      event.target.name === "middlename" ||
      event.target.name === "lastname"
    ) {
      if (value.length > 35) {
        return;
      }
    }

    if (event.target.name === "state") {
      if (value.length > 2) {
        return;
      }
    }

    if (event.target.name === "planClinic") {
      if (this.state.planClinic == 0) {
        this.setState({ planClinic: event.target.value }, () => {
          this.changePlanClinic();
        });
      } else {
        this.openModal(event);
      }
    } else {
      const modal = event.target.dataset.modal;

      if (modal === "prescription") {
        const index = event.target.dataset.index;
        let prescriptionField = this.state.prescriptionField;
        prescriptionField[index][event.target.name] = value;
        this.setState({
          prescriptionField: prescriptionField,
          dataChanged: true,
        });
        return true;
      }

      if (modal === "prescriptionNew") {
        const index = event.target.dataset.index;
        let newPrescriptionField = this.state.newPrescriptionField;
        newPrescriptionField[index][event.target.name] = value;
        this.setState({
          newPrescriptionField: newPrescriptionField,
          dataChanged: true,
        });
        return true;
      }

      const multiplePaygList = this.state.multiplePaygList;

      if (event.target.dataset.index && !event.target.dataset.parentindex) {
        let inputName = target.name;
        const index = event.target.dataset.index;
        multiplePaygList[index][inputName] = value;
      }

      const multipleMonthlyList = this.state.multipleMonthlyList;

      if (event.target.dataset.index && event.target.dataset.parentindex) {
        let inputName = target.name;
        const parentIndex = event.target.dataset.parentindex;
        const index = event.target.dataset.index;
        multipleMonthlyList[parentIndex]["dataRows"][index][inputName] = value;
      }

      this.setState({
        [event.target.name]: value,
        dataChanged: true,
        editCardId: null,
        multiplePaygList,
        multipleMonthlyList,
      });

      if (event.target.name === "treatType") {
        this.changeTreatType(event.target.value);
      }

      if (event.target.name === "expirePlanAfter") {
        if (this.state.treatType?.toLowerCase() === "monthly") {
          this.setState({
            expirePlanAfter: event.target.value,
            showPreviewBtn: false,
            showGenerateBtn: true,
          });
        }
      }

      if (modal === "prescription") {
        const index = event.target.dataset.index;
        let prescriptionField = this.state.prescriptionField;
        prescriptionField[index][event.target.name] = value;
        this.setState({
          prescriptionField: prescriptionField,
          dataChanged: true,
        });
      } else if (event.target.name === "membership_tier_id") {
        let monthly_membership_fees = 0;
        let one_time_membership_setup = 0;
        let membership_tier_id = "";
        let membership_on_all_product = 0;
        let membership_tier_type_products = "";
        let membership_payment_frequency;
        let membership_yearly_fees;
        let membership_monthly_fees;
        let total_membership_with_waive = 0;
        let total_membership_without_waive = 0;

        if (value) {
          if (this.state.membership_tier_types.length > 0) {
            let membershipTierTypeProducts = [];
            const membership_tier_type = this.state.membership_tier_types.find(
              (x) => x.id == value,
            );
            if (membership_tier_type) {
              membership_payment_frequency =
                membership_tier_type.membership_payment_options;
              membership_yearly_fees = membership_tier_type.price_per_year
                ? membership_tier_type.price_per_year
                : 0;
              membership_monthly_fees = membership_tier_type.price_per_month
                ? membership_tier_type.price_per_month
                : 0;
              membership_tier_id = membership_tier_type.id;
              if (membership_tier_type.tier_products) {
                membership_tier_type.tier_products.map((obj) => {
                  const product_name =
                    obj.product && obj.product.product_name
                      ? obj.product.product_name
                      : "";
                  if (product_name) {
                    membershipTierTypeProducts.push(
                      product_name + " - " + obj.units + " units",
                    );
                  }
                });
              }
              membership_tier_type_products =
                membershipTierTypeProducts.join(", ");
              monthly_membership_fees = membership_tier_type.price_per_month
                ? membership_tier_type.price_per_month
                : 0;
              one_time_membership_setup =
                membership_tier_type.one_time_setup_fee
                  ? membership_tier_type.one_time_setup_fee
                  : 0;
              membership_on_all_product =
                membership_tier_type.discount_percentage
                  ? membership_tier_type.discount_percentage
                  : 0;

              total_membership_with_waive =
                membership_payment_frequency?.toLowerCase() === "monthly"
                  ? monthly_membership_fees
                  : membership_payment_frequency?.toLowerCase() === "yearly"
                    ? membership_yearly_fees
                    : 0;
              total_membership_without_waive =
                total_membership_with_waive + one_time_membership_setup;
            }
          }
        }
        this.setState(
          {
            [event.target.name]: value,
            dataChanged: true,
            waiveOffOTFee: false,
            monthly_membership_fees: monthly_membership_fees,
            one_time_membership_setup: one_time_membership_setup,
            total_membership_with_waive: total_membership_with_waive,
            total_membership_without_waive: total_membership_without_waive,
            membership_tier_id: membership_tier_id,
            membership_tier_idClass: "simpleSelect",
            membership_tier_type_products: membership_tier_type_products,
            membership_on_all_product: membership_on_all_product,
            discount_percentage: 0,
            couponCode: "",
            discountCouponApplied: false,
            totalDiscount: 0,
            membership_payment_frequency: membership_payment_frequency,
            membership_yearly_fees: membership_yearly_fees,
            membership_monthly_fees: membership_monthly_fees,
            selected_payment_option: membership_payment_frequency,
            couponCodeClass: "simpleInput",
          },
          () => this.getMembershipPaymentDetails(),
        );
      } else if (event.target.name === "selected_payment_option") {
        let total_membership_with_waive = 0;
        let total_membership_without_waive = 0;
        total_membership_with_waive =
          value?.toLowerCase() === "monthly"
            ? this.state.monthly_membership_fees
            : value?.toLowerCase() === "yearly"
              ? this.state.membership_yearly_fees
              : 0;
        total_membership_without_waive =
          total_membership_with_waive + this.state.one_time_membership_setup;
        this.setState(
          {
            [event.target.name]: value,
            dataChanged: true,
            total_membership_with_waive: total_membership_with_waive,
            total_membership_without_waive: total_membership_without_waive,
            couponCodeClass: "simpleInput",
            couponCode: "",
            discountCouponApplied: 0,
            totalDiscount: 0,
          },
          () => this.getMembershipPaymentDetails(),
        );
      } else {
        let inputName = event.target.name;
        this.setState(
          { [event.target.name]: value, dataChanged: true, editCardId: null },
          () => {
            if (["waiveOffOTFee", "drawDate"].includes(inputName)) {
              this.getMembershipPaymentDetails();
            }
          },
        );
      }
    }
  };

  viewTraceAbility = (proID) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/traceability-info/${proID}/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  viewHealthTraceability = (proID) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/traceability-info/${proID}/${this.state.clientID}/profile/health`,
        )}
      </div>
    );
  };

  getMedicalHistory = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/medical-history/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  getUpcomingAppointments = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/upcoming-appointments/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  getPaymentHistory = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/payment-history/${this.state.clientID}/profile/invoices`,
        )}
      </div>
    );
  };

  getCustomerNotes = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/customer-notes/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  getClientDocuments = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/documents/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  resendEmail = () => {
    this.setState({ showLoader: true });
    this.props.resendWelcomeEmail(this.state.clientID);
  };

  showResetPassModal = () => {
    this.setState({ showResetPassModal: true });
  };

  dismissPassModal = () => {
    this.setState({ showResetPassModal: false });
  };

  changePassType = () => {
    var oldState = this.state.resetPassType;
    var isTextOrHide = oldState === "password";
    var newState = isTextOrHide ? "text" : "password";
    var newClass = !isTextOrHide
      ? "client-treat-cal pass-hidden"
      : "client-treat-cal pass-show";

    this.setState({
      resetPassType: newState,
      eyeClass: newClass,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let password = this.state.clientNewPassword;
    let isValidPass =
      /^(?=.{8,})(?=.*[A-Z])(?=.*[`~*-/[\]\\|{}().:;,''""!_<>-@#$%^&+=]).*$/.test(
        password,
      );

    if (!isValidPass) {
      this.setState({
        resetPassClass: "setting-input-box p-r-40 setting-input-box-invalid",
      });
    } else if (
      this.state.clientData &&
      this.state.clientData.patient_account &&
      this.state.clientData.patient_account.patient_user &&
      this.state.clientData.patient_account.patient_user.email !== ""
    ) {
      this.setState({
        resetPassClass: "setting-input-box p-r-40",
        showLoader: true,
        showResetPassModal: false,
        clientNewPassword: "",
      });

      this.refs.clientPass.value = "";

      let formData = {
        portal_email:
          this.state.clientData &&
            this.state.clientData.patient_account &&
            this.state.clientData.patient_account.patient_user &&
            this.state.clientData.patient_account.patient_user.email !== ""
            ? this.state.clientData.patient_account.patient_user.email
            : "",
        patient_user_id:
          this.state.clientData.patient_account !== undefined &&
            this.state.clientData.patient_account !== null
            ? this.state.clientData.patient_account.patient_user_id
            : 0,
        portal_password: password,
      };

      this.props.resetPortalPassword(this.state.clientID, formData);
    }
  };

  getInvoiceDetails = (invoiceID) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/invoice/${invoiceID}/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  editClienProfile = () => {
    return (
      <div>
        {this.props.history.push(`/clients/${this.state.clientID}/profile`)}
      </div>
    );
  };

  deleteClient = () => {
    let recentlyViewedSaved = JSON.parse(
      localStorage.getItem("recentlyViewedData"),
    );

    if (recentlyViewedSaved) {
      if (recentlyViewedSaved.length > 0) {
        recentlyViewedSaved.map((obj, idx) => {
          if (obj.profile_id === parseInt(this.state.clientID)) {
            recentlyViewedSaved.splice(idx, 1);
          }
        });

        localStorage.removeItem("recentlyViewedData");
        localStorage.setItem(
          "recentlyViewedData",
          JSON.stringify(recentlyViewedSaved),
        );
      }
    }

    this.props
      .deleteClient(this.state.clientID)
      .then((res) => {
        uiNotification.success(
          this.state.globalLang[res.message] ||
          "Patient has been successfully deleted",
        );
        this.props.history.push(CLIENTS_ROUTES.root());
      })
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] ||
          tCommon(`apiError.${res.message}`, "Unable to delete patient"),
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  createProcedure = (proType) => {
    if (proType && proType === "cosmetic") {
      return (
        <div>
          {this.props.history.push(
            `/clients/procedure/add/${this.state.clientID}/profile`,
          )}
        </div>
      );
    } else if (proType && proType === "health") {
      return (
        <div>
          {this.props.history.push(
            `/clients/procedure-health/add/${this.state.clientID}/profile/false`,
          )}
        </div>
      );
    }
  };

  openClientWallet = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/wallet/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  exportClientPDF = () => {
    if (this.state.clientID) {
      this.setState({ showLoader: true });
      let formData = {
        patient_id: this.state.clientID,
      };

      this.props.exportClientPDF(formData);
    }
  };

  createAppointment = () => {
    if (this.state.clientID) {
      window.open(`/appointment/create/client/` + this.state.clientID);
    }
  };

  exportClientProcedures = () => {
    return (
      <div>
        {this.props.history.push(
          `/clients/export-procedures/${this.state.clientID}/profile`,
        )}
      </div>
    );
  };

  viewFilledSurvey = (procedureID, appointmentID) => {
    if (procedureID && appointmentID) {
      return (
        <div>
          {this.props.history.push(
            `/clients/export-procedures/${this.state.clientID}/profile/${procedureID}/${appointmentID}`,
          )}
        </div>
      );
    }
  };

  sendPostInstructions = () => {
    if (this.state.procedure_id) {
      this.setState({ showLoader: true, showInsSendModal: false });
      this.props.sendPostInstructions(
        this.state.procedure_id,
      ).finally(() => {
        this.setState({ showLoader: false });
      });
    }
  };

  showSendInstructionPopup = (procedureId) => {
    this.setState({
      showInsSendModal: true,
      procedure_id: procedureId,
    });
  };

  dismissInsSendModal = () => {
    this.setState({
      showInsSendModal: false,
      apptIDToSend: "",
      procedure_id: "",
    });
  };

  dismissPreModal = () => {
    this.setState({ showPreInsModal: false, apptIDToSend: "" });
  };

  sendPreInstructions = () => {
    if (this.state.apptIDToSend) {
      this.setState({ showLoader: true, showInsSendModal: false });
      let formData = {
        appointment_id: this.state.apptIDToSend,
      };
      this.props.sendPreInstructionsInAppointment(formData);
    }
  };

  dismissSkinModal = () => {
    this.setState({ showSkinModal: false });
  };

  viewBAVideos = (procedureID) => {
    if (procedureID) {
      return (
        <div>
          {this.props.history.push(
            `/clients/ba-videos/${procedureID}/${this.state.clientID}/profile`,
          )}
        </div>
      );
    }
  };

  showMembershipModal = () => {
    this.setState({
      showImmediateMembershipModal: !this.state.showImmediateMembershipModal,
    });
  };

  hideMembershipModal = () => {
    document.body.style.overflow = "";
    let ref = "ref_waive";

    if (this.refs[ref]) {
      this.refs[ref].checked = false;
    }

    this.setState({
      showMembershipModal: false,
      drawDate: dayOfMonth,
      subscription_started_at: new Date(),
      showSignatureCanvas: false,
    });
  };

  hideFutureMembershipModal = () => {
    document.body.style.overflow = "";
    this.setState({ showFutureMembershipModal: false });
  };

  handleMembershipSubmitValidations = () => {
    let error = false;

    if (
      this.state.membership_tier === "multiple" &&
      this.state.membership_tier_id <= 0
    ) {
      this.setState({
        membership_tier_idClass: "simpleSelect setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ membership_tier_idClass: "simpleSelect" });
    }

    if (
      (this.state.membershipData.membership_payment_options?.toLowerCase() == "both" ||
        this.state.membershipData.membership_payment_options == "") &&
      this.state.selected_payment_option?.toLowerCase() == "both"
    ) {
      this.setState({
        member_payment_optionsClass: "simpleSelect setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ member_payment_optionsClass: "simpleSelect" });
    }

    if (
      this.state.patientEmail.trim() === "" ||
      !validateEmail(this.state.patientEmail.trim())
    ) {
      this.setState({
        patientEmailClass: "simpleInput setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ patientEmailClass: "simpleInput" });
    }

    if (
      this.state.subscription_started_at === "" ||
      this.state.subscription_started_at === null
    ) {
      this.setState({
        subscription_started_atClass: "simpleInput setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ subscription_started_atClass: "simpleInput" });
    }

    if (
      (this.state.membership_payment_frequency?.toLowerCase() == "both" ||
        this.state.membership_payment_frequency == "") &&
      (this.state.selected_payment_option?.toLowerCase() == "both" ||
        this.state.selected_payment_option == "")
    ) {
      this.setState({
        member_payment_optionsClass: "simpleSelect setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ member_payment_optionsClass: "simpleSelect" });
    }

    if (
      this.state.membership_clinic_id === "" ||
      this.state.membership_clinic_id === null ||
      this.state.membership_clinic_id === undefined
    ) {
      this.setState({
        membership_clinic_idClass: "simpleSelect setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ membership_clinic_idClass: "simpleSelect" });
    }

    if (this.state.membershipData.is_membership_enable == 0) {
      uiNotification.error("You cannot subscribe any new membership plans");
      error = true;
    }
    if (
      this.state.membershipData.single_membership_subscribed == true &&
      this.state.membershipData.membership_tier == "single"
    ) {
      uiNotification.error("You have already subscribed this membership plan");
      error = true;
    }

    if (
      this.state.userData.account.pos_gateway === "stripe" &&
      this.state.cardType === "saved" &&
      !this.state.cardSelected &&
      !error
    ) {
      uiNotification.error("Please select a card");
      error = true;
    }

    if (
      (this.state.membership_canvas_signature_url === "" ||
        this.state.membership_canvas_signature_url === null) &&
      !error
    ) {
      if (
        (this._refMembershipSketch &&
          this._refMembershipSketch.toJSON().objects.length === 0 &&
          this.state.membershipCanvasClass.indexOf("no-display") === -1) ||
        (this.state.membershipCanvasClass.indexOf("no-display") > 0 &&
          this.state.signature_url === "")
      ) {
        this.setState({
          membershipCanvasClass: "signature-box sig-div signature-not-found",
        });
        uiNotification.error(
          this.state.globalLang.validation_md_signature_required_if,
        );
        error = true;
      }
    }

    return error;
  };

  handleMembershipSubmit = async (e) => {
    e.preventDefault();

    const error = this.handleMembershipSubmitValidations();
    if (error) {
      return;
    }

    if (
      this.state.membership_canvas_signature_url === "" ||
      this.state.membership_canvas_signature_url === null
    ) {
      let canvasFlag = await this.handleSaveMembershipCanvas();
      if (!canvasFlag) {
        return false;
      }
    }

    let ref = "ref_waive";

    if (this.refs[ref]) {
      this.refs[ref].checked = false;
    }

    uiNotification.clear();

    if (
      this.state.patientEmail.trim() !== "" &&
      validateEmail(this.state.patientEmail.trim())
    ) {
      if (!this.requiresCcDetails()) {
        let formData = {
          stripeToken: "",
          waive_off_one_time_fee: this.state.waiveOffOTFee ? 1 : 0,
          email: this.state.patientEmail,
          subscription_started_at: moment(
            this.state.subscription_started_at,
          ).format("YYYY-MM-DD"),
          membership_total: this.state.waiveOffOTFee
            ? this.state.total_membership_with_waive
            : this.state.total_membership_without_waive,
          membership_tier: this.state.membership_tier,
          clinic_id: this.state.membership_clinic_id,
          membership_agreement_id: 0,
          applied_discount_coupon: this.state.discountCouponApplied,
          coupon_code: this.state.couponCode,
          patient_signature: this.state.membership_canvas_signature,
        };
        if (this.state.membership_tier == "single") {
          formData.subscription_type =
            this.state.membershipData.membership_payment_options?.toLowerCase() == "both"
              ? this.state.selected_payment_option
              : this.state.membershipData.membership_payment_options;
          formData.membership_agreement_id =
            this.state.agreementData && this.state.agreementData.data
              ? this.state.agreementData.data.id
              : 0;
        }
        if (this.state.membership_tier === "multiple") {
          formData.membership_tier_id = this.state.membership_tier_id;
          formData.subscription_type =
            this.state.membership_payment_frequency?.toLowerCase() == "both"
              ? this.state.selected_payment_option
              : this.state.membership_payment_frequency;
          formData.membership_agreement_id =
            this.state.agreementData && this.state.agreementData.data
              ? this.state.agreementData.data.id
              : 0;
        }

        this.setState({ membershipFormData: formData });
        if (this.state.showAgreement == false) {
          this.openAgreement();
        }
      } else {
        if (
          this.state.userData &&
          this.state.userData.account &&
          this.state.userData.account.pos_gateway == "stripe"
        ) {
          let formData = {
            setup_intent_id:
              this.state.cardType === "new"
                ? this.props.stripeIntent
                  ? this.props.stripeIntent.setupIntentId
                  : ""
                : "",
            payment_method_id:
              this.state.cardType === "saved" ? this.state.cardSelected : "",
            waive_off_one_time_fee: this.state.waiveOffOTFee ? 1 : 0,
            email: this.state.patientEmail,
            subscription_started_at: moment(
              this.state.subscription_started_at,
            ).format("YYYY-MM-DD"),
            membership_total: this.state.waiveOffOTFee
              ? this.state.total_membership_with_waive
              : this.state.total_membership_without_waive,
            applied_discount_coupon: this.state.discountCouponApplied,
            coupon_code: this.state.couponCode,
            draw_day: this.state.drawDate,
            membership_tier: this.state.membership_tier,
            clinic_id: this.state.membership_clinic_id,
            membership_agreement_id:
              this.state.agreementData && this.state.agreementData.data
                ? this.state.agreementData.data.id
                : 0,
            patient_signature: this.state.membership_canvas_signature,
          };

          if (this.state.membership_tier == "single") {
            formData.subscription_type =
              this.state.membershipData.membership_payment_options?.toLowerCase() == "both"
                ? this.state.selected_payment_option
                : this.state.membershipData.membership_payment_options;
          }

          if (this.state.discountCouponApplied === 0) {
            delete formData.coupon_code;
          }

          if (this.state.draw_day === 0) {
            delete formData.draw_day;
          }

          if (this.state.membership_tier === "multiple") {
            formData.membership_tier_id = this.state.membership_tier_id;
            formData.subscription_type =
              this.state.membership_payment_frequency?.toLowerCase() == "both"
                ? this.state.selected_payment_option
                : this.state.membership_payment_frequency;
          }

          if (this.state.monthly_membership_type !== "paid") {
            delete formData.draw_day;
          }
          this.setState({ membershipFormData: formData });
          this.openAgreement();
        } else {
          let clearentError = {};
          clearentError["clearent_email_error"] = false;
          clearentError["clearent_zip_error"] = false;
          this.setState(clearentError);

          if (
            this.state.clearent_email_id == undefined ||
            this.state.clearent_email_id == "" ||
            !validator.isEmail(this.state.clearent_email_id)
          ) {
            clearentError.clearent_email_error = true;
          } else {
            clearentError.clearent_email_error = false;
          }
          if (
            this.state.clearent_zip == undefined ||
            this.state.clearent_zip == "" ||
            !validator.isNumeric(this.state.clearent_zip)
          ) {
            clearentError.clearent_zip_error = true;
          } else {
            clearentError.clearent_zip_error = false;
          }

          if (
            clearentError.clearent_email_error ||
            clearentError.clearent_zip_error
          ) {
            clearentError.showLoader = false;
            this.setState(clearentError);
            return false;
          }
          let cardDataToken = await getClearentToken();
          if (cardDataToken) {
            let formData = {
              token_id: cardDataToken,
              waive_off_one_time_fee: this.state.waiveOffOTFee ? 1 : 0,
              email: this.state.patientEmail,
              subscription_started_at: moment(
                this.state.subscription_started_at,
              ).format("YYYY-MM-DD"),
              membership_total: this.state.waiveOffOTFee
                ? this.state.total_membership_with_waive
                : this.state.total_membership_without_waive,
              applied_discount_coupon: this.state.discountCouponApplied,
              coupon_code: this.state.couponCode,
              draw_day: this.state.drawDate,
              membership_tier: this.state.membership_tier,
              clinic_id: this.state.membership_clinic_id,
              membership_agreement_id:
                this.state.agreementData && this.state.agreementData.data
                  ? this.state.agreementData.data.id
                  : 0,
              patient_signature: this.state.membership_canvas_signature,
              clearent_email_id: this.state.clearent_email_id,
              clearent_zip: this.state.clearent_zip,
            };

            if (this.state.membership_tier == "single") {
              formData.subscription_type =
                this.state.membershipData.membership_payment_options?.toLowerCase() == "both"
                  ? this.state.selected_payment_option
                  : this.state.membershipData.membership_payment_options;
            }

            if (this.state.discountCouponApplied === 0) {
              delete formData.coupon_code;
            }

            if (this.state.draw_day === 0) {
              delete formData.draw_day;
            }

            if (this.state.membership_tier === "multiple") {
              formData.membership_tier_id = this.state.membership_tier_id;
              formData.subscription_type =
                this.state.membership_payment_frequency?.toLowerCase() == "both"
                  ? this.state.selected_payment_option
                  : this.state.membership_payment_frequency;
            }

            if (this.state.monthly_membership_type !== "paid") {
              delete formData.draw_day;
            }
            this.setState({
              token_id: cardDataToken,
              membershipFormData: formData,
            });
            this.openAgreement();
          } else {
            this.setState({
              showLoader: false,
              mClearentError: cardDataToken.errros,
            });
            return false;
          }
        }
      }
    }
  };

  onChangeDatePicker = (value) => {
    this.setState(value, () => {
      if (value && value.subscription_started_at) {
        this.getMembershipPaymentDetails();
      }
    });

    if (value && value.treatmentStartOn) {
      if (this.state.treatType?.toLowerCase() === "monthly") {
        this.setState({ showPreviewBtn: false, showGenerateBtn: true });
      }
    }
  };

  cancelMembership = () => {
    document.body.style.overflow = "";
    let formData = {
      membership_id: this.state.selectedMembershipID,
    };
    this.props.cancelMembership(this.state.clientID, formData);

    this.setState({
      showLoader: true,
      showModal: false,
      showFutureMembershipModal: false,
      showImmediateMembershipModal: true,
      stripe_token: "",
    });
  };

  applyCouponCode = () => {
    let code = this.state.couponCode;
    const subscription_started_at =
      this.state.selected_payment_option?.toLowerCase() === "yearly"
        ? moment()
        : moment(this.state.subscription_started_at);

    if (
      (this.state.membership_payment_frequency?.toLowerCase() == "both" ||
        this.state.membership_payment_frequency == "") &&
      this.state.selected_payment_option?.toLowerCase() == "both"
    ) {
      this.setState({
        member_payment_optionsClass: "simpleSelect setting-input-box-invalid",
      });
      return;
    } else {
      this.setState({ member_payment_optionsClass: "simpleSelect" });
    }
    if (code.trim()) {
      this.setState({ couponCodeClass: "simpleInput", showLoader: true });
      let formData = {
        coupon_code: code,
        membership_tier_id: this.state.membership_tier_id
          ? this.state.membership_tier_id
          : 0,
        payment_frequency:
          this.state.membership_payment_frequency?.toLowerCase() == "both" ||
            this.state.membership_payment_frequency == ""
            ? this.state.selected_payment_option
            : this.state.membership_payment_frequency,
        draw_day: this.state.drawDate,
        start_date: subscription_started_at.format("Y-MM-DD"),
      };
      this.props.applyCouponCode(this.state.clientID, formData);
    } else {
      this.setState({
        couponCodeClass: "simpleInput setting-input-box-invalid",
      });
    }
  };

  handleSubmitPayment = async (e, index) => {
    e.preventDefault();
    uiNotification.clear();

    if (
      this.state.userData &&
      this.state.userData.account &&
      this.state.userData.account.pos_gateway === "stripe"
    ) {
      let formData = {
        setup_intent_id: this.props.stripeIntent
          ? this.props.stripeIntent.setupIntentId
          : "",
        membership_id: this.state.selectedMembershipID,
      };

      this.setState({
        showLoader: true,
        showImmediateMembershipModal: true,
        ["showCardSection-" + index]: false,
      });

      this.props.updateMembershipCC(this.state.clientID, formData);
      document.body.style.overflow = "";
    } else {
      let clearentError = {};
      clearentError["clearent_email_error_" + index] = false;
      clearentError["clearent_zip_error_" + index] = false;
      this.setState(clearentError);

      if (
        this.state["clearent_email_id_" + index] == undefined ||
        this.state["clearent_email_id_" + index] == "" ||
        !validator.isEmail(this.state["clearent_email_id_" + index])
      ) {
        clearentError["clearent_email_error_" + index] = true;
      } else {
        clearentError["clearent_email_error_" + index] = false;
      }
      if (
        this.state["clearent_zip_" + index] == undefined ||
        this.state["clearent_zip_" + index] == "" ||
        !validator.isNumeric(this.state["clearent_zip_" + index])
      ) {
        clearentError["clearent_zip_error_" + index] = true;
      } else {
        clearentError["clearent_zip_error_" + index] = false;
      }
      if (
        clearentError["clearent_email_error_" + index] ||
        clearentError["clearent_zip_error_" + index]
      ) {
        this.setState(clearentError, () => { });
        return false;
      }
      let cardDataToken = await getClearentToken();
      if (cardDataToken) {
        let formData = {
          membership_id: this.state.selectedMembershipID,
          clearent_zip: this.state["clearent_zip_" + index],
          clearent_email_id: this.state["clearent_email_id_" + index],
        };

        this.setState({ showLoader: true, showImmediateMembershipModal: true });

        this.props.updateMembershipCC(this.state.clientID, formData);
        document.body.style.overflow = "";
      } else {
        uiNotification.error("Please fill mandatory fields.");
        return false;
      }
    }
  };

  saveWithoutSign = () => {
    let proIDArr = [this.state.mdSignProcedureId];

    if (this.state.signature_url !== "") {
      let formData = {
        procedure_ids: proIDArr,
        signature_saved: this.state.save_sign ? 1 : 0,
        md_signature: this.state.signature,
      };

      let profileData = {
        params: {
          scopes: this.state.clientScopes,
        },
      };

      this.props.signProcedure(formData, false, profileData, "md-room");
      this.setState({
        signature_url: this.state.signature_url,
        uploadedSignature_url: this.state.signature_url,
        uploadedSignature: this.state.signature,
        signature: this.state.signature,
        inputOut: "input-outer",
        canvasClass: "signature-box sig-div  no-display",
        clearClass: "new-white-btn no-margin clear no-display",
        resetClass: "new-blue-btn reset  no-display",
        changeClass: "new-blue-btn no-margin Change",
        showSignModal: false,
        showLoader: true,
      });
    }
  };

  signThis = () => {
    if (
      (this._sketch &&
        this._sketch.toJSON().objects.length === 0 &&
        this.state.canvasClass.indexOf("no-display") === -1) ||
      (this.state.canvasClass.indexOf("no-display") > 0 &&
        this.state.signature_url === "")
    ) {
      uiNotification.error(
        this.state.globalLang.validation_md_signature_required_if,
      );
    } else {
      let proIDArr = [this.state.mdSignProcedureId];

      if (
        this.state.signature_url !== "" &&
        this.state.canvasClass.indexOf("no-display") > 0
      ) {
        let formData = {
          procedure_ids: proIDArr,
          signature_saved: this.state.save_sign ? 1 : 0,
          md_signature: this.state.signature,
        };

        let profileData = {
          params: {
            scopes: this.state.clientScopes,
          },
        };

        this.props.signProcedure(formData, false, profileData, "md-room");

        this.setState({
          signature_url: this.state.signature_url,
          uploadedSignature_url: this.state.signature_url,
          uploadedSignature: this.state.signature,
          signature: this.state.signature,
          inputOut: "input-outer",
          canvasClass: "signature-box sig-div  no-display",
          clearClass: "new-white-btn no-margin clear no-display",
          resetClass: "new-blue-btn reset  no-display",
          changeClass: "new-blue-btn no-margin Change",
          showSignModal: false,
          showLoader: true,
        });
      } else {
        clientProfileInstance
          .post(process.env.REACT_APP_API_URL + "upload/signature", {
            image_data: this._sketch.toDataURL(),
            upload_type: "signatures",
          })
          .then((response) => {
            if (response.data && response.data.status === 200) {
              let formData = {
                procedure_ids: proIDArr,
                signature_saved: this.state.save_sign ? 1 : 0,
                md_signature: response.data.data.file_name,
              };

              let profileData = {
                params: {
                  scopes: this.state.clientScopes,
                },
              };

              this.props.signProcedure(formData, false, profileData, "md-room");

              this.setState({
                signature_url: this.state.signature_url,
                uploadedSignature_url: this.state.signature_url,
                uploadedSignature: this.state.signature,
                signature: this.state.signature,
                inputOut: "input-outer",
                canvasClass: "signature-box sig-div  no-display",
                clearClass: "new-white-btn no-margin clear no-display",
                resetClass: "new-blue-btn reset  no-display",
                changeClass: "new-blue-btn no-margin Change",
                showSignModal: false,
                showLoader: true,
              });
            }
          })
          .catch(() => {
            uiNotification.error(
              this.state.roomTextData.signature_upload_error_text,
            );
          });
      }
    }
  };

  dismissSignModal = () => {
    this.setState({ showSignModal: false });
  };

  pastDueInvoiceData = (invoiceId) => {
    if (invoiceId) {
      let formData = {};
      formData = {
        invoice_id: invoiceId,
      };
      this.setState({ showLoader: true });
      this.props.pastDueInvoiceData(formData);
    }
  };

  handleClearReset = () => {
    this.setState({
      inputOut: "input-outer",
      canvasClass: "signature-box sig-div  no-display",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset  no-display",
      changeClass: "new-blue-btn no-margin Change",
    });
  };

  clear = () => {
    this._sketch.clear();
    this._sketch.setBackgroundFromDataUrl("");
    this.setState({
      controlledValue: null,
      backgroundColor: "transparent",
      fillWithBackgroundColor: false,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  clearCanvas = () => {
    if (this._sketch) {
      this._sketch.clear();
      this._sketch.setBackgroundFromDataUrl("");
    }
    this.setState({
      canvasClass: "signature-box sig-div",
      inputOut: "input-outer no-display",
      clearClass: "new-white-btn no-margin clear",
      resetClass: "new-blue-btn reset ",
      changeClass: "new-blue-btn no-margin Change no-display",
    });
  };

  changeMembershipClinic = (index, clinicID) => {
    let returnState = {};
    returnState["showMembershipClinic-" + index] = true;
    returnState.membership_clinic_idClass = "simpleSelect";
    returnState.membership_change_clinic_id = clinicID;
    this.setState(returnState, () => { });
  };

  changeMembershipDrawDay = (index, drawDay) => {
    let returnState = {};
    returnState["showDrawDay-" + index] = true;
    returnState.membership_clinic_idClass = "simpleSelect";
    returnState.membership_change_draw_day = drawDay;
    this.props.resetMembershipDetailsData();
    this.setState(returnState, () => { });
  };

  cancelChangeMembershipClinic = (index) => {
    let returnState = {};
    returnState["showMembershipClinic-" + index] = false;
    this.setState(returnState, () => { });
  };

  cancelMembershipDrawDay = (index) => {
    let returnState = {};
    returnState["showDrawDay-" + index] = false;
    this.setState(returnState, () => { });
  };

  handleChangeMembershipClinic = (e, membershipID) => {
    e.preventDefault();

    let error = false;
    if (
      this.state.membership_change_clinic_id === "" ||
      this.state.membership_change_clinic_id === null ||
      this.state.membership_change_clinic_id === undefined
    ) {
      this.setState({
        membership_clinic_idClass: "simpleSelect setting-input-box-invalid",
      });
      error = true;
    } else {
      this.setState({ membership_clinic_idClass: "simpleSelect" });
    }

    if (error) {
      return;
    }

    let formData = {
      membership_id: membershipID,
      clinic_id: this.state.membership_change_clinic_id,
    };

    this.setState({ showLoader: true });
    this.props.changeMembershipClinic(formData);
  };

  openAgreement = () => {
    let membership_tier_id = this.state.membership_tier_id
      ? this.state.membership_tier_id
      : 0;
    this.setState({ showLoader: true });
    this.props.fetchAgreement(membership_tier_id);
  };

  closeAgreement = () => {
    this.setState({ showAgreement: false, stripe_token: "" });
  };

  handleAgree = () => {
    if (this.state.membership_canvas_signature) {
      this.setState({
        showLoader: true,
        showAgreement: false,
        showMembershipModal: false,
      });
      let membershipFormData = this.state.membershipFormData;
      membershipFormData.membership_agreement_id =
        this.state.agreementData.data.id;
      membershipFormData.patient_signature =
        this.state.membership_canvas_signature;
      this.props.addMonthyMembership(this.state.clientID, membershipFormData);
    } else {
      this.setState(
        {
          showAgreement: false,
          stripe_token: "",
          membershipCanvasClass: "signature-box sig-div signature-not-found",
        },
        () => {
          uiNotification.clear();
          uiNotification.error(
            this.state.globalLang.validation_md_signature_required_if,
          );
        },
      );
      return;
    }
  };

  showTreatmentModal = () => {
    this.setState({ showLoader: true });
    this.props.getTreatmentTemplates();
  };

  hideTreatmentModal = () => {
    this.setState({ showTreatmentModal: false });
  };

  showCreateTreatModal = () => {
    this.setState({
      showCreateTreatModal: true,
      treatType: "payg",
      showPaygSection: true,
      showMonthlySection: false,
      showTreatmentModal: false,
      expirePlanAfter: 0,
      treatmentStartOn: new Date(),
      multiplePaygList: [initPaygElem()],
      multiplePaygClassList: [initPaygElemClass()],
      showSearchResult: false,
      showGenerateBtn: false,
      showPreviewBtn: true,
      multipleMonthlyList: [],
      skinGoal: "",
      prescribeOnly: "0",
      requestType: "add",
      requestMode: "new",
    });
  };

  closeCreateTratmentModal = () => {
    let showTreatmentModal = true;

    if (this.state.htmlGenerator && this.state.htmlGenerator === "treatment") {
      showTreatmentModal = false;
    }

    this.setState({
      showCreateTreatModal: false,
      treatType: "payg",
      showPaygSection: true,
      showMonthlySection: false,
      showTreatmentModal: showTreatmentModal,
      showGenerateBtn: false,
      showPreviewBtn: true,
      multipleMonthlyList: [],
      skinGoal: "",
      prescribeOnly: "0",
      requestType: "add",
      requestMode: "new",
      treatment_name: "",
    });
  };

  changeTreatType = (treatType) => {
    let showPayg = true;
    let showMontly = false;
    let treatmentType = "payg";
    let expirePlanAfter = 0;
    let showPreviewBtn = true;
    let showGenerateBtn = false;

    if (treatType?.toLowerCase() === "monthly") {
      showPayg = false;
      showMontly = true;
      treatmentType = "monthly";
      expirePlanAfter = 3;
      showGenerateBtn = true;
      showPreviewBtn = false;
    }
    this.setState({
      showPaygSection: showPayg,
      showMonthlySection: showMontly,
      treatType: treatmentType,
      expirePlanAfter: expirePlanAfter,
      treatmentStartOn: new Date(),
      multiplePaygList: [initPaygElem()],
      multiplePaygClassList: [initPaygElemClass()],
      showSearchResult: false,
      showGenerateBtn: showGenerateBtn,
      showPreviewBtn: showPreviewBtn,
      multipleMonthlyList: [],
      skinGoal: "",
      prescribeOnly: "0",
      requestType: "add",
      requestMode: "new",
    });
  };

  handleChildCreateSubmit = (e, submitType) => {
    submitType = submitType || "preview";
    e.preventDefault();

    if (this.state.treatType === "payg") {
      let error = false;
      let multiplePaygClassList = this.state.multiplePaygClassList;

      this.state.multiplePaygList.map((obj, idx) => {
        if (!obj.product_id) {
          multiplePaygClassList[idx]["product_name"] =
            "simpleInput field_error";
          error = true;
        } else {
          multiplePaygClassList[idx]["product_name"] = "simpleInput";
        }

        if (!isNumber(obj.units)) {
          multiplePaygClassList[idx]["units"] = "simpleInput field_error";
          error = true;
        } else {
          multiplePaygClassList[idx]["units"] = "simpleInput";
        }

        if (!isInt(obj.rep_val) || obj.rep_val <= 0) {
          multiplePaygClassList[idx]["rep_val"] =
            "simpleInput p-r-5 field_error";
          error = true;
        } else {
          multiplePaygClassList[idx]["rep_val"] = "simpleInput p-r-5";
        }
      });

      this.setState({
        multiplePaygClassList: multiplePaygClassList,
      });

      if (error) {
        return;
      }

      let formData = {};
      let treatProductName = [];
      let hProID = [];
      let treatProductUnits = [];
      let treatPlanRepValue = [];
      let treatPlanRepBy = [];

      this.state.multiplePaygList.map((obj) => {
        treatProductName.push(obj.product_name);
        hProID.push(obj.product_id);
        treatProductUnits.push(obj.units);
        treatPlanRepValue.push(obj.rep_val);
        treatPlanRepBy.push(obj.rep_by);
      });

      formData.patient_id = this.state.clientID;
      formData.treatment_name = this.state.treatment_name;
      formData.plan_id = this.state.treatmentPlanID;
      formData.request_type = this.state.requestType;
      formData.plan_status =
        this.state.planDetails && this.state.planDetails.treatment_plan
          ? this.state.planDetails.treatment_plan.status
          : "notsaved";
      formData.request_mode = this.state.requestMode;
      formData.edit_after_start = 0;
      formData.prescribe_only = parseInt(this.state.prescribeOnly);
      formData.treat_product_name = treatProductName;
      formData.h_pro_id = hProID;
      formData.treat_product_units = treatProductUnits;
      formData.treat_plan_rep_val = treatPlanRepValue;
      formData.treat_plan_rep_by = treatPlanRepBy;
      formData.treat_start_date = moment(this.state.treatmentStartOn).format(
        "YYYY-MM-DD",
      );
      formData.plan_duration = this.state.expirePlanAfter;
      formData.sub_id = this.state.subscriptionID;

      if (this.state.requestType === "add") {
        delete formData.plan_id;
        delete formData.sub_id;
      }

      this.setState({ showLoader: true, submitType: submitType });
      this.props
        .savePAYGTreatmentPlan(formData)
        .then((res) => {
          uiNotification.success(
            this.state.globalLang[res.message] ||
            "Successfully Created Treatment Plan",
          );
          this.setState({
            treatment_name: "",
          });
        })
        .catch((res) => {
          if (res.message === "treatment_name_required") {
            return uiNotification.error("Treatment Plan Name is required");
          }

          uiNotification.error(
            this.state.globalLang[res.message] ||
            "Unable To Create Treatment Plan",
          );
        });
    } else {
      let error = false;
      let multipleMonthlyClassList = this.state.multipleMonthlyClassList;
      let skinGoal = this.state.skinGoal;
      let skinGoalClass = "simpleInput";

      if (skinGoal.trim() === "") {
        error = true;
        skinGoalClass = "simpleInput field_error";
      }

      let totalDataRows = 0;
      let totalUnfilledRows = 0;

      this.state.multipleMonthlyList.map((obj, idx) => {
        totalDataRows += obj.dataRows.length;
        if (obj.dataRows) {
          obj.dataRows.map((mobj, midx) => {
            let productID = mobj.product_id;
            let units = mobj.units;

            if (!productID && !units) {
              totalUnfilledRows = totalUnfilledRows + 1;
            }

            if (productID && !isNumber(units)) {
              error = true;
              multipleMonthlyClassList[idx].dataRows[midx]["units"] =
                "simpleInput field_error";
            } else {
              multipleMonthlyClassList[idx].dataRows[midx]["units"] =
                "simpleInput";
            }

            if (!productID && isNumber(units)) {
              error = true;
              multipleMonthlyClassList[idx].dataRows[midx]["product_name"] =
                "simpleInput field_error";
            } else {
              multipleMonthlyClassList[idx].dataRows[midx]["product_name"] =
                "simpleInput";
            }
          });
        }
      });

      if (totalDataRows === totalUnfilledRows) {
        error = true;
        uiNotification.clear();
        uiNotification.error(
          "At least one product and its units are required to start treatment plan",
        );
      }

      this.setState({
        skinGoalClass: skinGoalClass,
        multipleMonthlyClassList: multipleMonthlyClassList,
      });

      if (error) {
        return;
      }

      let treatProductName = [];
      let hProID = {};
      let hMonth = {};
      let hYear = {};
      let treatProductUnits = {};
      let finalData = [];

      this.state.multipleMonthlyList.map((obj) => {
        treatProductName.push(obj.product_name);

        let month = parseInt(moment("1 " + obj.month).format("M"));
        let year = moment("1 " + obj.month).format("YYYY");

        hProID[month] = {};
        hMonth[month] = {};
        hYear[month] = {};
        treatProductUnits[month] = {};

        finalData.push({ month: month, year: year, data: obj.dataRows });

        if (obj.dataRows) {
          hProID[month][year] = [];
          hMonth[month][year] = [];
          hYear[month][year] = [];
          treatProductUnits[month][year] = [];

          obj.dataRows.map((mobj) => {
            hProID[month][year].push(mobj.product_id);
            hMonth[month][year].push(month);
            hYear[month][year].push(year);
            treatProductUnits[month][year].push(mobj.units);
          });
        }
      });

      let formData = {};
      formData.patient_id = this.state.clientID;
      formData.plan_id = this.state.treatmentPlanID;
      formData.request_type = this.state.requestType;
      formData.plan_status =
        this.state.planDetails && this.state.planDetails.treatment_plan
          ? this.state.planDetails.treatment_plan.status
          : "notsaved";
      formData.request_mode = this.state.requestMode;
      formData.edit_after_start = 0;
      formData.prescribe_only = parseInt(this.state.prescribeOnly);
      formData.treat_skin_goal = skinGoal;
      formData.treat_start_month = parseInt(
        moment(this.state.treatmentStartOn).format("MM"),
      );
      formData.treat_start_year = moment(this.state.treatmentStartOn).format(
        "YYYY",
      );
      formData.h_pro_id = hProID;
      formData.h_month = hMonth;
      formData.h_year = hYear;
      formData.treat_product_units = treatProductUnits;
      formData.finalData = finalData;
      formData.plan_duration = this.state.expirePlanAfter;
      formData.sub_id = this.state.subscriptionID;
      formData.treatment_name = this.state.treatment_name;

      if (this.state.requestType === "add") {
        delete formData.plan_id;
        delete formData.sub_id;
      }

      this.setState({ showLoader: true, submitType: submitType });
      this.props.saveMonthlyTreatmentPlan(formData).then(() => {
        this.setState({
          treatment_name: "",
        });
      });
    }
  };

  removePaygRows = (event) => {
    const index = event.target.dataset.index;

    let multiplePaygList = this.state.multiplePaygList;
    if (multiplePaygList.length === 1) {
      return false;
    }

    if (multiplePaygList[index] !== undefined) {
      multiplePaygList.splice(index, 1);
    }

    let multiplePaygClassList = this.state.multiplePaygClassList;
    if (multiplePaygClassList[index] !== undefined) {
      multiplePaygClassList.splice(index, 1);
    }
    this.setState({
      multiplePaygList: multiplePaygList,
      multiplePaygClassList: multiplePaygClassList,
      showSearchResult: false,
    });
  };

  addMorePaygRows = () => {
    let multiplePaygList = this.state.multiplePaygList;
    multiplePaygList.push(initPaygElem());

    let multiplePaygClassList = this.state.multiplePaygClassList;
    multiplePaygClassList.push(initPaygElemClass());
    this.setState({
      multiplePaygList: multiplePaygList,
      multiplePaygClassList: multiplePaygClassList,
      showSearchResult: false,
    });
  };

  handleProductChange = (event) => {
    const target = event.target;
    let value = target.value;
    let name = event.target.name;

    if (this.state.treatType === "payg") {
      const index = event.target.dataset.index;
      const multiplePaygList = this.state.multiplePaygList;

      let inputName = target.name;
      multiplePaygList[index][inputName] = value;

      let formData = {};
      formData.term = value;
      formData.patient_id = this.state.clientID;

      if (value.length > 2) {
        this.setState({
          multiplePaygList: multiplePaygList,
          selProIndex: index,
        });
        this.props.searchProduct(formData);
      }

      if (value.length <= 2) {
        let multiplePaygList = this.state.multiplePaygList;
        multiplePaygList[index]["product_id"] = "";

        this.setState({
          showSearchResult: false,
          selProIndex: -1,
          multiplePaygList: multiplePaygList,
        });
      }
    } else {
      let index = event.target.dataset.index;
      let parentIndex = event.target.dataset.parentindex;
      const multipleMonthlyList = this.state.multipleMonthlyList;
      multipleMonthlyList[parentIndex]["dataRows"][index][name] = value;

      let formData = {};
      formData.term = value;
      formData.patient_id = this.state.clientID;

      if (value.length > 2) {
        this.setState({
          multipleMonthlyList: multipleMonthlyList,
          selProIndex: index,
          selProParentIndex: parentIndex,
        });
        this.props.searchProduct(formData);
      }

      if (value.length <= 2) {
        let multipleMonthlyList = this.state.multipleMonthlyList;
        multipleMonthlyList[parentIndex]["dataRows"][index]["product_id"] = "";

        this.setState({
          showSearchResult: false,
          selProIndex: -1,
          multipleMonthlyList: multipleMonthlyList,
          selProParentIndex: -1,
        });
      }
    }
  };

  selectProduct = (obj) => {
    let productID = 0;
    let productName = "";

    if (obj && obj.data && obj.data.id) {
      productID = obj.data.id;
      productName = obj.data.product_name;
    }

    if (this.state.treatType === "payg") {
      const multiplePaygList = this.state.multiplePaygList;
      let selProIndex = this.state.selProIndex;

      if (productID && productID > 0 && productName) {
        if (selProIndex) {
          multiplePaygList[selProIndex]["product_id"] = productID;
          multiplePaygList[selProIndex]["product_name"] = productName;
        }
      }

      this.setState({
        multiplePaygList: multiplePaygList,
        showSearchResult: false,
        selProIndex: -1,
      });
    } else {
      let selProIndex = this.state.selProIndex;
      let selProParentIndex = this.state.selProParentIndex;
      const multipleMonthlyList = this.state.multipleMonthlyList;

      if (productID && productID > 0 && productName) {
        if (selProIndex && selProParentIndex) {
          multipleMonthlyList[selProParentIndex]["dataRows"][selProIndex][
            "product_id"
          ] = productID;
          multipleMonthlyList[selProParentIndex]["dataRows"][selProIndex][
            "product_name"
          ] = productName;
        }
      }

      this.setState({
        multipleMonthlyList: multipleMonthlyList,
        showSearchResult: false,
        selProIndex: -1,
        selProParentIndex: -1,
      });
    }
  };

  selectThisTemplate = () => {
    this.setState({ showLoader: true, showTemplateConfirmation: false });
    this.props.getTemplateDetails(this.state.tempObj.id).then((res) => {
      this.setState({
        treatment_name: res?.data?.treatment_plan_template?.plan_name || "",
      });
    });
  };

  showUseConfirmation = (tempObj) => {
    this.setState({ showTemplateConfirmation: true, tempObj: tempObj });
  };

  dismissUseModal = () => {
    this.setState({ showTemplateConfirmation: false, tempObj: {} });
  };

  generateMonthlyHTML = (e) => {
    e.preventDefault();
    let dateArray = [];
    let monthArray = [];
    let treatStartOn = this.state.treatmentStartOn;
    let expireAfter = this.state.expirePlanAfter;
    let classArray = [];

    for (let i = 1; i <= expireAfter; i++) {
      let monthName = moment(treatStartOn).format("MMMM, YYYY");
      treatStartOn = moment(treatStartOn).add(1, "month").format("YYYY-MM-DD");
      monthArray.push(monthName);
    }

    if (monthArray.length > 0) {
      monthArray.map((mobj, idx) => {
        let tempMonthList = [];
        let tempClassList = [];

        if (
          this.state.multipleMonthlyList &&
          this.state.multipleMonthlyList[idx] &&
          this.state.multipleMonthlyList[idx].dataRows &&
          this.state.multipleMonthlyList[idx].dataRows.length > 0
        ) {
          let monthlySchedules = this.state.multipleMonthlyList[idx].dataRows;

          monthlySchedules.map((mmobj) => {
            let tempObj = {};
            let tempClassObj = {};

            tempObj.product_id = mmobj.product_id;
            tempObj.product_name = mmobj.product_name;
            tempObj.month = mobj;
            tempObj.units = mmobj.units;

            tempClassObj.product_id = "simpleInput";
            tempClassObj.product_name = "simpleInput";
            tempClassObj.month = "simpleInput";
            tempClassObj.units = "simpleInput";

            tempMonthList.push(tempObj);
            tempClassList.push(tempClassObj);
          });
        } else {
          let tempObj = {};
          let tempClassObj = {};

          tempObj.product_id = "";
          tempObj.product_name = "";
          tempObj.month = mobj;
          tempObj.units = "";

          tempClassObj.product_id = "simpleInput";
          tempClassObj.product_name = "simpleInput";
          tempClassObj.month = "simpleInput";
          tempClassObj.units = "simpleInput";

          tempMonthList.push(tempObj);
          tempClassList.push(tempClassObj);
        }
        dateArray.push({ month: mobj, dataRows: tempMonthList });
        classArray.push({ month: mobj, dataRows: tempClassList });
      });
    }
    this.setState({
      showGenerateBtn: false,
      showPreviewBtn: true,
      multipleMonthlyList: dateArray,
      multipleMonthlyClassList: classArray,
    });
  };

  addMoreMonthlyRows = (passedIdx, passedObj) => {
    let multipleMonthList = JSON.parse(
      JSON.stringify(this.state.multipleMonthlyList),
    );
    let defMonthList = JSON.parse(
      JSON.stringify(this.state.multipleMonthlyListDefObj),
    );

    let multipleMonthlyClassList = JSON.parse(
      JSON.stringify(this.state.multipleMonthlyClassList),
    );
    let defClassList = JSON.parse(
      JSON.stringify(this.state.multipleMonthlyClassDefObj),
    );

    defMonthList["month"] = passedObj.month;
    defClassList["month"] = passedObj.month;

    multipleMonthList[passedIdx].dataRows.push(defMonthList);
    multipleMonthlyClassList[passedIdx].dataRows.push(defClassList);

    this.setState({
      multipleMonthlyList: multipleMonthList,
      multipleMonthlyClassList: multipleMonthlyClassList,
      showSearchResult: false,
    });
  };

  removeMonthlyRows = (parentIndex, index) => {
    let multipleMonthlyList = this.state.multipleMonthlyList;
    if (multipleMonthlyList[parentIndex]["dataRows"].length === 1) {
      return false;
    }

    if (multipleMonthlyList[parentIndex]["dataRows"][index] !== undefined) {
      multipleMonthlyList[parentIndex]["dataRows"].splice(index, 1);
    }

    let multipleMonthlyClassList = this.state.multipleMonthlyClassList;
    if (
      multipleMonthlyClassList[parentIndex]["dataRows"][index] !== undefined
    ) {
      multipleMonthlyClassList[parentIndex]["dataRows"].splice(index, 1);
    }
    this.setState({
      multipleMonthlyList: multipleMonthlyList,
      multipleMonthlyClassList: multipleMonthlyClassList,
      showSearchResult: false,
    });
  };

  saveThePlan = (e) => {
    e.preventDefault();

    this.handleChildCreateSubmit(e, "save");
  };

  hidePreviewDetails = (e) => {
    e.preventDefault();

    this.setState({ showPreviewDetails: false });
  };

  hideProgramDetails = (e) => {
    e.preventDefault();

    this.setState({
      showLoader: true,
      fireStatus: null,
      portalAccessStatus: null,
      showStartProgram: false,
      returnFromStartProgram: true,
      canExpireProduct: 0,
    });

    this.props.getClientProfileDetail(
      this.state.clientID,
      this.state.clientScopes,
    );
  };

  showProgramDetails = (e) => {
    e.preventDefault();

    if (
      this.state.cardType === "new" &&
      this.state.userData &&
      this.state.userData.account &&
      this.state.userData.account.pos_gateway == "stripe"
    ) {
      this.props.getSetupIntent({
        patient_id: this.state.clientID,
        clinic_id: this.state.clientData.current_treatment_plan.clinic_id,
      });
    }

    this.setState({
      showPreviewDetails: false,
      showLoader: true,
      treatmentPlanID: this.state.clientData.current_treatment_plan.id,
    });

    this.props.getProgramData(
      this.state.clientID,
      this.state.clientData.current_treatment_plan.id,
    );
  };

  getPlanDetailsByID = (planID, requestMode, subID) => {
    this.setState({
      showLoader: true,
      treatmentPlanID: planID,
      requestType: "edit",
      requestMode: requestMode,
      subscriptionID: subID,
    });

    this.props.getPlanDataByID(planID).then((res) => {
      this.setState({
        treatment_name: res.data.treatment_plan.plan_name,
      });
    });
  };

  generateTemplateHTML = (planDataObj, type) => {
    let treatType = "";
    if (type && type === "template") {
      treatType =
        planDataObj && planDataObj.treatment_plan_template
          ? planDataObj.treatment_plan_template.plan_type
          : "payg";
    } else {
      treatType =
        planDataObj && planDataObj.treatment_plan
          ? planDataObj.treatment_plan.plan_type
          : "payg";
    }

    let multiplePaygList = [];
    let multiplePaygClassList = [];

    let skinGoal = "";
    let dateArray = [];
    let classArray = [];

    if (treatType === "payg") {
      let paygObj = [];

      if (type && type === "template") {
        paygObj = planDataObj.template_schedule_pay_as_u_go;
      } else {
        paygObj = planDataObj.schedule_pay_as_u_go;
      }

      paygObj &&
        paygObj.length > 0 &&
        paygObj.map((obj) => {
          let tempObj = {};
          let tempClassObj = {};

          tempObj.product_id = obj.product_id;
          tempObj.product_name = obj.product ? obj.product.product_name : "";
          tempObj.rep_by =
            obj.repeat_by == "days"
              ? "day"
              : obj.repeat_by == "weeks"
                ? "week"
                : obj.repeat_by == "months"
                  ? "month"
                  : obj.repeat_by;
          tempObj.rep_val = obj.repeat_value;
          tempObj.units = obj.units;

          tempClassObj.product_name = "simpleInput";
          tempClassObj.product_id = "simpleInput";
          tempClassObj.units = "simpleInput";
          tempClassObj.rep_val = "simpleInput p-r-5";
          tempClassObj.rep_by = "simpleInput";

          multiplePaygList.push(tempObj);
          multiplePaygClassList.push(tempClassObj);
        });
    } else {
      let duration = "";
      if (type && type === "template") {
        skinGoal =
          planDataObj && planDataObj.treatment_plan_template
            ? planDataObj.treatment_plan_template.skincare_goal
            : "";
        duration =
          planDataObj && planDataObj.treatment_plan_template
            ? planDataObj.treatment_plan_template.duration
            : 3;
      } else {
        skinGoal =
          planDataObj && planDataObj.treatment_plan
            ? planDataObj.treatment_plan.skincare_goal
            : "";
        duration =
          planDataObj && planDataObj.treatment_plan
            ? planDataObj.treatment_plan.duration
            : 3;
      }

      let monthArray = [];
      let treatStartOn = new Date();

      if (type && type === "template") {
        treatStartOn = new Date();
      } else {
        let treatStartOnMonth = planDataObj.treatment_plan.start_month
          ? planDataObj.treatment_plan.start_month.toString().padStart(2, "0")
          : "";
        treatStartOn =
          planDataObj && planDataObj.treatment_plan
            ? parseInt(planDataObj.treatment_plan.start_year) +
            "-" +
            treatStartOnMonth +
            "-" +
            "01"
            : new Date();
      }

      let expireAfter = duration;
      for (let i = 1; i <= expireAfter; i++) {
        let tempArray = [];
        tempArray.push(moment(treatStartOn).format("MMMM, YYYY"));
        treatStartOn = moment(treatStartOn)
          .add(1, "month")
          .format("YYYY-MM-DD");
        monthArray.push(tempArray);
      }

      if (monthArray.length > 0) {
        monthArray.map((mobj, idx) => {
          let monthlySchedules = [];

          if (type && type === "template") {
            monthlySchedules =
              planDataObj && planDataObj.template_schedule_monthly
                ? planDataObj.template_schedule_monthly
                : [];
          } else {
            monthlySchedules =
              planDataObj && planDataObj.schedule_monthly
                ? planDataObj.schedule_monthly
                : [];
          }

          let tempMonthList = [];
          let tempClassList = [];

          if (monthlySchedules && monthlySchedules.length > 0) {
            monthlySchedules.map((mmobj) => {
              let monthToCompare = "";

              if (type && type === "template") {
                monthToCompare = mmobj.belong_to_month;
              } else {
                monthToCompare = mmobj.month + "-" + mmobj.year;
              }

              if (type && type === "template") {
                if (idx + 1 === monthToCompare) {
                  let tempObj = {};
                  let tempClassObj = {};

                  tempObj.product_id = mmobj.product_id;
                  tempObj.product_name = mmobj.product
                    ? mmobj.product.product_name
                    : "";
                  tempObj.month = mobj[0];
                  tempObj.units = mmobj.units;

                  tempClassObj.product_id = "simpleInput";
                  tempClassObj.product_name = "simpleInput";
                  tempClassObj.month = "simpleInput";
                  tempClassObj.units = "simpleInput";

                  tempMonthList.push(tempObj);
                  tempClassList.push(tempClassObj);
                }
              } else {
                if (
                  moment("01 " + mobj[0]).format("M-YYYY") == monthToCompare
                ) {
                  let tempObj = {};
                  let tempClassObj = {};

                  tempObj.product_id = mmobj.product_id;
                  tempObj.product_name = mmobj.product
                    ? mmobj.product.product_name
                    : "";
                  tempObj.month = mobj[0];
                  tempObj.units = mmobj.units;

                  tempClassObj.product_id = "simpleInput";
                  tempClassObj.product_name = "simpleInput";
                  tempClassObj.month = "simpleInput";
                  tempClassObj.units = "simpleInput";

                  tempMonthList.push(tempObj);
                  tempClassList.push(tempClassObj);
                }
              }
            });
          } else {
            let tempObj = {};
            let tempClassObj = {};

            tempObj.product_id = "";
            tempObj.product_name = "";
            tempObj.month = mobj[0];
            tempObj.units = "";

            tempClassObj.product_id = "simpleInput";
            tempClassObj.product_name = "simpleInput";
            tempClassObj.month = "simpleInput";
            tempClassObj.units = "simpleInput";

            tempMonthList.push(tempObj);
            tempClassList.push(tempClassObj);
          }

          dateArray.push({ month: mobj[0], dataRows: tempMonthList });
          classArray.push({ month: mobj[0], dataRows: tempClassList });
        });
      }
    }

    let returnState = {};
    returnState.showLoader = false;
    returnState.showTreatmentModal = false;
    returnState.treatType = treatType;
    returnState.showCreateTreatModal = true;
    returnState.showPaygSection = treatType === "payg" ? true : false;
    returnState.showMonthlySection = treatType?.toLowerCase() === "monthly" ? true : false;
    returnState.htmlGenerator = type;

    if (type && type === "template") {
      returnState.expirePlanAfter =
        planDataObj && planDataObj.treatment_plan_template
          ? planDataObj.treatment_plan_template.duration
          : 3;
    } else {
      returnState.expirePlanAfter =
        planDataObj && planDataObj.treatment_plan
          ? planDataObj.treatment_plan.duration
          : 3;
    }
    returnState.showSearchResult = false;
    returnState.showPreviewBtn = treatType === "payg" ? true : false;
    returnState.showGenerateBtn = treatType?.toLowerCase() === "monthly" ? true : false;
    returnState.treatmentStartOn = new Date();
    returnState.requestType = this.state.requestType
      ? this.state.requestType
      : "add";
    returnState.requestMode = this.state.requestMode
      ? this.state.requestMode
      : "new";

    if (treatType === "payg") {
      returnState.multiplePaygClassList = multiplePaygClassList;
      returnState.multiplePaygList = multiplePaygList;
    }

    if (treatType?.toLowerCase() === "monthly") {
      returnState.skinGoal = skinGoal;
      returnState.showGenerateBtn = false;
      returnState.showPreviewBtn = true;
      returnState.multipleMonthlyList = dateArray;
      returnState.multipleMonthlyClassList = classArray;
    }

    this.setState({ ...returnState });
  };

  changeDisCountType = (type) => {
    this.setState({ discountType: type });
  };

  applyProgramDiscount = (e) => {
    e.preventDefault();

    let discountValue = this.state.discountValue.toString().trim();
    let error = false;
    let discountOuterClass = "TP-discount-outer";

    if (discountValue === "") {
      error = true;
      discountOuterClass = "TP-discount-outer field_error";
    }

    if (!isNumber(discountValue)) {
      error = true;
      discountOuterClass = "TP-discount-outer field_error";
    } else {
      discountOuterClass = "TP-discount-outer";
    }

    this.setState({ discountOuterClass: discountOuterClass });

    if (error) {
      return;
    }

    let formData = {};
    formData.patient_id = this.state.clientId;
    formData.discount_value = this.state.discountValue;
    formData.discount_type = this.state.discountType;
    formData.plan_id = this.state.treatmentPlanID;

    this.setState({ showLoader: true });
    this.props
      .applyStartProgramDiscount(formData)
      .then((res) => {
        uiNotification.success(
          this.state.globalLang[res.message] || "Discout Applied Successfully",
        );
      })
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] || "Unable To Apply Discount",
        );
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  changePlanClinic = async () => {
    this.setState({ showModal: false, showLoader: true });

    let formData = {};
    formData.plan_id = this.state.treatmentPlanID;
    formData.clinic_id = this.state.planClinic;

    await this.props.getSetupIntent(
      { patient_id: this.state.clientID, clinic_id: this.state.planClinic },
      true,
    );
    this.props.changeTreatmentPlanClinic(formData);
  };

  viewBreakDown = (prescribeOnly) => {
    prescribeOnly = prescribeOnly || false;
    let planID = "";

    if (prescribeOnly) {
      planID = this.state.presOnlyDetails ? this.state.presOnlyDetails.id : 0;
    } else {
      planID =
        this.state.clientData && this.state.clientData.current_treatment_plan
          ? this.state.clientData.current_treatment_plan.id
          : 0;
    }

    if (planID) {
      this.setState({ showLoader: true });

      this.props.viewPriceBreakUp(planID);
    }
  };

  hideBreakDown = () => {
    this.setState({ showPriceBreakModal: false });
  };

  handleStartTreatmentProgram = (formData) => {
    this.props
      .startTreatmentProgram(formData)
      .then((res) => {
        uiNotification.success(
          this.state.globalLang[res.message] ||
          "Treatment Plan Started Successfully",
        );
      })
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] ||
          "Unable To Start Treatment Plan",
        );
      });
  };

  handleProgramSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    let taxAmount = this.state.taxAmount;
    let taxOuterClass = "TP-discount-outer";
    let hidIsTaxEnabled = 0;
    let defClinicTax = this.state.programDetails
      ? this.state.programDetails.default_clinic_tax
      : 0;
    let totalAmount = this.state.startProTotal;
    let cardType = this.state.cardType;
    let stripeIntent = this.props.stripeIntent
      ? this.props.stripeIntent.setupIntentId
      : "";
    let planType =
      this.state.clientData &&
        this.state.clientData.current_treatment_plan &&
        this.state.clientData.current_treatment_plan.plan_type
        ? this.state.clientData.current_treatment_plan.plan_type
        : "payg";
    let chargeType = planType?.toLowerCase() === "monthly" ? "now" : this.state.chargeType;
    let planID = this.state.treatmentPlanID;
    let formData = {};
    let canExiprePro = this.state.canExpireProduct;

    if (taxAmount != "" && !isNumber(taxAmount)) {
      error = true;
      taxOuterClass = "TP-discount-outer field_error";
    } else {
      taxOuterClass = "TP-discount-outer";
    }

    this.setState({ taxOuterClass: taxOuterClass });

    if (error) {
      return;
    }

    if (!this.state.planClinic) {
      uiNotification.error("Please Select a Clinic");
      return;
    }

    if (totalAmount < 1) {
      uiNotification.clear();
      uiNotification.error(
        "Minimum amount to start a plan should be at least " +
        numberFormat(1, "currency"),
      );
      return;
    }

    if (taxAmount || defClinicTax) {
      hidIsTaxEnabled = 1;
    }

    this.setState({ showLoader: true, returnFromStartProgram: true });
    if (
      cardType === "saved" &&
      this.state.userData.account.pos_gateway === "clearent"
    ) {
      formData.plan_id = planID;
      formData.payment_day = 1;
      formData.patient_id = this.state.clientID;
      formData.card_type = cardType;
      formData.can_expire_product = canExiprePro ? 1 : 0;
      formData.hid_is_tax_enabled = hidIsTaxEnabled;
      formData.hid_tax_amount = taxAmount;
      formData.charge_type = chargeType;
      formData.payment_method_id = this.state.selectedClearentCard;
      if (
        this.state.userData &&
        this.state.userData.account &&
        this.state.userData.account.pos_gateway == "clearent"
      ) {
        let clearentError = {};
        clearentError["clearent_email_error"] = false;
        clearentError["clearent_zip_error"] = false;
        this.setState(clearentError);

        if (
          this.state.clearent_email_id == undefined ||
          this.state.clearent_email_id == "" ||
          !validator.isEmail(this.state.clearent_email_id)
        ) {
          clearentError.clearent_email_error = true;
        } else {
          clearentError.clearent_email_error = false;
        }
        if (
          this.state.clearent_zip == undefined ||
          this.state.clearent_zip == "" ||
          !validator.isNumeric(this.state.clearent_zip)
        ) {
          clearentError.clearent_zip_error = true;
        } else {
          clearentError.clearent_zip_error = false;
        }
        if (
          clearentError.clearent_email_error ||
          clearentError.clearent_zip_error
        ) {
          clearentError.showLoader = false;
          this.setState(clearentError);
          return false;
        } else {
          formData.clearent_email_id = this.state.clearent_email_id;
          formData.clearent_zip = this.state.clearent_zip;
        }
      }
      this.handleStartTreatmentProgram(formData);
    } else {
      if (
        this.state.userData &&
        this.state.userData.account &&
        this.state.userData.account.pos_gateway == "stripe"
      ) {
        if (
          (cardType !== "saved" && stripeIntent) ||
          (cardType === "saved" && this.state.cardSelected)
        ) {
          formData.plan_id = planID;
          formData.payment_day = 1;
          formData.patient_id = this.state.clientID;
          formData.card_type = cardType;
          formData.can_expire_product = canExiprePro ? 1 : 0;
          formData.hid_is_tax_enabled = hidIsTaxEnabled;
          formData.hid_tax_amount = taxAmount;
          formData.charge_type = chargeType;
          if (cardType === "saved")
            formData.payment_method_id = this.state.cardSelected;
          else formData.setup_intent_id = stripeIntent;

          this.handleStartTreatmentProgram(formData);
        } else {
          if (cardType === "saved" && !this.state.cardSelected) {
            uiNotification.clear();
            uiNotification.error("Please select a card");
            this.setState({ showLoader: false });
            return;
          } else {
            this.setState({ showLoader: false });
          }
        }
      } else {
        let clearentError = {};
        clearentError["clearent_email_error"] = false;
        clearentError["clearent_zip_error"] = false;
        this.setState(clearentError);

        if (
          this.state.clearent_email_id == undefined ||
          this.state.clearent_email_id == "" ||
          !validator.isEmail(this.state.clearent_email_id)
        ) {
          clearentError.clearent_email_error = true;
        } else {
          clearentError.clearent_email_error = false;
        }
        if (
          this.state.clearent_zip == undefined ||
          this.state.clearent_zip == "" ||
          !validator.isNumeric(this.state.clearent_zip)
        ) {
          clearentError.clearent_zip_error = true;
        } else {
          clearentError.clearent_zip_error = false;
        }

        if (
          clearentError.clearent_email_error ||
          clearentError.clearent_zip_error
        ) {
          clearentError.showLoader = false;
          this.setState(clearentError);
          return false;
        }
        let cardDataToken = await getClearentToken();
        if (cardDataToken) {
          formData.plan_id = planID;
          formData.payment_day = 1;
          formData.patient_id = this.state.clientID;
          formData.card_type = cardType;
          formData.can_expire_product = canExiprePro ? 1 : 0;
          formData.hid_is_tax_enabled = hidIsTaxEnabled;
          formData.hid_tax_amount = taxAmount;
          formData.token_id = cardDataToken;
          formData.charge_type = chargeType;
          formData.clearent_email_id = this.state.clearent_email_id;
          formData.clearent_zip = this.state.clearent_zip;
          this.handleStartTreatmentProgram(formData);
        } else {
          this.setState({ showLoader: false });
          uiNotification.error("Invalid Card Details.");
          return false;
        }
      }
    }
  };

  cancelPlanByID = (planID, subID) => {
    this.setState({
      subscriptionID: subID,
      treatmentPlanID: planID,
      modalMessage:
        "Sure? Once canceled, you will not be able to use this plan.",
      modalAction: "cancelPlan",
      modalActType: "cancelPlan",
      showModal: true,
    });
  };

  cancelPlan = () => {
    this.setState({ showLoader: true });
    let formData = {};
    formData.patient_id = this.state.clientID;
    formData.plan_id = this.state.treatmentPlanID;
    formData.treatment_plan_subscription_id = this.state.subscriptionID;

    this.props
      .cancelTreatmentPlan(formData)
      .then((res) => {
        uiNotification.success(
          this.state.globalLang[res.message] ||
          "Treatment Plan Cancelled Successfully",
        );
      })
      .catch((res) => {
        uiNotification.error(
          this.state.globalLang[res.message] ||
          "Unable To Cancel Treatment Plan",
        );
      });
  };

  changePlanCard = () => {
    let subID =
      this.state.clientData &&
        this.state.clientData.current_treatment_plan &&
        this.state.clientData.current_treatment_plan.treatment_plan_subscription
        ? this.state.clientData.current_treatment_plan
          .treatment_plan_subscription.id
        : 0;

    let planID =
      this.state.clientData && this.state.clientData.current_treatment_plan
        ? this.state.clientData.current_treatment_plan.id
        : 0;

    if (this.state.userData.account.pos_gateway === "stripe") {
      this.props.getSetupIntent({
        patient_id: this.state.clientID,
        clinic_id: this.state.clientData?.current_treatment_plan?.clinic_id,
      });
    }

    this.setState(
      {
        showChangePlanCCPopup: true,
        treatmentPlanID: planID,
        subscriptionID: subID,
      },
      () => {
        if (
          this.state.userData &&
          this.state.userData.account &&
          this.state.userData.account.pos_gateway == "clearent"
        ) {
          let clinic = this.state.clientData.current_treatment_plan.clinic;
          if (clinic && clinic.publickey) {
            initializeClearentSdk(
              clinic.publickey,
              "change-treatment-card-number",
            );
          } else {
            uiNotification.error("POS is not setup for this clinic");
          }
        }
      },
    );
  };

  hideChangePlanCCPopup = () => {
    this.setState({ showChangePlanCCPopup: false });
  };

  handleChangeCard = async (e) => {
    e.preventDefault();
    this.setState({ showLoader: true });
    if (
      this.state.userData &&
      this.state.userData.account &&
      this.state.userData.account.pos_gateway == "stripe"
    ) {
      if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
        let formData = {};
        formData.setup_intent_id = this.props.stripeIntent.setupIntentId;
        formData.patient_id = this.state.clientID;
        formData.plan_id = this.state.treatmentPlanID;
        formData.treatment_plan_subscription_id = this.state.subscriptionID;

        this.props.updatePlanCard(formData);
      } else {
        this.setState({ showLoader: false });
      }
    } else {
      let clearentError = {};
      clearentError["clearent_email_error"] = false;
      clearentError["clearent_zip_error"] = false;
      this.setState(clearentError);

      if (
        this.state.clearent_email_id == undefined ||
        this.state.clearent_email_id == "" ||
        !validator.isEmail(this.state.clearent_email_id)
      ) {
        clearentError.clearent_email_error = true;
      } else {
        clearentError.clearent_email_error = false;
      }
      if (
        this.state.clearent_zip == undefined ||
        this.state.clearent_zip == "" ||
        !validator.isNumeric(this.state.clearent_zip)
      ) {
        clearentError.clearent_zip_error = true;
      } else {
        clearentError.clearent_zip_error = false;
      }

      if (
        clearentError.clearent_email_error ||
        clearentError.clearent_zip_error
      ) {
        clearentError.showLoader = false;
        this.setState(clearentError);
        return false;
      }
      let cardDataToken = await getClearentToken();
      if (cardDataToken) {
        let formData = {};
        formData.stripeToken = "";
        formData.setup_intent_id = "";
        formData.patient_id = this.state.clientID;
        formData.plan_id = this.state.treatmentPlanID;
        formData.treatment_plan_subscription_id = this.state.subscriptionID;
        formData.clearent_email_id = this.state.clearent_email_id;
        formData.clearent_zip = this.state.clearent_zip;
        formData.token_id = cardDataToken;
        this.props.updatePlanCard(formData);
      } else {
        this.setState({ showLoader: false });
        uiNotification.error("Invalid Card Details.");
        return false;
      }
    }
  };

  showTemplateNamePopup = () => {
    this.setState({ showPreviewDetails: false, showSaveTemplate: true });
  };

  hideTemplateNamePopup = () => {
    this.setState({
      showPreviewDetails: true,
      showSaveTemplate: false,
      templateNameClass: "simpleInput",
      templateName: "",
    });
  };

  handleTempNameSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let templateName = this.state.templateName;
    let templateNameClass = "simpleInput";

    if (templateName.trim() === "") {
      error = true;
      templateNameClass = "simpleInput field_error";
    } else {
      templateNameClass = "simpleInput";
    }

    this.setState({ templateNameClass: templateNameClass });

    if (error) {
      return;
    }

    let formData = {};
    formData.template_name = templateName;
    formData.treatment_plan_id = this.state.treatmentPlanID;

    this.setState({ showLoader: true });
    this.props.savePlanAsTemplate(formData);
  };

  getPrescribedPlanData = (obj) => {
    this.setState({ showLoader: true });

    this.props.getPrescribeOnlyDetails(obj.id);
  };

  hidePresOnlyPoup = () => {
    this.setState({ showPresOnlyPopup: false });
  };

  makePrescribeOnly = () => {
    if (this.state.treatmentPlanID) {
      let formData = {};
      formData.treatment_plan_id = this.state.treatmentPlanID;

      this.setState({ showLoader: true });

      this.props.updatePrescribeOnly(formData);
    }
  };

  performThisAction = (planID, actionType) => {
    this.setState({ treatmentAction: actionType, showLoader: true });
    this.props.doThisAction(planID, actionType);
  };

  showClinicDropdown = () => {
    this.setState({ show_clinics: true });
  };

  saveClinicOfClient = () => {
    this.setState({ showLoader: true });
    let formData = {};
    formData.clinic_id = this.state.patient_clinic_id;
    formData.patient_id = this.state.clientID;
    this.props.saveClinicOfClient(formData);
  };

  showPrescriptionNew = (proID, index, viewOnly) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/prescription/${proID}/${this.state.clientID}/${index}/${viewOnly}/profile`,
        )}
      </div>
    );
  };

  hidePrescriptionNew = () => {
    this.setState({ showNewPrescriptionModal: false });
    toggleBodyScroll(false);
  };

  addNewPrescription = () => {
    let prescriptionField = this.state.newPrescriptionField;
    prescriptionField.push(initNewPrescriptionField());
    this.setState({ newPrescriptionField: prescriptionField });
  };

  removeNewPrescription = (index) => {
    if (index) {
      let prescriptionField = this.state.newPrescriptionField;
      if (prescriptionField.length == 1) {
        return false;
      }
      prescriptionField.splice(index, 1);
      this.setState({ newPrescriptionField: prescriptionField });
    }
  };

  saveNewProcedurePrescription = () => {
    if (isFormSubmit()) {
      let error = false;
      let prescriptionField = this.state.newPrescriptionField;

      if (
        typeof this.state.prescription_note == undefined ||
        this.state.prescription_note == null ||
        this.state.prescription_note == ""
      ) {
        this.setState({
          prescription_note_class:
            "setting-textarea-box auto-height field_error",
        });
        error = true;
      } else {
        if (this.state.prescription_note.trim() === "") {
          this.setState({
            prescription_note_class:
              "setting-textarea-box auto-height field_error",
          });
          error = true;
        } else {
          this.setState({
            prescription_note_class: "setting-textarea-box auto-height",
          });
        }
      }

      if (
        typeof this.state.pharmacy_id == undefined ||
        this.state.pharmacy_id == null ||
        this.state.pharmacy_id == ""
      ) {
        this.setState({ pharmacy_id_class: "setting-select-box field_error" });
        error = true;
      } else if (this.state.pharmacy_id) {
        this.setState({ pharmacy_id_class: "setting-select-box auto-height" });
      }

      if (
        typeof this.state.date_needed == undefined ||
        this.state.date_needed == null ||
        this.state.date_needed == ""
      ) {
        this.setState({ date_needed_class: "setting-input-box field_error" });
        error = true;
      } else if (this.state.date_needed) {
        this.setState({ date_needed_class: "setting-input-box auto-height" });
      }

      if (
        this.state.ship_to &&
        this.state.ship_to != "prescriber" &&
        this.state.ship_to != "patient"
      ) {
        if (
          typeof this.state.shipto_ohers_addr == undefined ||
          this.state.shipto_ohers_addr == null ||
          this.state.shipto_ohers_addr == ""
        ) {
          this.setState({
            shipto_ohers_addr_class: "setting-input-box field_error",
          });
          error = true;
        } else if (this.state.shipto_ohers_addr) {
          this.setState({
            shipto_ohers_addr_class: "setting-input-box auto-height",
          });
        }
      }

      prescriptionField.map((obj, idx) => {
        if (
          obj.medicine_name === "" ||
          obj.medicine_name === null ||
          obj.medicine_name === undefined
        ) {
          prescriptionField[idx]["medicationClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.medicine_name.trim() === "") {
            prescriptionField[idx]["medicationClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["medicationClass"] =
              "tableInput p-l-5 p-r-5";
          }
        }

        if (obj.form === "" || obj.form === null || obj.form === undefined) {
          prescriptionField[idx]["formClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.form.trim() === "") {
            prescriptionField[idx]["formClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["formClass"] = "tableInput p-l-5 p-r-5";
          }
        }

        if (
          obj.strength === "" ||
          obj.strength === null ||
          obj.strength === undefined
        ) {
          prescriptionField[idx]["strengthClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.strength.trim() === "") {
            prescriptionField[idx]["strengthClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["strengthClass"] = "tableInput p-l-5 p-r-5";
          }
        }

        if (
          obj.quantity === "" ||
          obj.quantity === null ||
          obj.quantity === undefined
        ) {
          prescriptionField[idx]["quantityClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.quantity === "" || !isNumber(obj.quantity)) {
            prescriptionField[idx]["quantityClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["quantityClass"] = "tableInput p-l-5 p-r-5";
          }
        }

        if (
          obj.frequency === "" ||
          obj.frequency === null ||
          obj.frequency === undefined
        ) {
          prescriptionField[idx]["directionsClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.frequency === "") {
            prescriptionField[idx]["directionsClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["directionsClass"] =
              "tableInput p-l-5 p-r-5";
          }
        }

        if (
          obj.dosage === "" ||
          obj.dosage === null ||
          obj.dosage === undefined
        ) {
          prescriptionField[idx]["doseClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.dosage === "") {
            prescriptionField[idx]["doseClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["doseClass"] = "tableInput p-l-5 p-r-5";
          }
        }

        if (
          obj.refills === "" ||
          obj.refills === null ||
          obj.refills === undefined
        ) {
          prescriptionField[idx]["refillsClass"] =
            "tableInput p-l-5 p-r-5 field_error";
          error = true;
        } else {
          if (obj.refills === "" || !isNumber(obj.refills)) {
            prescriptionField[idx]["refillsClass"] =
              "tableInput p-l-5 p-r-5 field_error";
            error = true;
          } else {
            prescriptionField[idx]["refillsClass"] = "tableInput p-l-5 p-r-5";
          }
        }
      });

      this.setState({ newPrescriptionField: prescriptionField });

      if (error) {
        return false;
      } else {
        let formData = {
          procedure_id: this.state.prescriptionProcedureId,
          procedure_prescription_informations: {
            prescription_note: this.state.prescription_note,
            pharmacy_id: this.state.pharmacy_id,
            date_needed: moment(this.state.date_needed).format("YYYY-MM-DD"),
            ship_to: this.state.ship_to,
          },
          procedure_prescriptions: prescriptionField,
        };
        this.setState({ showLoader: true });

        this.props.saveNewPrescription(formData);
      }
    }
  };

  hidePharmacyModal = () => {
    this.setState({ showPharmacyModal: false, showNewPrescriptionModal: true });
    toggleBodyScroll(false);
  };

  showPharmacyModal = () => {
    this.setState({
      pharmacy_name: "",
      pharmacy_fax: "",
      pharmacy_phone: "",
      pharmacy_name_class: "setting-input-box",
      pharmacy_fax_class: "setting-input-box",
      pharmacy_phone_class: "setting-input-box",
      defaultCountry: localStorage.getItem("cCode"),
      showPharmacyModal: true,
      showNewPrescriptionModal: false,
    });
    toggleBodyScroll(false);
  };

  savePharmacy = () => {
    if (isFormSubmit()) {
      let error = false;

      if (
        typeof this.state.pharmacy_name == undefined ||
        this.state.pharmacy_name == null ||
        this.state.pharmacy_name == ""
      ) {
        this.setState({ pharmacy_name_class: "setting-input-box field_error" });
        error = true;
      } else {
        if (this.state.pharmacy_name.trim() === "") {
          this.setState({
            pharmacy_name_class: "setting-input-box field_error",
          });
          error = true;
        } else {
          this.setState({ pharmacy_name_class: "setting-input-box" });
        }
      }

      if (
        typeof this.state.pharmacy_phone == undefined ||
        this.state.pharmacy_phone == null ||
        this.state.pharmacy_phone == "" ||
        !phoneUtil.isPossible(phoneUtil.compose(this.state.pharmacy_phone))
      ) {
        error = true;
        this.setState({ pharmacy_phone_error: true });
        uiNotification.error("Please provide valid pharmacy phone number");
      } else if (this.state.pharmacy_phone) {
        this.setState({ pharmacy_phone_error: false });
      }

      if (
        this.state.pharmacy_fax &&
        !phoneUtil.isPossible(phoneUtil.compose(this.state.pharmacy_fax))
      ) {
        this.setState({ pharmacy_fax_error: true });
        uiNotification.error("Please provide valid pharmacy fax number");
        error = true;
      } else {
        this.setState({ pharmacy_fax_error: false });
      }

      if (error) {
        return false;
      } else {
        let formData = {
          name: this.state.pharmacy_name,
          fax: phoneUtil.compose(this.state.pharmacy_fax),
          phone: phoneUtil.compose(this.state.pharmacy_phone),
        };

        this.setState({ showLoader: true });

        this.props.savePharmacy(formData);
      }
    }
  };

  phoneNumberChanged = (inputFiled, nextPhone) => {
    if (phoneUtil.isPossible(phoneUtil.compose(nextPhone))) {
      if (this.state.phoneNumberError && inputFiled === "phoneNumber") {
        this.setState({
          phoneNumberError: false,
        });
      }
      if (this.state.pharmacy_fax_error && inputFiled === "pharmacy_fax") {
        this.setState({
          pharmacy_fax_error: false,
        });
      }
      if (this.state.pharmacy_phone_error && inputFiled === "pharmacy_phone") {
        this.setState({
          pharmacy_phone_error: false,
        });
      }
    }

    this.setState({
      userChanged: true,
      [inputFiled]: nextPhone,
    });
  };

  hideViewPrescriptionNew = () => {
    this.setState({ showNewPrescriptionModalInViewMode: false });
    toggleBodyScroll(false);
  };

  downloadPrescriptionPDF = () => {
    if (this.state.prescription_pdf_url) {
      window.open(this.state.prescription_pdf_url);
    } else {
      uiNotification.error("Document not found.");
    }
  };

  generatePrescriptionPDF = (proID) => {
    if (proID) {
      this.setState({ showLoader: true });
      this.props.generatePrescriptionPDF(proID);
    } else {
      uiNotification.error("Procedure not found.");
    }
  };

  faxPrescriptionPDF = (proID) => {
    if (proID) {
      this.setState({ showLoader: true });
      this.props.faxPrescriptionPDF(proID);
    } else {
      uiNotification.error("Procedure not found.");
    }
  };

  goToInvoicePage = (obj) => {
    if (obj.invoice_id == 0) {
      this.props.history.push(`/clients/invoice/create/${obj.id}`);
    } else if (obj.invoice_id > 0 && obj.pos_invoices) {
      this.props.history.push(
        `/clients/invoice/${obj.invoice_id}/${this.state.clientID}/profile`,
      );
    } else if (obj.invoice_id > 0 && obj.pos_invoices == null) {
      this.props.history.push(`/clients/invoice/create/${obj.id}`);
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  handleResetMembershipCanvas = () => {
    this.setState({
      isResetMembershipCanvasButton: false,
      isClearMembershipCanvasButton: true,
      membershipCanvasInputOut: "input-outer no-display",
      membershipCanvasImgSrcClass: "img-src no-display",
      membershipCanvasClass: "signature-box sig-div",
      membership_canvas_signature_url: "",
      membership_canvas_signature: "",
    });
  };

  handleClearMembershipCanvas = () => {
    if (this._refMembershipSketch) {
      this._refMembershipSketch.clear();
      this._refMembershipSketch.setBackgroundFromDataUrl("");
      this.setState({
        membership_canvas_signature_url: "",
        membership_canvas_signature: "",
        isResetMembershipCanvasButton: false,
        controlledValue: null,
        backgroundColor: "transparent",
        fillWithBackgroundColor: false,
        canUndo: this._refMembershipSketch.canUndo(),
        canRedo: this._refMembershipSketch.canRedo(),
      });
    }
  };

  handleSaveMembershipCanvas = async () => {
    let returnFlag = false;
    this.setState({ showLoader: true });
    await clientProfileInstance
      .post(process.env.REACT_APP_API_URL + "upload/signature", {
        image_data: this._refMembershipSketch.toDataURL(),
        upload_type: "patient_signatures",
      })
      .then((response) => {
        if (response.data && response.data.status === 200) {
          this.setState({
            membership_canvas_signature_url: response.data.data.signature_url,
            membership_canvas_signature: response.data.data.file_name,
            membershipCanvasInputOut: "input-outer",
            membershipCanvasImgSrcClass: "img-src",
            membershipCanvasClass: "signature-box sig-div  no-display",
            isResetMembershipCanvasButton: true,
            isClearMembershipCanvasButton: false,
          });
          returnFlag = true;
        } else {
          uiNotification.error(this.state.globalLang[response.data.message]);
          this.setState({ showLoader: false });
          returnFlag = false;
        }
      })
      .catch(() => {
        uiNotification.error(this.state.globalLang.unknown_error_file_upload);
        this.setState({ showLoader: false });
        returnFlag = false;
      });
    return returnFlag;
  };

  downloadMembershipAgreement = (membershipId) => {
    this.setState({ showLoader: true });
    const formData = { params: { patient_id: this.state.clientID } };
    this.props.downloadMembershipAgreement(membershipId, formData);
  };

  verifyEmail = () => {
    let portalEmail = "";

    if (
      this.state.clientData.patient_account &&
      this.state.clientData.patient_account.patient_user
    ) {
      portalEmail = this.state.clientData.patient_account.patient_user.email;
    }

    let formData = {
      portal_email: portalEmail,
    };

    this.props.verifyPortalEmail(formData);
  };

  handleMembershipClinicChange = (event) => {
    const target = event.target;
    const value = target.value;
    let returnState = {};
    returnState[event.target.name] = value;
    this.setState(returnState, () => {
      this.getMembershipPaymentDetails();
      if (
        this.state.userData &&
        this.state.userData.account &&
        this.state.userData.account.pos_gateway == "clearent"
      ) {
        let clinic = this.state.membership_clinic_list.find(
          (x) => x.id == value,
        );
        if (clinic && clinic.publickey) {
          initializeClearentSdk(clinic.publickey, "membership-payment-form");
        } else {
          window.ClearentSDK.reset();
          uiNotification.error("POS is not setup for this clinic");
        }
      }
    });
  };

  handleClearentChange = (clearentState) => {
    this.setState(clearentState);
  };

  handleEprescribe = (obj) => {
    let returnState = {};
    returnState.showLoader = true;

    if (obj) {
      returnState.eprescribedProcedureID = obj.id;
    }
    this.setState(returnState);

    this.props.checkScriptSurePatient(this.state.clientID);
  };

  hideEprescribeIframe = () => {
    document.body.style.overflow = "";
    if (this.state.showEprescribeIframe) {
      let returnState = {};
      returnState.showLoader = true;

      this.setState(returnState);
      this.props.onClosePrescriptionIframe({
        params: {
          patient_id: this.state.clientID,
          procedure_id: this.state.eprescribedProcedureID,
        },
      });
    }
    this.setState({ showEprescribeIframe: false, showFieldsPopup: false });
  };

  saveMissingInfo = () => {
    let error = false;

    if (this.state.scriptSureData && this.state.scriptSureData.fields) {
      this.state.scriptSureData.fields.map((obj) => {
        let fieldClass = "newInputField";

        if (
          this.state[obj] == "" ||
          this.state[obj] == null ||
          this.state[obj] == undefined
        ) {
          error = true;
          fieldClass = obj + "_class";
          this.setState({ [fieldClass]: "newInputField field_error" });
        } else if (this.state[obj] != "") {
          if (
            obj === "phoneNumber" &&
            !phoneUtil.isPossible(phoneUtil.compose(this.state[obj]))
          ) {
            error = true;
            this.setState({
              phoneNumberError: true,
            });
            uiNotification.error(tCommon("formError.phoneInvalid"));
          } else {
            this.setState({
              phoneNumberError: false,
            });
          }

          if (
            obj === "firstname" ||
            obj === "middlename" ||
            obj === "lastname"
          ) {
            if (this.state[obj].length > 35) {
              error = true;
              fieldClass = obj + "_class";
              this.setState({ [fieldClass]: "newInputField field_error" });
            } else {
              fieldClass = obj + "_class";
              this.setState({ [fieldClass]: "newInputField" });
            }
          }

          if (obj === "state") {
            if (this.state[obj].length > 2) {
              error = true;
              fieldClass = obj + "_class";
              this.setState({ [fieldClass]: "newInputField field_error" });
            } else {
              fieldClass = obj + "_class";
              this.setState({ [fieldClass]: "newInputField" });
            }
          }
        }
      });

      if (!error) {
        let formData = {};

        if (this.state.scriptSureData && this.state.scriptSureData.fields) {
          this.state.scriptSureData.fields.map((obj) => {
            if (obj === "date_of_birth") {
              formData[obj] = moment(this.state.date_of_birth).format(
                "YYYY-MM-DD",
              );
            } else if (obj === "phoneNumber") {
              formData[obj] = phoneUtil.compose(this.state.phoneNumber);
            } else {
              formData[obj] = this.state[obj];
            }
          });
        }

        this.setState({ showLoader: true });
        this.props.createScriptSurePatient(formData, this.state.clientID);
      }
    }
  };

  requiresCcDetails() {
    const tier = this.state.membership_tier_types.find(
      (mt) => mt.id === this.state.membership_tier_id,
    );
    if (
      tier &&
      (tier.one_time_setup_fee > 0 ||
        tier.price_per_month > 0 ||
        tier.price_per_year > 0)
    ) {
      return true;
    }

    return (
      this.state.selected_payment_option &&
      this.state.selected_payment_option?.toLowerCase() !== "both" &&
      ((!this.state.waiveOffOTFee &&
        this.state.total_membership_without_waive > 0) ||
        (this.state.waiveOffOTFee &&
          this.state.total_membership_with_waive > 0))
    );
  }

  getPaymentDetail = () => {
    const payment = {
      today: {
        amount: 0,
        discount: 0,
        one_time_setup_fee: 0,
        total: 0,
      },
    };

    if (
      this.props.paymentDetailData &&
      this.props.paymentDetailData.status === "success"
    ) {
      Object.assign(payment, this.props.paymentDetailData.data.data);
    }

    return payment;
  };

  getChangeDrawDayPaymentDetail = () => {
    if (
      this.props.paymentDetailData &&
      this.props.paymentDetailData.status !== "success"
    ) {
      return null;
    }

    let data = this.props.paymentDetailData.data.data;

    if (data) {
      return data;
    }
  };

  getFeeScheduleMembershipFee = () => {
    if (!this.getPaymentDetail().discount_duration) {
      return this.getPaymentDetail().membership_amount;
    }
    if (this.getPaymentDetail().pro_rated_days) {
      return this.getPaymentDetail().membership_amount;
    }
    if (this.getPaymentDetail().future) {
      return this.getPaymentDetail().membership_amount;
    }
    return this.getPaymentDetail().membership_fee;
  };

  getMonthlyInstallmentsMembershipFee = () => {
    if (!this.getPaymentDetail().discount_duration) {
      return numberFormat(
        this.getPaymentDetail().membership_fee,
        "currency",
        2,
      );
    }
    if (this.getPaymentDetail().discount_duration === "forever") {
      return numberFormat(
        this.getPaymentDetail().membership_fee_with_discount,
        "currency",
        2,
      );
    }
    if (this.getPaymentDetail().discount_duration === "limited") {
      return `${numberFormat(
        this.getPaymentDetail().membership_fee_with_discount,
        "currency",
        2,
      )}|${numberFormat(
        this.getPaymentDetail().membership_fee,
        "currency",
        2,
      )} `;
    }
    if (this.getPaymentDetail().discount_duration === "single") {
      if (this.getPaymentDetail().pro_rated_days) {
        return `${numberFormat(
          this.getPaymentDetail().membership_fee_with_discount,
          "currency",
          2,
        )}|${numberFormat(
          this.getPaymentDetail().membership_fee,
          "currency",
          2,
        )} `;
      }
      return numberFormat(
        this.getPaymentDetail().membership_fee,
        "currency",
        2,
      );
    }
  };

  getMembershipFeeAcordion = (data) => {
    let fee = null;
    if (data.payment_frequency?.toLowerCase() === "monthly") {
      fee = data.mothly_membership_fees;
    } else if (data.payment_frequency?.toLowerCase() === "yearly") {
      fee = data.yearly_membership_fees;
    }
    const feeWithDiscount = parseFloat(fee) - parseFloat(data.total_discount);
    if (!data.applied_coupon) {
      return numberFormat(fee, "currency");
    }
    if (data.discount_duration === "forever") {
      return numberFormat(feeWithDiscount, "currency");
    }
    if (data.discount_duration === "limited") {
      return `${numberFormat(feeWithDiscount, "currency")}|${numberFormat(
        fee,
        "currency",
      )}`;
    }
    if (data.discount_duration === "single") {
      return numberFormat(fee, "currency");
    }
  };

  closeCalendar = () => {
    this.startDateRef.setOpen(false);
  };

  closeStickyNotesModal = () => this.setState({ stickyNotes: false });

  renderStripePaymentForm = () => {
    if (
      this.requiresCcDetails() &&
      this.state.userData.account.pos_gateway === "stripe"
    ) {
      if (
        this.props.cardList &&
        this.props.cardList.length &&
        this.state.cardType === "saved"
      ) {
        return (
          <div className="m-t-80 col-xs-12 m-b-30">
            <div className="new-field-label">Credit Card(s)</div>
            <select
              className="setting-select-box"
              value={this.state.cardSelected}
              onChange={this.changeCardSelected}
            >
              {!this.state.cardSelected && (
                <option value="">Select card</option>
              )}
              {this.props.cardList.map((obj) => {
                const last4 = obj.card.last4
                  ? ` ending with ${obj.card.last4}`
                  : "";
                return (
                  <option value={obj.id} key={obj.id}>
                    {obj.card.brand}
                    {last4}
                  </option>
                );
              })}
            </select>
          </div>
        );
      } else if (this.state.cardType === "new") {
        return (
          <StripePaymentForm
            onSubmit={this.handleMembershipSubmit}
            validation={this.handleMembershipSubmitValidations}
            type={"setupIntent"}
            onSubmitText={"Save"}
          />
        );
      }
    }
    return null;
  };

  setCardSelected = (value) => {
    this.setState({ cardSelected: value });
  };

  changeCardSelected = async (e) => {
    e.preventDefault();
    this.setState({ cardSelected: e.target.value });
  };

  toggleNotification = (event) => {
    const { name } = event.target;
    this.setState({
      modalMessage: "",
      modalAction: "",
      modalActType: "",
      showModal: false,
      showLoader: true,
    });
    this.props.doNotDisturb({
      clientID: this.state.clientID,
      settingName: name,
    });
  };

  get efaxIntegrationEnabled() {
    return this.props.globalSettings?.efax_integration
  }

  render() {
    let src = "/images/user.png";
    let clientName = "";
    let iconSrc = "";
    let iconClass = "";
    let fireMsg = "";
    let fireAction = "";
    let deleteMsg = this.state.languageData.clientprofile_del_client_message;
    let address = [];
    let isRegistered = this.state.languageData.clientprofile_not_registered;
    let portalAction = "";
    let hasPastAcc = false;
    let ccNos = [];
    let totalSavings = "";
    let isEmailVerfied = "1";
    const isHaveAllergy = this.state.clientData.is_have_allergy;
    let paymentDetail = {};

    if (this.props.paymentDetailData && this.props.paymentDetailData.status) {
      paymentDetail = this.getPaymentDetail();
    }

    const changeDrawDayPaymentDetail =
      this.props.paymentDetailData && this.getChangeDrawDayPaymentDetail();
    let agreementText =
      this.state.agreementData && this.state.agreementData.data
        ? this.state.agreementData.data.agreement_text
        : "";

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.user_image !== "" &&
      this.state.clientData.user_image_url !== ""
    ) {
      src = this.state.clientData.user_image_url;
    } else {
      if (this.state.clientData && this.state.clientData) {
        const { gender } = this.state.clientData;
        const genderImageMap = {
          '3': '/images/Face_NonBinary_new.png',
          '0': '/images/appmale.png'
        };
        src =
          genderImageMap[gender] || '/images/appfemale.png';
      }
    }

    clientName = displayName(this.state.clientData);

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.is_fired === 1
    ) {
      fireMsg = this.state.languageData.clientprofile_activate_message;
      fireAction = "disable";
    } else {
      fireMsg = this.state.languageData.clientprofile_fire_message;
      fireAction = "enable";
    }

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.address_line_1
    ) {
      address.push(this.state.clientData.address_line_1);
    }

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.address_line_2
    ) {
      address.push(this.state.clientData.address_line_2);
    }

    if (this.state.clientData !== undefined && this.state.clientData.city) {
      address.push(this.state.clientData.city);
    }

    if (this.state.clientData !== undefined && this.state.clientData.state) {
      address.push(this.state.clientData.state);
    }

    if (this.state.clientData !== undefined && this.state.clientData.pincode) {
      address.push(this.state.clientData.pincode);
    }

    if (this.state.clientData !== undefined && this.state.clientData.country) {
      address.push(this.state.clientData.country);
    }

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.is_monthly_membership
    ) {
      if (this.state.clientData.is_monthly_membership === 1) {
        isRegistered = this.state.languageData.clientprofile_registered;
        totalSavings =
          this.state.languageData.clientprofile_mem_saving +
          " " +
          numberFormat(
            this.state.clientData.membership_benefits_this_year,
            "currency",
          );
      }
    }

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.access_portal === 1
    ) {
      if (
        this.state.clientData.patient_account !== undefined &&
        this.state.clientData.patient_account !== null
      ) {
        hasPastAcc = true;
      }

      if (
        this.state.clientData.patient_account !== undefined &&
        this.state.clientData.patient_account !== null &&
        this.state.clientData.patient_account.patient_user
      ) {
        isEmailVerfied =
          this.state.clientData.patient_account.patient_user.status;
      }
      portalAction = "disable";
    } else {
      portalAction = "enable";
    }

    if (
      this.state.clientData !== undefined &&
      this.state.clientData.card_on_files
    ) {
      this.state.clientData.card_on_files.map((obj) => {
        ccNos.push(obj.card_number);
      });
    }

    let referer = 0;
    let showMissing = false;

    if (
      this.state.clientData !== undefined &&
      (this.state.clientData.referral_source ||
        this.state.clientData.referral_person)
    ) {
      referer = 1;
    }

    if (
      (this.state.clientData !== undefined &&
        (!this.state.clientData.address_line_1 ||
          !this.state.clientData.city ||
          !this.state.clientData.state ||
          !this.state.clientData.country ||
          !this.state.clientData.pincode)) ||
      referer === 0
    ) {
      showMissing = true;
    }

    let monthlyMembershipType = this.state.monthly_membership_type;

    let daysOptData = "";
    let daysArray = Array(31)
      .fill()
      .map((_, idx) => idx + 1);
    let monthsArray = [];

    for (let j = 1; j <= 12; j++) {
      monthsArray.push(j);
    }

    daysOptData = daysArray.map((daysData) => {
      return (
        <option key={daysData} value={daysData}>
          {daysData}
        </option>
      );
    });

    let monthArray = [];
    let treatStartOnMonth =
      this.state.clientData &&
        this.state.clientData.current_treatment_plan &&
        this.state.clientData.current_treatment_plan.start_month
        ? this.state.clientData.current_treatment_plan.start_month
          .toString()
          .padStart(2, "0")
        : "";
    let treatStartOn =
      (this.state.clientData &&
        this.state.clientData.current_treatment_plan &&
        this.state.clientData.current_treatment_plan.start_year) +
      "-" +
      treatStartOnMonth +
      "-" +
      "01";
    let expireAfter =
      this.state.clientData &&
      this.state.clientData.current_treatment_plan &&
      this.state.clientData.current_treatment_plan.duration;
    if (
      this.state.clientData &&
      this.state.clientData.current_treatment_plan &&
      this.state.clientData.current_treatment_plan.plan_type?.toLowerCase() === "monthly" &&
      treatStartOnMonth != "" &&
      expireAfter > 0
    ) {
      for (let i = 1; i <= expireAfter; i++) {
        let tempArray = [];
        tempArray.push(moment(treatStartOn).format("MMMM, YYYY"));
        treatStartOn = moment(treatStartOn)
          .add(1, "month")
          .format("YYYY-MM-DD");
        monthArray.push(tempArray);
      }
    }

    if (this.state.isShowCosmeticPhotoAfterFlag === true) {
      return (
        <CosmeticTimelinePhotoAfter
          procedureID={this.state.procedureIdForPopup}
          type="profile"
          handleChildState={this.handleChildState}
        />
      );
    }
    if (this.state.isShowCosmeticPhotoMarkingFlag === true) {
      return (
        <CosmeticTimelinePhotoMarking
          procedureID={this.state.procedureIdForPopup}
          type="profile"
          handleChildState={this.handleChildState}
        />
      );
    }

    const saveWithNewStripe =
      this.state.userData.account.pos_gateway === "stripe" &&
      this.state.cardType === "new" &&
      this.requiresCcDetails() &&
      this.props.stripeIntent;

    const isGlobalPosEnabled = Boolean(
      this.props.currentAccountQuery?.data?.globalSettings?.isPosEnabled,
    );

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper client-profile-container">
          {this.state.lightboxIsOpen == true &&
            Array.isArray(this.state.lightboxImage) == false && (
              <Lightbox
                images={[{ src: this.state.lightboxImage }]}
                isOpen={this.state.lightboxIsOpen}
                onClose={this.closeLightbox}
                preventScroll={false}
                showImageCount={false}
                backdropClosesModal={true}
              />
            )}
          {this.state.lightboxIsOpen == true &&
            Array.isArray(this.state.lightboxImage) == true && (
              <Lightbox
                images={this.state.lightboxImage}
                isOpen={this.state.lightboxIsOpen}
                onClose={this.closeLightbox}
                currentImage={this.state.currentImageIndex}
                showImageCount={true}
                showThumbnails={true}
                backdropClosesModal={true}
                onClickNext={this.currentImageLightbox.bind(
                  this,
                  this.state.currentImageIndex + 1,
                )}
                onClickPrev={this.currentImageLightbox.bind(
                  this,
                  this.state.currentImageIndex - 1,
                )}
                onClickThumbnail={this.currentImageLightbox}
              />
            )}
          <ScriptSureFrame
            handleChildChange={this.handleInputChange}
            parentState={this.state}
            hideEprescribeIframe={() => this.hideEprescribeIframe()}
          />
          <div
            className={
              this.state.showFieldsPopup === true && this.state.scriptSureData
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle vert-middle-report-option">
              <div className="white-popup xs-popup">
                <div className="white-popup-wrapper">
                  <div className="membershipTypeTitle">
                    Provide Missing Information
                    <a className="popupClose">
                      <img onClick={this.hideEprescribeIframe} src={picClose} />
                    </a>
                  </div>
                  <div className="row">
                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("firstname") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Firstname
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.firstname_class}
                              placeholder="Enter Firstname"
                              name="firstname"
                              onChange={this.handleInputChange}
                              value={this.state.firstname}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("middlename") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Middle Name
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.middlename_class}
                              placeholder="Enter Middle Name"
                              name="middlename"
                              onChange={this.handleInputChange}
                              value={this.state.middlename}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("lastname") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Lastname<span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.lastname_class}
                              placeholder="Enter Lastname"
                              name="lastname"
                              onChange={this.handleInputChange}
                              value={this.state.lastname}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf(
                        "date_of_birth",
                      ) > -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Date of Birth
                              <span className="setting-require">*</span>
                            </div>
                            <CustomDatePicker
                              name="date_of_birth"
                              onChangeDatePicker={this.onChangeDatePicker}
                              minDate={
                                new Date(
                                  moment().subtract(100, "years").toDate(),
                                )
                              }
                              maxDate={new Date()}
                              value={this.state.date_of_birth}
                              class={this.state.date_of_birth_class}
                              format={dateFormatPicker()}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("gender") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Gender<span className="setting-require">*</span>
                            </div>

                            <select
                              className={this.state.gender_class}
                              name="gender"
                              value={this.state.gender}
                              onChange={this.handleInputChange}
                            >
                              <option value="2">Not Disclosed</option>
                              <option value="0">Male</option>
                              <option value="1">Female</option>
                            </select>
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("phoneNumber") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel absolute top--10">
                              Phone<span className="setting-require">*</span>
                            </div>
                            <InputPhone
                              fullWidth
                              variant="legacy-outlined"
                              value={this.state.phoneNumber || ""}
                              onChange={this.phoneNumberChanged.bind(
                                this,
                                "phoneNumber",
                              )}
                              isError={this.state.phoneNumberError}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf(
                        "address_line_1",
                      ) > -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Address Line 1
                              <span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.address_line_1_class}
                              placeholder="Enter Address"
                              name="address_line_1"
                              onChange={this.handleInputChange}
                              value={this.state.address_line_1}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("city") > -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              City<span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.city_class}
                              placeholder="Enter City"
                              name="city"
                              onChange={this.handleInputChange}
                              value={this.state.city}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("state") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              State<span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.state_class}
                              placeholder="Enter State"
                              name="state"
                              onChange={this.handleInputChange}
                              value={this.state.state}
                            />
                          </div>
                        </div>
                      )}

                    {this.state.scriptSureData &&
                      this.state.scriptSureData.fields &&
                      this.state.scriptSureData.fields.indexOf("pincode") >
                      -1 && (
                        <div className="col-sm-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Zipcode<span className="setting-require">*</span>
                            </div>

                            <input
                              className={this.state.pincode_class}
                              placeholder="Enter Zipcode"
                              name="pincode"
                              onChange={this.handleInputChange}
                              value={this.state.pincode}
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="footer-static">
                  <div>
                    <a
                      className="new-blue-btn pull-right"
                      onClick={this.saveMissingInfo}
                    >
                      Save
                    </a>
                    <a
                      onClick={this.hideEprescribeIframe}
                      className="new-white-btn pull-right"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.showPresOnlyPopup === true &&
                this.state.presOnlyDetails
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership treatmentPlan">
                <div className="newPopupTitle">
                  {this.state?.presOnlyDetails?.plan_name}
                  <a className="popupClose">
                    <img
                      onClick={() => this.hidePresOnlyPoup()}
                      src={picClose}
                    />
                  </a>
                </div>
                <div className="newPopupContent">
                  <div className="treatment-timeline">
                    <div className="row">
                      <div className="col-sm-7 col-xs-12">
                        {this.state.presOnlyDetails &&
                          this.state.presOnlyDetails.plan_type === "payg" &&
                          this.state.presOnlyDetails.pay_as_you_go &&
                          this.state.presOnlyDetails.pay_as_you_go.length > 0 &&
                          this.state.presOnlyDetails.pay_as_you_go.map(
                            (obj, idx) => {
                              return (
                                <div key={idx} className="treatmentSection">
                                  {
                                    <div className="procedureDate payg-schedule">
                                      {obj.product_name}:{" "}
                                      <label className="smallDetails">
                                        {obj.units} Units (Every{" "}
                                        {obj.repeat_val} {obj.repeat_by})
                                      </label>{" "}
                                    </div>
                                  }
                                </div>
                              );
                            },
                          )}

                        {this.state.pMonthArray &&
                          this.state.pMonthArray.length > 0 &&
                          this.state.pMonthArray.map((mobj, idx) => {
                            const mobjDate = moment("01 " + mobj[0]).format(
                              "M-YYYY",
                            );
                            let show = false;
                            let returnedArray =
                              this.state.presOnlyDetails &&
                              this.state.presOnlyDetails
                                .patient_treatment_plan_schedule &&
                              this.state.presOnlyDetails
                                .patient_treatment_plan_schedule.length > 0 &&
                              this.state.presOnlyDetails.patient_treatment_plan_schedule.map(
                                (mmobj, midx) => {
                                  let monthToCompare =
                                    parseInt(mmobj.month) +
                                    "-" +
                                    parseInt(mmobj.year);
                                  let productName = mmobj.product
                                    ? capitalizeFirstLetter(
                                      mmobj.product.product_name,
                                    )
                                    : "N/A";
                                  let units = mmobj.units
                                    ? ", " + mmobj.units + " units"
                                    : "";
                                  if (mobjDate === monthToCompare) {
                                    show = true;
                                    return (
                                      <div key={midx}>
                                        {productName}
                                        {units}
                                      </div>
                                    );
                                  }
                                },
                              );
                            return (
                              <div
                                key={idx}
                                className={
                                  show
                                    ? "treatmentSection"
                                    : "treatmentSection no-display"
                                }
                              >
                                <div className="procedureDate">{mobj[0]}</div>
                                <div className="treatmentContent">
                                  <div className="treatmentDetails">
                                    {returnedArray}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>

                      <div className="col-sm-5 col-xs-12 treatment-timeline">
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="treat-text">Prescribed by</div>
                            <div className="TP-total">
                              {this.state.presOnlyDetails &&
                                this.state.presOnlyDetails.user &&
                                displayName(this.state.presOnlyDetails.user)}
                            </div>
                          </div>
                          <div className="col-xs-6">
                            <div className="treat-text">
                              Total{" "}
                              <a
                                onClick={() => this.viewBreakDown(true)}
                                title="View detailed breakdown of this amount"
                                className="help-icon sm-help"
                              >
                                ?
                              </a>
                            </div>
                            <div className="TP-total">
                              {numberFormat(
                                this.state.presOnlyDetails &&
                                this.state.presOnlyDetails.total_amount,
                                "currency",
                              )}
                            </div>
                          </div>
                          <div className="col-xs-12">
                            <div className="treat-text">Plan type</div>
                            <div className="TP-total">
                              {this.state.presOnlyDetails &&
                                this.state.presOnlyDetails.plan_type === "payg"
                                ? "Pay As You Go"
                                : "Monthly"}
                            </div>
                          </div>
                          {this.state.presOnlyDetails &&
                            this.state.presOnlyDetails.clinic && (
                              <div className="col-xs-12">
                                <div className="treat-text">Clinic</div>
                                <div className="TP-total">
                                  {this.state.presOnlyDetails &&
                                    this.state.presOnlyDetails.clinic &&
                                    this.state.presOnlyDetails.clinic
                                      .clinic_name}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.presOnlyDetails && (
                  <div className="footer-static p-b-0 d-flex gap-8 justify-end">
                    <DeletePrescribedPlan
                      planId={this.state.presOnlyDetails.id}
                      onSuccess={() => {
                        this.props.getClientProfileDetail(
                          this.state.clientID,
                          this.state.clientScopes,
                        );
                        this.hidePresOnlyPoup();
                      }}
                    />
                    <a
                      onClick={() =>
                        this.performThisAction(
                          this.state.presOnlyDetails.id,
                          "send_to_patient",
                        )
                      }
                      className="line-btn pull-right d-flex"
                    >
                      Send to Patient
                    </a>
                    <a
                      onClick={() =>
                        this.performThisAction(
                          this.state.presOnlyDetails.id,
                          "print",
                        )
                      }
                      className="line-btn pull-right no-width d-flex"
                    >
                      Print/Download
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <TreatmentPlan
            closeTratmentModal={() => this.hideTreatmentModal()}
            createNewTreatmentPlan={() => this.showCreateTreatModal()}
            cancelCreateTreatmenPlan={() => this.closeCreateTratmentModal()}
            parentState={this.state}
            changeTreatType={this.changeTreatType}
            handleChildChange={this.handleInputChange}
            handleChildFormSubmit={this.handleChildCreateSubmit}
            onChangeChildDatePicker={this.onChangeDatePicker}
            removePaygRows={this.removePaygRows}
            addMorePaygRows={this.addMorePaygRows}
            handleChildProductChange={this.handleProductChange}
            selectChildProduct={this.selectProduct}
            selectThisTemplate={this.selectThisTemplate}
            showUseConfirmation={this.showUseConfirmation}
            dismissUseModal={this.dismissUseModal}
            generateChildMonthlyHTML={this.generateMonthlyHTML}
            addMoreMonthlyRows={this.addMoreMonthlyRows}
            removeMonthlyRows={this.removeMonthlyRows}
            saveThePlan={this.saveThePlan}
            hideChildPreviewDetails={this.hidePreviewDetails}
            hideChildStartProgram={this.hideProgramDetails}
            showChildProgramDetails={this.showProgramDetails}
            changeChildDiscType={this.changeDisCountType}
            applyChildProgramDiscount={this.applyProgramDiscount}
            viewChildBreakDown={this.viewBreakDown}
            hideChildBreakDown={this.hideBreakDown}
            handleChildProgramSubmit={this.handleProgramSubmit}
            hideChildChangePlanCCPopup={this.hideChangePlanCCPopup}
            handleChildChangeCard={this.handleChangeCard}
            showChildTemplateNamePopup={this.showTemplateNamePopup}
            hideChildTemplateNamePopup={this.hideTemplateNamePopup}
            handleChildTempNameSubmit={this.handleTempNameSubmit}
            makeChildPrescribeOnly={this.makePrescribeOnly}
            treat_start_card_number={this.state.treat_start_card_number}
            treat_expiry_number={this.state.treat_expiry_number}
            treat_start_card_cvv={this.state.treat_start_card_cvv}
            handleClearentChange={this.handleClearentChange}
            treatStartClearentError={this.state.treatStartClearentError}
            treat_change_card_number={this.state.treat_change_card_number}
            treat_change_expiry_number={this.state.treat_change_expiry_number}
            treat_change_card_cvv={this.state.treat_change_card_cvv}
            treatChangeClearentError={this.state.treatChangeClearentError}
            setCardSelected={this.setCardSelected}
          />
          <div
            className={
              this.state.showSignModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">
                  {this.state.roomTextData.md_dir_consent_text}
                </div>
                <a onClick={this.dismissSignModal} className="small-cross">
                  ×
                </a>
              </div>

              <div className="juvly-container">
                <div className="settings-subtitle signature-subtitle">
                  {this.state.roomTextData.please_sign_below_text}:
                </div>
                <div className={this.state.canvasClass} id="sig-div">
                  {this.state.showSignModal &&
                    this.state.canvasClass === "signature-box sig-div" && (
                      <SketchField
                        width="400px"
                        ref={(c) => (this._sketch = c)}
                        height="200px"
                        tool={Tools.Pencil}
                        lineColor="black"
                        lineWidth={6}
                      />
                    )}
                </div>
                <div className="img-src" id="img-src">
                  <div
                    className={this.state.inputOut}
                    style={{ background: "#fff none repeat scroll 0 0" }}
                  >
                    <img
                      className=""
                      id="signature_image"
                      src={
                        this.state.uploadedSignature_url
                          ? this.state.uploadedSignature_url
                          : this.state.signature_url
                      }
                    />
                  </div>
                </div>

                <div className="right-sign-btn m-t-20">
                  <input
                    className="pull-left sel-all-visible"
                    type="checkbox"
                    name="save_sign"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                  <label className="search-text" htmlFor="save_sign">
                    {" "}
                    {this.state.roomTextData.save_sig_text}
                  </label>
                </div>

                <div className="img-src change-sig">
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change"
                      onClick={this.clearCanvas}
                      className={this.state.changeClass}
                    >
                      {this.state.roomTextData.btn_change_text}
                    </button>
                  </div>
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change1"
                      onClick={this.clear}
                      className={this.state.clearClass}
                    >
                      {this.state.roomTextData.btn_clear_text}
                    </button>
                  </div>
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change2"
                      onClick={this.handleClearReset}
                      className={this.state.resetClass}
                    >
                      {this.state.roomTextData.btn_reset_text}
                    </button>
                  </div>
                  <div className="pull-left"></div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  id="saveConsultation"
                  onClick={this.signThis}
                  className="new-blue-btn pull-right"
                >
                  {this.state.roomTextData.sign_text}
                </a>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.showMembershipModal === true &&
                this.state.showAgreement == false
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership large-popup clientMembershipPopup">
                <form
                  onSubmit={
                    saveWithNewStripe ? "" : this.handleMembershipSubmit
                  }
                >
                  <div className="newPopupTitle">
                    {"Membership"}{" "}
                    <a
                      onClick={() => this.hideMembershipModal()}
                      className="popupClose"
                    >
                      <img src={picClose} />
                    </a>
                  </div>
                  <div className="newPopupSubTitle">
                    {this.state.languageData.clientprofile_add_membership}
                  </div>
                  <div className="newPopupContent">
                    <div className="membershipDetail">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="simpleLabel">{"Select Clinic"}</div>
                          <div className="simpleField">
                            <select
                              className={this.state.membership_clinic_idClass}
                              onChange={this.handleMembershipClinicChange}
                              name="membership_clinic_id"
                              value={this.state.membership_clinic_id}
                            >
                              <option value="">Select</option>
                              {this.state.membership_clinic_list &&
                                this.state.membership_clinic_list.length > 0 &&
                                this.state.membership_clinic_list.map((obj) => {
                                  return (
                                    <option
                                      key={"membership_clinic_list-" + obj.id}
                                      value={obj.id}
                                    >
                                      {obj.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>

                        <div className={"col-sm-6"}>
                          <div className="simpleLabel">
                            {
                              this.state.languageData
                                .clientprofile_membersip_plan
                            }
                          </div>
                          <div className="simpleField">
                            <div className="simpleInput simpleInput-div-span">
                              <span> {this.state.recurly_program_name}</span>
                            </div>
                          </div>
                        </div>
                        {this.state.membershipData &&
                          this.state.membershipData
                            .membership_payment_options?.toLowerCase() == "both" &&
                          this.state.membership_tier === "single" && (
                            <div className="col-sm-6">
                              <div className="simpleLabel">
                                {"Payment Frequency Options"}
                                <span className="required m-l-5">*</span>
                              </div>
                              <div className="simpleField">
                                <select
                                  className={
                                    this.state.member_payment_optionsClass
                                  }
                                  onChange={this.handleInputChange}
                                  name="selected_payment_option"
                                  value={this.state.selected_payment_option}
                                >
                                  <option value="both">Select</option>
                                  <option value="monthly">Monthly</option>
                                  <option value="yearly">Yearly</option>
                                </select>
                              </div>
                            </div>
                          )}
                        {this.state.membership_tier === "multiple" && (
                          <div className="col-sm-12">
                            <div className="col-sm-6 p-l-0 memberPlanType">
                              <div className="simpleLabel">
                                {
                                  this.state.languageData
                                    .clientprofile_membership_type
                                }
                              </div>
                              <div className="simpleField">
                                <select
                                  className={this.state.membership_tier_idClass}
                                  onChange={this.handleInputChange}
                                  name="membership_tier_id"
                                  value={this.state.membership_tier_id}
                                >
                                  {this.state.membership_tier_types &&
                                    this.state.membership_tier_types.length >
                                    0 ? (
                                    this.state.membership_tier_types.map(
                                      (obj) => {
                                        return (
                                          <option
                                            key={
                                              "membership_tier_types-" + obj.id
                                            }
                                            value={obj.id}
                                          >
                                            {obj.tier_name}
                                          </option>
                                        );
                                      },
                                    )
                                  ) : (
                                    <option value="">Select</option>
                                  )}
                                </select>
                              </div>
                            </div>
                            {this.state.membership_payment_frequency?.toLowerCase() ==
                              "both" && (
                                <div className="col-sm-6 p-r-0">
                                  <div className="simpleLabel">
                                    {"Payment Frequency Options"}
                                    <span className="required m-l-5">*</span>
                                  </div>
                                  <div className="simpleField">
                                    <select
                                      className={
                                        this.state.member_payment_optionsClass
                                      }
                                      onChange={this.handleInputChange}
                                      name="selected_payment_option"
                                      value={this.state.selected_payment_option}
                                    >
                                      <option value="both">Select</option>
                                      <option value="monthly">Monthly</option>
                                      <option value="yearly">Yearly</option>
                                    </select>
                                  </div>
                                </div>
                              )}
                          </div>
                        )}
                        {this.state.membership_tier === "multiple" &&
                          this.state.membership_tier_type_products !== "" &&
                          this.state.membership_tier_type_products !== null &&
                          this.state.membership_tier_type_products !==
                          undefined && (
                            <div className="col-sm-12 col-xs-12">
                              <div className="simpleLabel">
                                {
                                  this.state.languageData
                                    .client_free_monthly_products
                                }
                              </div>
                              <div className="simpleField">
                                <div className="simpleTextarea ProductMembershipContent">
                                  <Scrollbars style={{ height: "42px" }}>
                                    {this.state.membership_tier_type_products}
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          )}
                        {this.state.membership_tier === "multiple" &&
                          this.state.membership_on_all_product > 0 && (
                            <div className="col-sm-12 col-xs-12">
                              <div className="simpleField">
                                {
                                  this.state.languageData
                                    .client_discount_on_all_items
                                }{" "}
                                <span className="font-weight-600">
                                  {numberFormat(
                                    this.state.membership_on_all_product,
                                    "decimal",
                                    2,
                                  ) + "%"}
                                </span>
                              </div>
                            </div>
                          )}
                      </div>
                      {this.state.membership_tier === "single" && (
                        <div className={"this.state.membership_tier"}>
                          <div className="row">
                            <div className="col-sm-5 col-xs-12 membershipLabel">
                              {
                                this.state.globalLang
                                  .signup_label_subscription_type
                              }
                            </div>
                            <div className="col-sm-3 col-xs-6">
                              {monthlyMembershipType.toUpperCase()}
                            </div>
                          </div>
                          {this.state.membershipData &&
                            this.state.membershipData
                              .membership_payment_options?.toLowerCase() != "both" &&
                            this.state.membershipData
                              .membership_payment_options && (
                              <div className="row">
                                <div className="col-sm-5 col-xs-12 membershipLabel">
                                  {"Payment Frequency option"}
                                </div>
                                <div className="col-sm-3 col-xs-6">
                                  {this.state.membershipData.membership_payment_options.toUpperCase()}
                                </div>
                              </div>
                            )}
                          {this.state.selected_payment_option == "monthly" && (
                            <div className="row">
                              <div className="col-sm-5 col-xs-12 membershipLabel">
                                {this.state.languageData.label_price_per_month}{" "}
                              </div>
                              <div className="col-sm-3 col-xs-6">
                                {numberFormat(
                                  this.state.monthly_membership_fees,
                                  "currency",
                                  2,
                                )}
                              </div>
                            </div>
                          )}
                          {this.state.selected_payment_option?.toLowerCase() == "yearly" && (
                            <div className="row">
                              <div className="col-sm-5 col-xs-12 membershipLabel">
                                {"Price per year "}
                              </div>
                              <div className="col-sm-3 col-xs-6">
                                {numberFormat(
                                  this.state.membership_yearly_fees,
                                  "currency",
                                  2,
                                )}{" "}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="newPopupSubTitle m-t-10">
                          FEE SCHEDULE
                        </div>
                      </div>
                      <div className="col-sm-9">
                        {this.state.one_time_membership_setup > 0 && (
                          <div className="no-padding-left m-t-10">
                            <div className="waive-setup">
                              <input
                                type="checkbox"
                                className="new-check"
                                checked={
                                  this.state.waiveOffOTFee ? "checked" : false
                                }
                                check
                                name="waiveOffOTFee"
                                onChange={this.handleInputChange}
                                ref={"ref_waive"}
                              />{" "}
                              {
                                this.state.languageData
                                  .clientprofile_waive_setup_fee
                              }
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="row">
                          <div className="col-sm-12 membershipLabel">
                            Due today:
                          </div>
                          <div className="col-sm-7 col-xs-12 membershipLabel">
                            {this.state.languageData.clientprofile_ots_fee}
                          </div>
                          <div className="col-sm-4 col-xs-6">
                            {numberFormat(
                              paymentDetail.one_time_setup,
                              "currency",
                              2,
                            )}
                          </div>
                          <div className="col-sm-7 col-xs-12 membershipLabel">
                            Membership Fee
                          </div>
                          <div className="col-sm-4 col-xs-6">
                            {numberFormat(
                              this.getFeeScheduleMembershipFee(),
                              "currency",
                              2,
                            )}
                          </div>
                          {!!paymentDetail.discount &&
                            !paymentDetail.next_payment && (
                              <>
                                <div className="col-sm-7 col-xs-12 membershipLabel">
                                  Discount
                                </div>
                                <div className="col-sm-4 col-xs-6">
                                  {numberFormat(
                                    paymentDetail.discount,
                                    "currency",
                                    2,
                                  )}
                                </div>
                              </>
                            )}
                          <div className="col-sm-7 col-xs-12 membershipLabel">
                            {this.state.languageData.clientprofile_total}
                          </div>
                          <div className="col-sm-4 col-xs-6">
                            {numberFormat(
                              paymentDetail.total_amount,
                              "currency",
                              2,
                            )}
                          </div>
                          {paymentDetail.next_payment && (
                            <>
                              <div className="col-sm-7 col-xs-12 membershipLabel m-t-20">
                                Next Payment
                              </div>
                              <div className="col-sm-4 col-xs-6 m-t-20">
                                {numberFormat(
                                  paymentDetail.next_payment,
                                  "currency",
                                  2,
                                )}
                              </div>
                            </>
                          )}
                          {Boolean(paymentDetail.pro_rated_days) && (
                            <MembershipProratedFeeInfo
                              id="new"
                              diff_days={paymentDetail.pro_rated_days}
                            />
                          )}
                        </div>
                        {paymentDetail.payment_frequency === "monthly" && (
                          <div className="row m-t-20">
                            <div className="col-sm-12 membershipLabel">
                              Monthly Installments:
                            </div>
                            <div className="col-sm-7 col-xs-12 membershipLabel">
                              Membership Fee
                            </div>
                            <div className="col-sm-4 col-xs-6">
                              {this.getMonthlyInstallmentsMembershipFee()}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <div className="row">
                          {this.state.monthly_membership_type === "paid" && (
                            <div className="newPopupSubTitle m-t-10">
                              {
                                this.state.languageData
                                  .clientprofile_coupon_code
                              }
                            </div>
                          )}
                          {this.state.monthly_membership_type === "paid" && (
                            <div className="row m-b-0">
                              <div className="col-sm-8 col-xs-12 p-r-0 memberPlanCouponCode">
                                <div className="simpleField">
                                  <input
                                    name="couponCode"
                                    className={this.state.couponCodeClass}
                                    onChange={this.handleInputChange}
                                    type="text"
                                    value={this.state.couponCode}
                                    autoComplete="off"
                                    placeholder="Enter Code"
                                    readOnly={
                                      this.state.discountCouponApplied
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                {this.state.discountCouponApplied ? (
                                  <div>
                                    <a className="simple-btn pull-left disable field-btn">
                                      {
                                        this.state.languageData
                                          .clientprofile_applied
                                      }
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <a
                                      onClick={() => this.applyCouponCode()}
                                      className="simple-btn pull-left simple-btn-popup-full-width field-btn"
                                    >
                                      {
                                        this.state.languageData
                                          .clientprofile_apply
                                      }
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        {!!this.state.discountCouponApplied && (
                          <div className="row">
                            {`"${this.state.couponCode}" coupon code applied
                            successfully to next full installment(s).`}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="newPopupSubTitle m-t-15">
                      {this.state.languageData.clientprofile_details}
                    </div>
                    <div className="row m-b-15">
                      <div className="col-sm-6 col-xs-12">
                        <div className="simpleLabel">
                          {this.state.languageData.clientprofile_email}
                          <span className="required m-l-5">*</span>
                        </div>
                        <div className="simpleField">
                          <input
                            name="patientEmail"
                            className={this.state.patientEmailClass}
                            onChange={this.handleInputChange}
                            type="text"
                            value={this.state.patientEmail}
                            autoComplete="off"
                            placeholder={
                              this.state.languageData.clientprofile_enter_email
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={
                          this.state.selected_payment_option?.toLowerCase() == "monthly"
                            ? "col-sm-6 col-xs-12 calender d-flex align-center"
                            : "col-sm-6 col-xs-12 calender no-display"
                        }
                      >
                        <Disabled>
                          <div className="simpleLabel">
                            {this.state.languageData.clientprofile_start_date}
                            <span className="required m-l-5">*</span>
                          </div>
                          <div className="simpleField field-icon start-date-picker">
                            <CustomDatePicker
                              disabled
                              name="subscription_started_at"
                              onChangeDatePicker={this.onChangeDatePicker}
                              minDate={new Date()}
                              maxDate={
                                new Date(moment().add(10, "years").toDate())
                              }
                              value={this.state.subscription_started_at}
                              class={this.state.subscription_started_atClass}
                              format={dateFormatPicker()}
                              reference={(startDateRef) =>
                                (this.startDateRef = startDateRef)
                              }
                              onClickOutside={() => this.closeCalendar()}
                            />
                          </div>
                        </Disabled>
                        <Tooltip
                          id="dateDisabled"
                          text={tCommon(
                            "clientProfile.disabledMembershipDateInfo",
                          )}
                        >
                          <img
                            src={svg.infoBlue}
                            alt="info"
                            width="19px"
                            className="m-t-5 m-l-5"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    {this.requiresCcDetails() && (
                      <div>
                        <div id="membership-payment-form"></div>
                        {this.state.userData &&
                          this.state.userData.account &&
                          this.state.userData.account.pos_gateway ==
                          "clearent" && (
                            <div className="row m-t-15 m-b-15">
                              <div className="col-sm-6 col-xs-12">
                                <div className="simpleLabel">
                                  Zip<span className="required m-l-5">*</span>
                                </div>
                                <div className="simpleField">
                                  <input
                                    className={
                                      this.state["clearent_zip_error"]
                                        ? "simpleInput field-error"
                                        : "simpleInput"
                                    }
                                    type="text"
                                    placeholder="Enter Zip Code"
                                    name={"clearent_zip"}
                                    value={this.state["clearent_zip"]}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 col-xs-12">
                                <div className="simpleLabel">
                                  {"Email"}
                                  <span className="required m-l-5">*</span>
                                </div>
                                <div className="simpleField">
                                  <input
                                    className={
                                      this.state["clearent_email_error"]
                                        ? "simpleInput field-error"
                                        : "simpleInput"
                                    }
                                    type="text"
                                    placeholder="Enter Email"
                                    name={"clearent_email_id"}
                                    value={this.state["clearent_email_id"]}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                    <div className="membershipSign">
                      <div className="juvly-container">
                        <div className="newPopupSubTitle">
                          {"Client Signature"}
                        </div>
                        <div className={this.state.membershipCanvasClass}>
                          {this.state.showSignatureCanvas && (
                            <SketchField
                              width="400px"
                              ref={(c) => (this._refMembershipSketch = c)}
                              height="200px"
                              tool={Tools.Pencil}
                              lineColor="black"
                              lineWidth={6}
                            />
                          )}
                        </div>
                        <div className="img-src" id="img-src">
                          <div
                            className={this.state.membershipCanvasInputOut}
                            style={{
                              background: "#fff none repeat scroll 0 0",
                            }}
                          >
                            <img
                              className=""
                              src={
                                this.state.membership_canvas_signature_url
                                  ? this.state.membership_canvas_signature_url
                                  : this.state.signature_url
                              }
                            />
                          </div>
                        </div>
                        {this.state.isSaveSignatueLabel === true && (
                          <div className="right-sign-btn m-t-20">
                            <input
                              className="pull-left sel-all-visible"
                              type="checkbox"
                              name="save_sign"
                              autoComplete="off"
                              onChange={this.handleInputChange}
                            />
                            <label className="search-text" htmlFor="save_sign">
                              {" "}
                              {"Save Signature"}
                            </label>
                          </div>
                        )}
                        {(this.state.isResetMembershipCanvasButton === true ||
                          this.state.isClearMembershipCanvasButton ===
                          true) && (
                            <div className="img-src change-sig">
                              {this.state.isClearMembershipCanvasButton ===
                                true && (
                                  <div className="pull-left">
                                    <button
                                      type="button"
                                      onClick={this.handleClearMembershipCanvas}
                                      className={"new-white-btn no-margin"}
                                    >
                                      {"Clear"}
                                    </button>
                                  </div>
                                )}
                              {this.state.isResetMembershipCanvasButton ===
                                true && (
                                  <div className="pull-left">
                                    <button
                                      type="button"
                                      onClick={this.handleResetMembershipCanvas}
                                      className={"new-white-btn no-margin"}
                                    >
                                      {"Reset"}
                                    </button>
                                  </div>
                                )}
                            </div>
                          )}
                      </div>
                    </div>
                    {this.state.userData.account.pos_gateway === "stripe" &&
                      this.requiresCcDetails() && (
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="radio-outer">
                              {this.props.cardList &&
                                this.props.cardList.length ? (
                                <span>
                                  <input
                                    type="radio"
                                    name="cardType"
                                    id="saved"
                                    value="saved"
                                    checked={this.state.cardType === "saved"}
                                    onChange={this.handleInputChange}
                                  />
                                  <label htmlFor="saved">Saved Cards</label>
                                </span>
                              ) : (
                                ""
                              )}
                              <input
                                type="radio"
                                name="cardType"
                                id="new"
                                value="new"
                                checked={this.state.cardType === "new"}
                                onChange={this.handleInputChange}
                              />
                              <label htmlFor="new">New Card</label>
                            </div>
                          </div>
                        </div>
                      )}
                    {this.renderStripePaymentForm()}
                  </div>
                  {saveWithNewStripe ? (
                    ""
                  ) : (
                    <div className="newPopupfooter">
                      <div className="row">
                        <div className="col-sm-8"></div>
                        <div className="col-sm-4 col-xs-12">
                          <input
                            type="submit"
                            className="new-blue-btn pull-right simple-btn-popup-full-width"
                            value={this.state.languageData.clientprofile_save}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          <div
            className={
              this.state.showFutureMembershipModal === true
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership">
                <div className="newPopupTitle">
                  {this.state.languageData.clientprofile_monthly_membership}{" "}
                  <a
                    onClick={() => this.hideFutureMembershipModal()}
                    className="popupClose"
                  >
                    <img src={picClose} />
                  </a>
                </div>
                {this.state.membershipData && (
                  <div className="newPopupContent">
                    <div className="membershipDetail">
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 membershipLabel">
                          {this.state.languageData.clientprofile_membership_fee}
                        </div>
                        {
                          <div className="col-sm-8 col-xs-12">
                            {numberFormat(
                              this.state.membershipData.mothly_membership_fees,
                              "currency",
                            )}{" "}
                            {this.state.membershipData &&
                              this.state.membershipData
                                .one_time_membership_setup > 0
                              ? "(Setup fee included)"
                              : "(Setup fee not included)"}
                          </div>
                        }
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 membershipLabel">
                          {this.state.languageData.clientprofile_ots_fee}
                        </div>
                        <div className="col-sm-8 col-xs-12">
                          {numberFormat(
                            this.state.membershipData.one_time_membership_setup,
                            "currency",
                          )}
                        </div>
                      </div>
                      {this.state.appliedCoupon && (
                        <div className="row">
                          <div className="col-sm-4 col-xs-12 membershipLabel">
                            {this.state.languageData.label_coupon_applied}
                          </div>
                          <div className="col-sm-8 col-xs-12">
                            {this.state.appliedCoupon} (
                            {numberFormat(
                              this.state.total_discount,
                              "currency",
                            )}
                            )
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 membershipLabel">
                          {this.state.languageData.clientprofile_will_start_on}
                        </div>
                        <div className="col-sm-8 col-xs-12">
                          {showFormattedDate(
                            this.state.membershipData.subscription_started_at,
                          )}
                        </div>
                      </div>
                      {this.state.membershipData &&
                        this.state.membershipData.draw_day > 0 && (
                          <div className="row">
                            <div className="col-sm-4 col-xs-12 membershipLabel">
                              {this.state.languageData.clientprofile_draw_day}
                            </div>
                            <div className="col-sm-8 col-xs-12">
                              {getNumberWithOrdinal(
                                this.state.membershipData.draw_day,
                              )}{" "}
                              {
                                this.state.languageData
                                  .clientprofile_of_every_month
                              }
                            </div>
                          </div>
                        )}
                      {this.state.membership_clinic_name && (
                        <div className="row">
                          <div className="col-sm-4 col-xs-12 membershipLabel">
                            {"Clinic"}
                          </div>
                          {this.state.showMembershipClinicSection === false ? (
                            <div className="col-sm-8 col-xs-12">
                              {this.state.membership_clinic_name}
                              {
                                <a
                                  className="easy-link changeClinic"
                                  onClick={this.changeMembershipClinic}
                                >
                                  {" "}
                                  Change{" "}
                                </a>
                              }
                            </div>
                          ) : (
                            <div className="col-sm-7 col-xs-12">
                              <select
                                className={this.state.membership_clinic_idClass}
                                onChange={this.handleInputChange}
                                name="membership_change_clinic_id"
                                value={this.state.membership_change_clinic_id}
                              >
                                <option value="">Select</option>
                                {this.state.membership_clinic_list &&
                                  this.state.membership_clinic_list.length >
                                  0 &&
                                  this.state.membership_clinic_list.map(
                                    (obj) => {
                                      return (
                                        <option
                                          key={
                                            "membership_clinic_list-" + obj.id
                                          }
                                          value={obj.id}
                                        >
                                          {obj.name}
                                        </option>
                                      );
                                    },
                                  )}
                              </select>
                              <div className="pull-right m-t-20">
                                <input
                                  type="button"
                                  className="new-blue-btn pull-right m-l-10"
                                  value={"Save Clinic"}
                                  onClick={this.handleChangeMembershipClinic}
                                />
                                <a
                                  onClick={() =>
                                    this.cancelChangeMembershipClinic()
                                  }
                                  className="new-blue-btn pull-right"
                                >
                                  {this.state.languageData.clientprofile_cancel}
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      <a
                        data-message={
                          this.state.languageData
                            .clientprofile_cancel_monthly_membership_confirm_message
                        }
                        data-mtype="cancel"
                        data-action="cancelMembership"
                        onClick={this.openModal}
                        className="simple-btn m-t-20"
                      >
                        {
                          this.state.languageData
                            .clientprofile_cancel_membership
                        }
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row duplicate-patient-title">
            <div className="col-sm-3">
              <Link
                to={`/clients`}
                className="pull-left go-back go-back-arrow"
              ></Link>
              <span className="patient-count">
                {this.state.languageData.clientprofile_profile}
              </span>
            </div>
            {this.state.isTouchMD == 1 &&
              this.state.plan_selected == "practice_booking" ? (
              ""
            ) : (
              <div className="right-create-patient col-sm-9 profile-right">
                {(checkIfPermissionAllowed("view-customer-notes") ||
                  checkIfPermissionAllowed("view-appointments") ||
                  checkIfPermissionAllowed("manage-medical-history") ||
                  checkIfPermissionAllowed("view-documents")) && (
                    <div className="dropdown pull-right more-info-profile-dropdown">
                      <button
                        className="line-btn pull-right"
                        type="button"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        {this.state.languageData.clientprofile_more_info}
                        <i className="fas fa-angle-down"></i>
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu1"
                      >
                        {checkIfPermissionAllowed("export-procedures") && (
                          <li>
                            <a
                              className="header-unselect-btn modal-link"
                              onClick={() => this.exportClientProcedures()}
                            >
                              {
                                this.state.languageData
                                  .clientprofile_export_procedure
                              }
                            </a>
                          </li>
                        )}

                        {checkIfPermissionAllowed("view-appointments") && (
                          <li>
                            <a
                              onClick={() => this.getUpcomingAppointments()}
                              className="header-unselect-btn setting"
                            >
                              {
                                this.state.languageData
                                  .clientprofile_upcoming_appointments
                              }{" "}
                              {this.state.clientData.upcoming_appointment_count
                                ? "(" +
                                this.state.clientData
                                  .upcoming_appointment_count +
                                ")"
                                : "(0)"}
                            </a>
                          </li>
                        )}
                        {checkIfPermissionAllowed("view-patients") && (
                          <li>
                            <a
                              onClick={() => this.getClientDocuments()}
                              className="header-unselect-btn setting modal-link"
                            >
                              {this.state.languageData.clientprofile_documents}
                            </a>
                          </li>
                        )}
                        {this.state.clientData !== undefined &&
                          this.state.clientData.account !== null &&
                          this.state.clientData.account !== undefined &&
                          this.state.clientData.account.pos_enabled === 1 && (
                            <li>
                              <a
                                onClick={() => this.getPaymentHistory()}
                                className="header-unselect-btn setting modal-link"
                              >
                                {
                                  this.state.languageData
                                    .clientprofile_payment_history
                                }
                              </a>
                            </li>
                          )}
                      </ul>
                    </div>
                  )}
                {checkIfPermissionAllowed("view-customer-notes") && (
                  <a
                    className="blue-btn pull-right"
                    onClick={() => this.getCustomerNotes()}
                  >
                    {this.state.languageData.clientprofile_customer_notes}
                  </a>
                )}
                {checkIfPermissionAllowed("manage-medical-history") && (
                  <a
                    className="blue-btn pull-right"
                    onClick={() => this.getMedicalHistory()}
                  >
                    {this.state.languageData.clientprofile_medical_history}
                  </a>
                )}
                {this.state.clientData !== undefined &&
                  this.state.clientData.account !== null &&
                  this.state.clientData.account !== undefined &&
                  this.state.clientData.account.pos_enabled === 1 && (
                    <a
                      onClick={() => this.openClientWallet()}
                      className="blue-btn pull-right"
                    >
                      {this.state.languageData.clientprofile_client_wallet}
                    </a>
                  )}
                {this.state.clientData !== undefined &&
                  this.state.clientData.active_treamtment_plan === 0 &&
                  this.state.clientData.account !== null &&
                  this.state.clientData.account !== undefined &&
                  this.state.clientData.account.pos_enabled === 1 && (
                    <a
                      onClick={() => this.showTreatmentModal()}
                      className="blue-btn pull-right"
                    >
                      Treatment Plan
                    </a>
                  )}
                <a
                  onClick={() => this.showMembershipModal()}
                  className={"blue-btn pull-right"}
                >
                  {this.state.languageData.clientprofile_membersip_plan}
                </a>
                {checkIfPermissionAllowed("create-appointment") && (
                  <a
                    onClick={() => this.createAppointment()}
                    className="blue-btn pull-right"
                  >
                    {this.state.languageData.clientprofile_create_appointment}
                  </a>
                )}
                <a
                  className="blue-btn pull-right"
                  onClick={() =>
                    this.props.history.push(
                      CLIENTS_ROUTES.vitals(this.state.clientID),
                    )
                  }
                >
                  Vitals
                </a>
                {this.efaxIntegrationEnabled &&
                  <a
                    onClick={() => this.props.history.push(CLIENTS_ROUTES.eFax(this.state.clientID))}
                    className="blue-btn pull-right"
                  >
                    eFax Orders
                  </a>
                }
              </div>
            )}
            {this.state.isTouchMD == 1 &&
              this.state.plan_selected == "practice_booking" && (
                <div className="right-create-patient col-sm-9 profile-right">
                  {checkIfPermissionAllowed("view-patients") && (
                    <a
                      onClick={() => this.getClientDocuments()}
                      className="blue-btn pull-right"
                    >
                      {this.state.languageData.clientprofile_documents}
                    </a>
                  )}
                  {this.state.clientData !== undefined &&
                    this.state.clientData.account !== null &&
                    this.state.clientData.account !== undefined &&
                    this.state.clientData.account.pos_enabled === 1 && (
                      <a
                        onClick={() => this.getPaymentHistory()}
                        className="blue-btn pull-right"
                      >
                        {this.state.languageData.clientprofile_payment_history}
                      </a>
                    )}
                  {checkIfPermissionAllowed("view-customer-notes") && (
                    <a
                      className="blue-btn pull-right"
                      onClick={() => this.getCustomerNotes()}
                    >
                      {this.state.languageData.clientprofile_customer_notes}
                    </a>
                  )}
                </div>
              )}
          </div>
          <div className="merge-outer row">
            {this.state.clientData !== undefined && (
              <div className="patient-left col-sm-4 merge-info">
                <div className="merge-setion">
                  <div className="section-title header-blue">
                    <span className="section-title-name">
                      {this.state.languageData.client_client_information}
                    </span>
                  </div>
                  {this.state.showLoader === false &&
                    showMissing &&
                    checkIfPermissionAllowed("add-update-patients") && (
                      <div className="profile-header-red">
                        {this.state.languageData.clientprofile_source_missing}{" "}
                        <a onClick={this.editClienProfile}>
                          {this.state.languageData.clientprofile_click_here}
                        </a>{" "}
                        {this.state.languageData.clientprofile_to_add}
                      </div>
                    )}
                  <div className="pro-pic-outer">
                    <img src={src} />
                    <div className="profile-img-info">
                      <h3 className="break-word-content">
                        {clientName}
                        {isHaveAllergy && <div className="allergyIcon" />}
                      </h3>
                      <div className="member-type-outer">
                        {this.state.clientData &&
                          this.state.clientData
                            .patient_membership_subscription &&
                          this.state.clientData.patient_membership_subscription
                            .length > 0 &&
                          this.state.clientData.patient_membership_subscription.map(
                            (data, index) => {
                              const getMembershipColor = () => {
                                if (data?.membership_tier_id > 0) {
                                  return (
                                    data?.membership_tier?.membership_color ||
                                    DEFAULT_MEMBERSHIP_COLOR
                                  );
                                }
                                if (data?.membership_tier_id === 0) {
                                  return (
                                    this.state.clientData?.account_prefrences
                                      ?.membership_color ||
                                    DEFAULT_MEMBERSHIP_COLOR
                                  );
                                }
                                return DEFAULT_MEMBERSHIP_COLOR;
                              };

                              if (
                                data.last_invoice &&
                                data.last_invoice.payment_status === "past_due"
                              ) {
                                return (
                                  <Fragment key={data.id}>
                                    <span
                                      className="colorRed exclamationPatientProfile"
                                      data-tip
                                      data-for={`client-payment-error-${data.id}`}
                                    >
                                      <i className="fa fa-exclamation-circle"></i>
                                    </span>
                                    <ReactTooltip
                                      className="client-payment-error-tooltip"
                                      id={`client-payment-error-${data.id}`}
                                      place="right"
                                      border
                                      textColor="red"
                                      backgroundColor="#fff"
                                      borderColor="red"
                                      arrowColor="red"
                                    >
                                      <span>
                                        Payment declined for{" "}
                                        {data.membership_tier.tier_name}!
                                      </span>
                                    </ReactTooltip>
                                  </Fragment>
                                );
                              }
                              return (
                                <MembershipBadge
                                  key={
                                    "patient_membership_subscription-" + index
                                  }
                                  color={getMembershipColor()}
                                  displayPage="bedge-client-profile"
                                  onHold={data.membership_on_hold}
                                />
                              );
                            },
                          )}
                        {this.state.userData !== undefined &&
                          (this.state.userData.user.account_id ===
                            process.env.REACT_APP_JUVLY_ACC_ID ||
                            this.state.userData.user.account_id ===
                            process.env.REACT_APP_CC_ACC_ID) &&
                          iconSrc && (
                            <img className={iconClass} src={iconSrc} />
                          )}
                        {this.state.clientData.documentsExpirationDate && (
                          <a
                            className="cursor-default flex"
                            data-tip
                            data-for="client-expiration"
                          >
                            <TIMER_ICON
                              width="32px"
                              height="32px"
                              color="red"
                            />
                            <ReactTooltip
                              className="client-payment-error-tooltip"
                              place="right"
                              border
                              textColor="red"
                              backgroundColor="#fff"
                              borderColor="red"
                              arrowColor="red"
                              id="client-expiration"
                            >
                              <span>
                                {`${this.state.globalLang.label_client} has a document that will expire soon.`}
                              </span>
                            </ReactTooltip>
                          </a>
                        )}
                      </div>
                      <div className="client-setting">
                        <span>
                          {
                            this.state.languageData
                              .clientprofile_fire_this_client
                          }
                        </span>
                        <label className="setting-switch pull-right">
                          <input
                            ref={"ref_1"}
                            type="checkbox"
                            defaultChecked={this.state.clientData.is_fired}
                            data-message={fireMsg}
                            data-mtype={fireAction}
                            data-action="updateFireStatus"
                            name="fireCheck"
                            className="setting-custom-switch-input"
                            onClick={this.openModal}
                          />
                          <span className="setting-slider "></span>
                        </label>
                      </div>
                      <div className="client-setting">
                        <span>Do Not Call</span>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name={"do_not_call"}
                            checked={this.state.clientData.do_not_call}
                            onChange={this.toggleNotification}
                            className="filled-checkbox"
                          />
                          <span className="setting-slider "></span>
                        </label>
                      </div>
                      <div className="client-setting">
                        <span>Do Not SMS</span>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name={"do_not_sms"}
                            checked={this.state.clientData.do_not_sms}
                            onChange={this.toggleNotification}
                            className="filled-checkbox"
                          />
                          <span className="setting-slider "></span>
                        </label>
                      </div>
                      <div className="client-setting">
                        <span>Do Not Email</span>
                        <label className="setting-switch pull-right">
                          <input
                            type="checkbox"
                            name={"do_not_email"}
                            checked={this.state.clientData.do_not_email}
                            onChange={this.toggleNotification}
                            className="filled-checkbox"
                          />
                          <span className="setting-slider "></span>
                        </label>
                      </div>
                      {(checkIfPermissionAllowed("add-update-patients") ||
                        checkIfPermissionAllowed("delete-patients") ||
                        checkIfPermissionAllowed("export-patients")) && (
                          <div className="dropdown">
                            <button
                              className="line-btn profile-actions no-margin"
                              type="button"
                              id="dropdownMenu1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="true"
                            >
                              {this.state.languageData.client_actions}
                              <i className="fas fa-angle-down"></i>
                            </button>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenu1"
                            >
                              {checkIfPermissionAllowed(
                                "add-update-patients",
                              ) && (
                                  <li>
                                    <a
                                      className="modal-link"
                                      onClick={this.editClienProfile}
                                    >
                                      {this.state.languageData.client_edit}
                                    </a>
                                  </li>
                                )}
                              {checkIfPermissionAllowed("delete-patients") && (
                                <li>
                                  <a
                                    className="confirm-model"
                                    onClick={this.openModal}
                                    data-message={deleteMsg}
                                    data-mtype="delete"
                                    data-action="deleteClient"
                                  >
                                    {this.state.languageData.client_delete}
                                  </a>
                                </li>
                              )}
                              {checkIfPermissionAllowed("export-patients") && (
                                <li>
                                  <a onClick={this.exportClientPDF}>
                                    {
                                      this.state.languageData
                                        .clientprofile_export_pdf
                                    }
                                  </a>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="about-form">
                    <div className="row">
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">ID</label>
                        <span className="popup-field-box">
                          {this.state.clientData.id}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {"Clinic"}
                        </label>
                        <div
                          className={
                            !this.state.show_clinics
                              ? "popup-field-box"
                              : "popup-field-box no-display"
                          }
                        >
                          <span className="profileLeftClinic text-ellipsis">
                            {this.state.clinicName != ""
                              ? shortenClinicName(this.state.clinicName)
                              : "No clinic assigned"}
                          </span>
                          <span className="profileLeftbtns">
                            <a
                              className="xs-blue-btn pull-right"
                              onClick={this.showClinicDropdown}
                            >
                              Edit
                            </a>
                          </span>
                        </div>
                        <div
                          className={
                            this.state.show_clinics
                              ? "popup-field-box"
                              : "popup-field-box no-display"
                          }
                        >
                          <select
                            className="profileLeftSelect p-r-20"
                            name="patient_clinic_id"
                            value={this.state.patient_clinic_id}
                            onChange={this.handleInputChange}
                          >
                            <option value="0">Select Clinic</option>
                            {this.state.clientData !== undefined &&
                              this.state.clientData.clinics &&
                              this.state.clientData.clinics.map((obj, idx) => {
                                return (
                                  <option value={obj.id} key={"clinics-" + idx}>
                                    {shortenClinicName(obj.clinic_name)}
                                  </option>
                                );
                              })}
                          </select>
                          <span className="profileLeftbtns">
                            <a
                              className="xs-blue-btn pull-right"
                              onClick={this.saveClinicOfClient}
                            >
                              Save
                            </a>
                            <a
                              className="xs-white-btn pull-right"
                              onClick={() =>
                                this.setState({ show_clinics: false })
                              }
                            >
                              Cancel
                            </a>
                          </span>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.client_email}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.email !== ""
                            ? this.state.clientData.email
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.client_phone}
                        </label>
                        <span className="popup-field-box">
                          {unwrapOr(
                            () =>
                              phoneUtil.formatWithDashes(
                                this.state.clientData.phoneNumber,
                              ),
                            "",
                          )}
                        </span>
                      </div>
                      {this.state.clientData &&
                        this.state.clientData.email &&
                        this.state.clientData.account_prefrences &&
                        this.state.clientData.account_prefrences
                          .patient_sign_up > 0 && (
                          <div className="col-xs-12">
                            <label className="popup-input-field-name accessporta-name">
                              {
                                this.state.languageData
                                  .clientprofile_access_portal
                              }
                            </label>
                            <div className="access-portal-allrow">
                              <div className="access-portal-row">
                                <span className="access-portal-label">
                                  {
                                    this.state.languageData
                                      .clientprofile_invited
                                  }{" "}
                                </span>
                                <label className="setting-switch">
                                  <input
                                    ref={"ref_3"}
                                    type="checkbox"
                                    name="access_portal"
                                    data-message={
                                      "Are you sure you want to change this access?"
                                    }
                                    data-mtype={portalAction}
                                    data-action="updatePatientPortalAccess"
                                    className="setting-custom-switch-input"
                                    onClick={this.openModal}
                                    defaultChecked={
                                      this.state.clientData.access_portal
                                    }
                                  />
                                  <span className="setting-slider "></span>
                                </label>
                                {hasPastAcc === true && (
                                  <button
                                    onClick={() => this.showResetPassModal()}
                                    className="line-btn pull-right restPasPtn"
                                    type="button"
                                    id="reset_portal_btn"
                                  >
                                    {
                                      this.state.languageData
                                        .clientprofile_reset_password
                                    }
                                  </button>
                                )}
                              </div>
                              {this.state.clientData.access_portal === 1 && (
                                <div className="access-portal-row acc_class">
                                  <span className="access-portal-label">
                                    {
                                      this.state.languageData
                                        .clientprofile_accepted
                                    }
                                  </span>
                                  <span className="access-portal-content pull-i">
                                    {hasPastAcc === true ? "Yes" : "No"}
                                  </span>
                                  <span className="access-portal-content">
                                    {hasPastAcc === false && (
                                      <button
                                        onClick={() => this.resendEmail()}
                                        className="line-btn pull-right"
                                        type="button"
                                      >
                                        {
                                          this.state.languageData
                                            .clientprofile_resend_email
                                        }
                                      </button>
                                    )}
                                  </span>
                                </div>
                              )}
                              {hasPastAcc === true && (
                                <div className="access-portal-row login_email_class">
                                  <span className="access-portal-label">
                                    {
                                      this.state.languageData
                                        .clientprofile_login_email
                                    }
                                  </span>
                                  <span className="access-portal-content login_email">
                                    {this.state.clientData.patient_account !==
                                      undefined &&
                                      this.state.clientData.patient_account !==
                                      null &&
                                      this.state.clientData.patient_account &&
                                      this.state.clientData.patient_account
                                        .patient_user
                                      ? this.state.clientData.patient_account
                                        .patient_user.email
                                      : "N/A"}
                                  </span>
                                </div>
                              )}
                              {hasPastAcc === true && (
                                <div className="access-portal-row login_email_class">
                                  <span className="access-portal-label">{`Email Verified`}</span>
                                  <span className="access-portal-content pull-i">
                                    {isEmailVerfied == "0" ? "Yes" : "No"}
                                  </span>
                                  <span className="access-portal-content">
                                    {hasPastAcc === true &&
                                      isEmailVerfied === 1 && (
                                        <button
                                          onClick={() => this.verifyEmail()}
                                          className="line-btn pull-right"
                                          type="button"
                                        >{`Verify Email`}</button>
                                      )}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_birthday}
                        </label>
                        <span className="popup-field-box">
                          {this.state.showLoader === false &&
                            this.state.clientData !== undefined &&
                            this.state.clientData.date_of_birth !== "" &&
                            this.state.clientData.date_of_birth !== null
                            ? format(
                              this.state.clientData.date_of_birth,
                              getDateFormat(),
                            )
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_address}
                        </label>
                        <span className="popup-field-box">
                          {address.join(", ")}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_emergency_name}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.emergency_contact_name !== ""
                            ? this.state.clientData.emergency_contact_name
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {
                            this.state.languageData
                              .clientprofile_emergency_number
                          }
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.emergency_contact_number !== ""
                            ? this.state.clientData.emergency_contact_number
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_last_visit}
                        </label>
                        <span className="popup-field-box">
                          {this.state?.clientProcedureList?.[0]?.procedure_date
                            ? showFormattedDate(
                              this.state.clientProcedureList[0]
                                .procedure_date,
                            )
                            : "Never"}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {
                            this.state.languageData
                              .clientprofile_membership_program
                          }
                        </label>
                        <span className="popup-field-box">
                          {isRegistered}
                          <br />
                          {totalSavings}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_sale_relation}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.total_sale_relationship !==
                            null &&
                            this.state.clientData.total_sale_relationship > 0
                            ? numberFormat(
                              this.state.clientData.total_sale_relationship,
                              "currency",
                            )
                            : numberFormat(0, "currency")}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_bd_acc_no}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.brilliant_acc_number !== ""
                            ? this.state.clientData.brilliant_acc_number
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {
                            this.state.languageData
                              .clientprofile_xperience_acc_no
                          }
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData?.xperience_username}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.clientprofile_aspire_acc_no}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.aspire_acc_email !== ""
                            ? this.state.clientData.aspire_acc_email
                            : ""}
                        </span>
                      </div>
                      {this.state.patientSkinScore !== undefined &&
                        this.state.patientSkinScore.points !== undefined && (
                          <div className="col-xs-12 fitzpatrick-block">
                            <label className="popup-input-field-name">
                              {
                                this.state.languageData
                                  .clientprofile_fitzpatrick_test
                              }
                            </label>
                            <span className="popup-field-box">
                              {this.state.languageData.clientprofile_your_pts}{" "}
                              {this.state.patientSkinScore.points}
                              <br />
                              {
                                this.state.languageData.clientprofile_skin_type
                              }{" "}
                              {this.state.patientSkinScore.skin_type}
                              <br />
                              {
                                this.state.languageData.clientprofile_last_taken
                              }{" "}
                              {showFormattedDate(
                                this.state.patientSkinScore.created,
                                true,
                              )}
                            </span>
                            <button
                              className="line-btn pull-right skin-descp"
                              type="button"
                              onClick={() =>
                                this.setState({ showSkinModal: true })
                              }
                            >
                              {
                                this.state.languageData
                                  .clientprofile_view_details
                              }
                            </button>
                          </div>
                        )}

                      {isGlobalPosEnabled && (
                        <div className="col-xs-12">
                          <label className="popup-input-field-name">
                            {this.state.languageData.clientprofile_cc_number}
                          </label>
                          <div className="setting popup-field-box items-center">
                            <ClientCreditCards
                              clientId={this.state.clientID}
                              clientClinicId={this.state.patient_clinic_id}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {this.state.languageData.client_zip_code}
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.pincode !== ""
                            ? this.state.clientData.pincode
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          {
                            this.state.languageData
                              .clientprofile_referral_source
                          }
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.referral_source !== ""
                            ? this.state.clientData.referral_source
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          REFERRAL SOURCE SUBCATEGORY
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.referral_source_subcategory !==
                            ""
                            ? this.state.clientData.referral_source_subcategory
                            : ""}
                        </span>
                      </div>
                      <div className="col-xs-12">
                        <label className="popup-input-field-name">
                          Insurance Provider
                        </label>
                        <span className="popup-field-box">
                          {this.state.clientData !== undefined &&
                            this.state.clientData.patient_insurence
                            ? this.state.clientData.patient_insurence
                              .insurance_provider
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={
                this.state.showPrescriptionModal === true ? "modalOverlay" : ""
              }
            >
              <div
                className={
                  this.state.showPrescriptionModal === true
                    ? "small-popup-outer appointment-detail-main displayBlock"
                    : "small-popup-outer appointment-detail-main no-display"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.languageData.clientprofile_prescription_detail}
                  </div>
                  <a
                    onClick={() => this.hidePrescription()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                <div className="small-popup-content">
                  <div className="juvly-container no-padding-bottom">
                    <div className="prescription-content">
                      <div className="doc-section">
                        <div className="col-xs-12 no-margin">
                          {this.state.prescriptionField.map((obj, idx) => {
                            return (
                              <div
                                key={"prescription-row-" + idx}
                                className="row prescription-row"
                              >
                                <div className="col-xs-12">
                                  <div className="setting-field-outer">
                                    <div className="new-field-label">
                                      {
                                        this.state.languageData
                                          .clientprofile_medicine_name
                                      }{" "}
                                      <span className="setting-require">*</span>
                                    </div>
                                    <div className="setting-input-outer">
                                      <input
                                        name="medicine_name"
                                        value={obj.medicine_name}
                                        className={obj.medicineNameClass}
                                        data-index={idx}
                                        data-modal={"prescription"}
                                        type="text"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                  <div className="setting-field-outer">
                                    <div className="new-field-label">
                                      {
                                        this.state.languageData
                                          .clientprofile_dosage
                                      }{" "}
                                      <span className="setting-require">*</span>
                                    </div>
                                    <div className="setting-input-outer">
                                      <input
                                        name="dosage"
                                        value={obj.dosage}
                                        className={obj.dosageClass}
                                        data-index={idx}
                                        data-modal={"prescription"}
                                        type="text"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                  <div className="setting-field-outer">
                                    <div className="new-field-label">
                                      {
                                        this.state.languageData
                                          .clientprofile_frequency
                                      }{" "}
                                    </div>
                                    <div className="setting-input-outer">
                                      <input
                                        name="frequency"
                                        value={obj.frequency}
                                        className={obj.frequencyClass}
                                        data-index={idx}
                                        data-modal={"prescription"}
                                        type="text"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-4 col-xs-12">
                                  <div className="setting-field-outer">
                                    <div className="new-field-label">
                                      {
                                        this.state.languageData
                                          .clientprofile_duration
                                      }{" "}
                                    </div>
                                    <div className="setting-input-outer">
                                      <input
                                        name="duration"
                                        value={obj.duration}
                                        className={obj.durationClass}
                                        data-index={idx}
                                        data-modal={"prescription"}
                                        type="text"
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {this.state.prescriptionField.length > 1 && (
                                  <a
                                    className="add-round-btn"
                                    onClick={() =>
                                      this.deleteMultiplePrescription(idx)
                                    }
                                  >
                                    <span>-</span>
                                  </a>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <a
                    className="new-blue-btn pull-right"
                    onClick={() => this.saveProcedurePrescription()}
                  >
                    {this.state.languageData.clientprofile_save}
                  </a>
                  <a
                    className="new-blue-btn pull-right"
                    onClick={() => this.addMultiplePrescription()}
                  >
                    {this.state.globalLang.label_add_more}
                  </a>
                  {this.state.isSendEmail === true && (
                    <a
                      className="new-blue-btn pull-right"
                      onClick={() => this.sendProcedurePrescription()}
                    >
                      {this.state.languageData.clientprofile_send_email}
                    </a>
                  )}
                  {this.state.isSendEmail === true && (
                    <a
                      onClick={this.openModal}
                      data-message={
                        "Are you sure you want to delete this prescription?"
                      }
                      data-mtype="delete"
                      data-action="deleteProcedurePrescription"
                      className="new-red-btn pull-left"
                    >
                      {this.state.languageData.client_delete}
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div
              className={
                this.state.showNewPrescriptionModal === true
                  ? "modalOverlay"
                  : ""
              }
            >
              <div
                className={
                  this.state.showNewPrescriptionModal === true
                    ? "small-popup-outer appointment-detail-main displayBlock extra-large-popup"
                    : "small-popup-outer appointment-detail-main no-display extra-large-popup"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.languageData.clientprofile_prescription_detail}
                  </div>
                  <a
                    onClick={() => this.hidePrescriptionNew()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>

                <div className="small-popup-content">
                  <div className="juvly-container no-padding-bottom new-prescription-section">
                    <div className="row m-b-10">
                      <div className="col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">
                            Clinical Assessment
                            <span className="setting-require">*</span>
                          </div>
                          <textarea
                            className={this.state.prescription_note_class}
                            placeholder="Write your note here"
                            name="prescription_note"
                            onChange={this.handleInputChange}
                            value={this.state.prescription_note}
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div className="juvly-subtitle m-b-10 m-t-0">
                      Prescription Information
                    </div>
                    <div className="table-responsive fixed-header-table orderInfoTable m-b-30">
                      <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                        <thead className="table-updated-thead">
                          <tr>
                            <th className="col-xs-3 table-updated-th p-r-0">
                              Medication
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Form
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Strength
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Quantity
                            </th>
                            <th className="col-xs-3 table-updated-th p-r-0">
                              Directions/Frequency
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Dose
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Refills
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0" />
                          </tr>
                        </thead>
                        <tbody className="ajax_body no-scroll">
                          {this.state.newPrescriptionField.map((obj, idx) => {
                            return (
                              <tr
                                key={"prescription-row-" + idx}
                                className="table-updated-tr"
                              >
                                <td className="col-xs-3 table-updated-td p-r-0">
                                  <div className="setting-input-outer">
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      data-index={idx}
                                      data-modal={"prescriptionNew"}
                                      className={obj.medicationClass}
                                      name="medicine_name"
                                      onChange={this.handleInputChange}
                                      value={obj.medicine_name}
                                      placeholder="Medication"
                                    />
                                  </div>
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  <input
                                    data-index={idx}
                                    autoComplete="off"
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.formClass}
                                    name="form"
                                    onChange={this.handleInputChange}
                                    value={obj.form}
                                    placeholder="Form"
                                  />
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  <input
                                    data-index={idx}
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.strengthClass}
                                    autoComplete="off"
                                    name="strength"
                                    onChange={this.handleInputChange}
                                    value={obj.strength}
                                    placeholder="Strength"
                                  />
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  <input
                                    data-index={idx}
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.quantityClass}
                                    autoComplete="off"
                                    name="quantity"
                                    onChange={this.handleInputChange}
                                    value={obj.quantity}
                                    placeholder="Quantity"
                                  />
                                </td>
                                <td className="col-xs-3 table-updated-td p-r-0">
                                  <input
                                    data-index={idx}
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.directionsClass}
                                    autoComplete="off"
                                    name="frequency"
                                    onChange={this.handleInputChange}
                                    value={obj.frequency}
                                    placeholder="Directions/Frequency"
                                  />
                                </td>
                                <td className="col-xs-1 table-updated-th p-r-0">
                                  <input
                                    data-index={idx}
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.doseClass}
                                    autoComplete="off"
                                    name="dosage"
                                    onChange={this.handleInputChange}
                                    value={obj.dosage}
                                    placeholder="Dose"
                                  />
                                </td>
                                <td className="col-xs-1 table-updated-th p-r-0">
                                  <input
                                    data-index={idx}
                                    data-modal={"prescriptionNew"}
                                    type="text"
                                    className={obj.refillsClass}
                                    autoComplete="off"
                                    name="refills"
                                    onChange={this.handleInputChange}
                                    value={obj.refills}
                                    placeholder="Refills"
                                  />
                                </td>
                                {idx == 0 ? (
                                  <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal">
                                    <a
                                      className="easy-link p-t-5  p-r-0"
                                      onClick={() => this.addNewPrescription()}
                                    >
                                      <i className="fa fa-plus m-r-5 m-t-2"></i>
                                    </a>
                                  </td>
                                ) : (
                                  <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal">
                                    <a
                                      className="easy-link p-t-5  p-r-0"
                                      onClick={() =>
                                        this.removeNewPrescription(idx)
                                      }
                                    >
                                      <i className="fa fa-minus m-r-5 m-t-2"></i>
                                    </a>
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-md-4 col-sm-7 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">
                            Pharmacy <span className="setting-require">*</span>
                          </div>
                          <select
                            className={this.state.pharmacy_id_class}
                            name="pharmacy_id"
                            value={this.state.pharmacy_id}
                            onChange={this.handleInputChange}
                          >
                            <option value="">Select</option>
                            {this.state.clientData &&
                              this.state.clientData.pharmacy_list &&
                              this.state.clientData.pharmacy_list.length > 0 &&
                              this.state.clientData.pharmacy_list.map((obj) => {
                                return (
                                  <option
                                    key={"pharmacy_list-" + obj.id}
                                    value={obj.id}
                                  >
                                    {obj.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-2 col-sm-6 col-xs-11">
                        <a
                          onClick={() => this.showPharmacyModal()}
                          className="new-blue-btn m-t-10 m-l-0 m-b-10"
                        >
                          Add New
                        </a>
                      </div>

                      <div className="col-md-6 col-sm-7 col-xs-12 calender">
                        <div className="setting-field-outer  m-b-15">
                          <div className="new-field-label">
                            Date Needed{" "}
                            <span className="setting-require">*</span>
                          </div>
                          <CustomDatePicker
                            name="date_needed"
                            onChangeDatePicker={this.onChangeDatePicker}
                            minDate={new Date()}
                            maxDate={
                              new Date(moment().add(10, "years").toDate())
                            }
                            value={this.state.date_needed}
                            class={this.state.date_needed_class}
                            format={dateFormatPicker()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-b-30">
                      <div className="col-md-6  col-xs-12 p-r-0">
                        <div className="newInputFileldOuter m-t-5 preferMethodContact m-b-15">
                          <div className="new-field-label m-b-0">Ship To</div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input
                              id="Prescriber's Office"
                              className="basic-form-checkbox"
                              name="ship_to"
                              type="radio"
                              onChange={this.handleInputChange}
                              value={`prescriber`}
                              checked={this.state.ship_to === "prescriber"}
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="Prescriber's Office"
                            >
                              {`Prescriber's Office`}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input
                              id="Patient's Home"
                              className="basic-form-checkbox"
                              name="ship_to"
                              type="radio"
                              onChange={this.handleInputChange}
                              value={`patient`}
                              checked={this.state.ship_to === "patient"}
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="Patient's Home"
                            >
                              {`Patient's Home`}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input
                              id="Other1"
                              className="basic-form-checkbox"
                              name="ship_to"
                              type="radio"
                              onChange={this.handleInputChange}
                              value={`other`}
                              checked={
                                this.state.ship_to != "prescriber" &&
                                this.state.ship_to != "patient"
                              }
                            />
                            <label className="basic-form-text" htmlFor="Other1">
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.ship_to &&
                        this.state.ship_to != "prescriber" &&
                        this.state.ship_to != "patient" && (
                          <div className="col-md-6 col-sm-7 col-xs-11">
                            <div className="setting-field-outer">
                              <input
                                className={this.state.shipto_ohers_addr_class}
                                type="text"
                                name="shipto_ohers_addr"
                                value={this.state.shipto_ohers_addr}
                                autoComplete="off"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <a
                    className="new-blue-btn"
                    onClick={() =>
                      this.showPrescriptionNew(
                        this.state.prescriptionProcedureId,
                        this.state.savedIndex,
                        true,
                      )
                    }
                  >
                    Back
                  </a>
                  <a
                    className="new-blue-btn pull-right"
                    onClick={() => this.saveNewProcedurePrescription()}
                  >
                    {this.state.languageData.clientprofile_save}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.showNewPrescriptionModalInViewMode === true
                  ? "modalOverlay"
                  : ""
              }
            >
              <div
                className={
                  this.state.showNewPrescriptionModalInViewMode === true
                    ? "small-popup-outer appointment-detail-main displayBlock extra-large-popup"
                    : "small-popup-outer appointment-detail-main no-display extra-large-popup"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">
                    {this.state.languageData.clientprofile_prescription_detail}
                  </div>
                  <a
                    onClick={() => this.hideViewPrescriptionNew()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>

                <div className="small-popup-content">
                  <div className="juvly-container no-padding-bottom new-prescription-section">
                    <div className="row m-b-10">
                      <div className="col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">
                            Clinical Assessment
                          </div>
                          <div className="setting-textarea-box auto-height">
                            {this.state.prescription_note}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="juvly-subtitle m-b-10 m-t-0">
                      Prescription Information
                    </div>
                    <div className="table-responsive fixed-header-table orderInfoTable m-b-30">
                      <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                        <thead className="table-updated-thead">
                          <tr>
                            <th className="col-xs-3 table-updated-th p-r-0">
                              Medication
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Form
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Strength
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Quantity
                            </th>
                            <th className="col-xs-3 table-updated-th p-r-0">
                              Directions/Frequency
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Dose
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              Refills
                            </th>
                            <th className="col-xs-1 table-updated-th p-r-0" />
                          </tr>
                        </thead>
                        <tbody className="ajax_body no-scroll">
                          {this.state.newPrescriptionField.map((obj, idx) => {
                            return (
                              <tr
                                key={"prescription-row-view-" + idx}
                                className="table-updated-tr"
                              >
                                <td className="col-xs-3 table-updated-td p-r-0">
                                  {obj.medicine_name}
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  {obj.form}
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  {obj.strength}
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0">
                                  {obj.quantity}
                                </td>
                                <td className="col-xs-3 table-updated-td p-r-0">
                                  {obj.frequency}
                                </td>
                                <th className="col-xs-1 table-updated-th p-r-0">
                                  {obj.dosage}
                                </th>
                                <td className="col-xs-1 table-updated-th p-r-0">
                                  {obj.refills}
                                </td>
                                <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal"></td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row m-b-10">
                      <div className="col-md-6 col-sm-7 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy</div>
                          <div className="setting-input-box">
                            {this.state.clientData &&
                              this.state.clientData.pharmacy_list &&
                              this.state.clientData.pharmacy_list.length > 0 &&
                              this.state.clientData.pharmacy_list.map((obj) => {
                                if (obj.id == this.state.pharmacy_id) {
                                  return obj.name;
                                }
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-7 col-xs-12 calender">
                        <div className="setting-field-outer  m-b-15">
                          <div className="new-field-label">Date Needed</div>
                          <div className="setting-input-box">
                            {this.state.showNewPrescriptionModalInViewMode ===
                              true && showFormattedDate(this.state.date_needed)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row m-b-30">
                      <div className="col-md-6  col-xs-12 p-r-0">
                        <div className="newInputFileldOuter m-t-5 preferMethodContact m-b-15">
                          <div className="new-field-label m-b-0">Ship To</div>
                          <div className="basic-checkbox-outer m-b-0">
                            {this.state.ship_to &&
                              this.state.ship_to === "prescriber" &&
                              "Prescriber's Office"}
                            {this.state.ship_to &&
                              this.state.ship_to === "patient" &&
                              "Patient's Home"}
                            {this.state.ship_to &&
                              this.state.ship_to != "prescriber" &&
                              this.state.ship_to != "patient" &&
                              this.state.shipto_ohers_addr}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  {this.state.prescription_pdf_url ? (
                    <a
                      className="new-blue-btn"
                      onClick={() =>
                        this.downloadPrescriptionPDF(
                          this.state.prescriptionProcedureId,
                        )
                      }
                    >
                      Download
                    </a>
                  ) : (
                    <a
                      className="new-blue-btn"
                      onClick={() =>
                        this.generatePrescriptionPDF(
                          this.state.prescriptionProcedureId,
                        )
                      }
                    >
                      Generate PDF
                    </a>
                  )}
                  <a
                    className="new-blue-btn pull-right"
                    onClick={() =>
                      this.showPrescriptionNew(
                        this.state.prescriptionProcedureId,
                        this.state.savedIndex,
                        false,
                      )
                    }
                  >
                    Edit
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.showPharmacyModal === true ? "modalOverlay" : ""
              }
            >
              <div
                className={
                  this.state.showPharmacyModal === true
                    ? "small-popup-outer recieveProductPopup displayTable"
                    : "small-popup-outer recieveProductPopup no-display"
                }
              >
                <div className="small-popup-header">
                  <div className="popup-name">Add Pharmacy</div>
                  <a
                    onClick={() => this.hidePharmacyModal()}
                    className="small-cross"
                  >
                    ×
                  </a>
                </div>
                <div className="small-popup-content no-scroll">
                  <div className="juvly-container no-padding-bottom">
                    <div className="prescription-content">
                      <div className="doc-section pharmacy-section">
                        <div className="col-xs-12 no-margin">
                          <div className="row prescription-row">
                            <div className="col-xs-12">
                              <div className="setting-field-outer">
                                <div className="new-field-label">
                                  Name<span className="setting-require">*</span>
                                </div>
                                <div className="setting-input-outer">
                                  <input
                                    name="pharmacy_name"
                                    value={this.state.pharmacy_name}
                                    className={this.state.pharmacy_name_class}
                                    data-modal={"add_pharmacy"}
                                    type="text"
                                    autoComplete="off"
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                              <div className="setting-field-outer">
                                <div className="new-field-label">Fax</div>
                                <div className="setting-input-outer">
                                  <InputPhone
                                    fullWidth
                                    variant="legacy-outlined"
                                    value={this.state.pharmacy_fax || ""}
                                    onChange={this.phoneNumberChanged.bind(
                                      this,
                                      "pharmacy_fax",
                                    )}
                                    isError={this.state.pharmacy_fax_error}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6 col-xs-12">
                              <div className="setting-field-outer">
                                <div className="new-field-label">
                                  Phone
                                  <span className="setting-require">*</span>
                                </div>
                                <div className="setting-input-outer">
                                  <InputPhone
                                    fullWidth
                                    variant="legacy-outlined"
                                    value={this.state.pharmacy_phone || ""}
                                    onChange={this.phoneNumberChanged.bind(
                                      this,
                                      "pharmacy_phone",
                                    )}
                                    isError={this.state.pharmacy_phone_error}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <a
                    className="new-blue-btn pull-right"
                    onClick={() => this.savePharmacy()}
                  >
                    {this.state.languageData.clientprofile_save}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={this.state.showInsSendModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showInsSendModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissInsSendModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.client_conf_requierd}
                    </h4>
                  </div>
                  <div className="modal-body add-patient-form filter-patient">
                    {this.state.languageData.clientprofile_send_post_message}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissInsSendModal}
                      >
                        {this.state.languageData.client_no}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.sendPostInstructions}
                      >
                        {this.state.languageData.client_yes}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={this.state.showPreInsModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showPreInsModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissPreModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.client_conf_requierd}
                    </h4>
                  </div>
                  <div className="modal-body add-patient-form filter-patient">
                    Are you sure you want to send pre treatment instructions?
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissPreModal}
                      >
                        {this.state.languageData.client_no}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.sendPreInstructions}
                      >
                        {this.state.languageData.client_yes}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={this.state.showModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.client_conf_requierd}
                    </h4>
                  </div>
                  <div
                    id="errorwindow"
                    className="modal-body add-patient-form filter-patient"
                  >
                    {this.state.modalMessage !== undefined
                      ? this.state.modalMessage
                      : ""}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        {this.state.languageData.client_no}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.handleModalAction}
                      >
                        {this.state.languageData.client_yes}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={this.state.showSkinModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showSkinModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissSkinModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.clientprofile_skin_details}
                    </h4>
                  </div>
                  <div className="modal-body add-patient-form modalSkinMessage-patient p-b-30">
                    {this.state.modalSkinMessage !== undefined
                      ? this.state.modalSkinMessage
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.showResetPassModal === true
                  ? "modalOverlay"
                  : "modalOverlay no-display"
              }
            >
              <form onSubmit={this.handleSubmit}>
                <div className="small-popup-outer">
                  <div className="small-popup-header">
                    <div className="popup-name">
                      {this.state.languageData.clientprofile_reset_password}
                    </div>
                    <a
                      onClick={() => this.dismissPassModal()}
                      className="small-cross"
                    >
                      ×
                    </a>
                  </div>
                  <div className="small-popup-content">
                    <div className="juvly-container no-padding-bottom">
                      <div className="row">
                        <div className="col-sm-6 col-xs-12">
                          <div className="setting-field-outer">
                            <div className="new-field-label">
                              {
                                this.state.languageData
                                  .clientprofile_user_emailid
                              }
                            </div>
                            <div className="setting-input-outer">
                              <input
                                className="setting-input-box"
                                type="text"
                                name="clientEmailID"
                                defaultValue={
                                  this.state.clientData &&
                                    this.state.clientData.patient_account &&
                                    this.state.clientData.patient_account
                                      .patient_user &&
                                    this.state.clientData.patient_account
                                      .patient_user.email !== ""
                                    ? this.state.clientData.patient_account
                                      .patient_user.email
                                    : ""
                                }
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <div className="setting-field-outer">
                            <div className="new-field-label">
                              {
                                this.state.languageData
                                  .clientprofile_new_password
                              }
                            </div>
                            <div className="setting-input-outer">
                              <input
                                ref="clientPass"
                                className={this.state.resetPassClass}
                                type={this.state.resetPassType}
                                name="clientNewPassword"
                                onChange={this.handleInputChange}
                              />
                              <span
                                onClick={this.changePassType}
                                className={this.state.eyeClass}
                              ></span>
                            </div>
                          </div>
                        </div>
                        <p className="p-text col-xs-12">
                          {this.state.languageData.clientprofile_password_note}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="footer-static">
                    <input
                      className="new-blue-btn pull-right"
                      type="submit"
                      value={this.state.languageData.clientprofile_save}
                    />
                  </div>
                </div>
              </form>
            </div>
            {this.state.clientID &&
              this.state.clientData.patient_membership_subscription &&
              this.state.showImmediateMembershipModal && (
                <ClientMembership
                  newMembershipDataTime={this.state.newMembershipDataTime}
                  newMembershipData={this.state.newMembershipData}
                  changeMembershipDrawDay={this.props.changeMembershipDrawDay}
                  changeDrawDayPaymentDetail={changeDrawDayPaymentDetail}
                  updateMembershipCC={this.props.updateMembershipCC}
                  userData={this.state.userData}
                  getDrawDayPaymentDetails={this.getDrawDayPaymentDetails}
                  handleHoldInputChange={this.handleInputChange}
                  cancelMembershipDrawDay={this.cancelMembershipDrawDay}
                  getMembershipFeeAcordion={this.getMembershipFeeAcordion}
                  updateMembershipHoldStatus={
                    this.props.updateMembershipHoldStatus
                  }
                  handleAddCard={this.handleSubmitPayment}
                  openLoader={() => this.setState({ showLoader: true })}
                  closeLoader={() => this.setState({ showLoader: false })}
                  changeMembershipClinicOption={
                    this.props.changeMembershipClinic
                  }
                  handleMembershipClinic={this.changeMembershipClinic}
                  handleDownloadAgreement={this.downloadMembershipAgreement}
                  getMembershipStatus={this.getMembershipStatus}
                  openCancelMembershipModal={this.openModal}
                  daysOptData={daysOptData}
                  clientID={this.state.clientID}
                  languageData={this.state.languageData}
                  memberships={
                    this.state.clientData.patient_membership_subscription
                  }
                  clientData={this.state.clientData}
                  onClose={this.showMembershipModal}
                />
              )}
            {!this.state.showImmediateMembershipModal && (
              <Timeline
                type={this.state.defTimeLine}
                onChangeType={this.changeTimelinePref}
                profile={this.state.clientData}
                onCreateAppointment={this.createAppointment}
                onCreateProcedure={this.createProcedure}
                isQualiphyEnabled={Boolean(this.props?.isQualiphyEnabled)}
                patientId={this.props.match.params.id}
                appointmentData={this.state.appointmentData}
                monthArray={monthArray}
                openProcedureDetail={this.openProcedureDetail}
                openLightbox={this.openLightbox}
                runCarousel={this.runCarousel}
                viewFilledSurvey={this.viewFilledSurvey}
                showSendInstructionPopup={
                  this.showSendInstructionPopup
                }
                viewQuestionnaires={this.viewQuestionnaires}
                viewQuestionnairesList={this.viewQuestionnairesList}
                viewBAVideos={this.viewBAVideos}
                viewTraceAbility={this.viewTraceAbility}
                viewNotes={this.viewNotes}
                viewConsents={this.viewConsents}
                getInvoiceDetails={this.getInvoiceDetails}
                handleChildState={this.handleChildState}
                handleEprescribe={this.handleEprescribe}
                viewProcedureDetail={this.viewProcedureDetail}
                showPrescriptionNew={this.showPrescriptionNew}
                viewHealthTraceability={this.viewHealthTraceability}
                goToInvoicePage={this.goToInvoicePage}
                procedureDataText={this.state.procedureDataText}
                getPrescribedPlanData={this.getPrescribedPlanData}
                refetchClientData={() =>
                  this.props.getClientProfileDetail(
                    this.state.clientID,
                    this.state.clientScopes,
                  )
                }
                showProgramDetails={this.showProgramDetails}
                cancelPlanByID={this.cancelPlanByID}
                getPlanDetailsByID={this.getPlanDetailsByID}
                performThisAction={this.performThisAction}
                viewBreakDown={this.viewBreakDown}
                startProTotal={this.state.startProTotal}
                startProMonthly={this.state.startProMonthly}
                changePlanCard={this.changePlanCard}
              />
            )}
          </div>
        </div>
        {this.state.showLoader && <Loader />}
        {this.state.showAgreement && (
          <ModalAgreement
            text={agreementText}
            onClose={this.closeAgreement}
            onAgree={this.handleAgree}
          />
        )}
        {Array.isArray(this.state.stickyNotes) &&
          this.state.stickyNotes.length > 0 && (
            <StickyNotesModal
              handleClose={this.closeStickyNotesModal}
              notes={this.state.stickyNotes}
            />
          )}
        {this.state.isCreateQualiphyProcedureModalOpen && (
          <ModalCreateQualiphyProcedure
            isOpen
            patientId={this.props.match.params.id}
            onSuccess={() => {
              this.refetchProcedures();
              this.setState({
                isCreateQualiphyProcedureModalOpen: false,
              });
            }}
            onClose={() =>
              this.setState({
                isCreateQualiphyProcedureModalOpen: false,
              })
            }
          />
        )}
      </div>
    );
  }

  getMembershipStatus(membership) {
    const isMarkedForCancel = Boolean(membership.marked_for_cancel);
    const isActive = Boolean(membership.active_membership);

    const isMarkedForHold = Boolean(membership.marked_for_hold);
    const isOnHold = Boolean(membership.membership_hold_status);

    return {
      isMarkedForCancel,
      isActive,
      isMarkedForOrCanceled: isMarkedForCancel || !isActive,
      isMarkedForHold,
      isOnHold,
      isMarkedForOrOnHold: isMarkedForHold || isOnHold,
    };
  }

  getMembershipStatusView(membership, status) {
    switch (true) {
      case !status.isActive:
        return (
          <span className="pull-right cancelled-text fontsize16">Canceled</span>
        );
      case status.isMarkedForCancel:
        return (
          <span className="pull-right cancelled-text fontsize16">
            Active until {showFormattedDate(membership.subscription_valid_upto)}
          </span>
        );
      case status.isOnHold:
        return (
          <span className="pull-right on-hold-text fontsize16">
            On-hold until {showFormattedDate(membership.membership_hold_until)}
          </span>
        );
      case status.isMarkedForHold:
        return (
          <span className="pull-right on-hold-text fontsize16">
            Active until {showFormattedDate(membership.subscription_valid_upto)}
          </span>
        );
      default:
        return null;
    }
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData")) || {
    global: {},
  };
  let returnState = {};

  if (state.ClientsReducer.action === "CLIENT_PROFILE_DATA") {
    if (state.ClientsReducer.data) {
      if (state.ClientsReducer.data.status !== 200) {
        if (
          state.ClientsReducer.data.message ===
          "patient_not_found_or_deleted" ||
          state.ClientsReducer.data.message === "client_not_found_or_deleted"
        ) {
          uiNotification.clear();
          uiNotification.error(
            languageData.global[state.ClientsReducer.data.message],
          );
          returnState.message = state.ClientsReducer.data.message;
          returnState.messageTime = new Date();
        } else {
          uiNotification.clear();
          uiNotification.error(
            languageData.global[state.ClientsReducer.data.message],
          );
          returnState.showLoader = false;
        }
      } else {
        returnState.clientData = state.ClientsReducer.data.data;
      }
    } else {
      returnState.showLoader = false;
    }
  }

  if (state.ClientsReducer.action === "FIRE_CLIENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
    } else {
      uiNotification.clear();
      returnState.fireData = state.ClientsReducer.data;
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
    }
  }

  if (state.ClientsReducer.action === "DND_CLIENT") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.dndStatus = new Date();
    }
  }

  if (state.ClientsReducer.action === "CHANGE_PORTAL_CLIENT") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      if (state.ClientsReducer.data.message === "third_party_error") {
        uiNotification.error(state.ClientsReducer.data.data);
      } else {
        uiNotification.error(
          languageData.global[state.ClientsReducer.data.message],
        );
      }
    } else {
      returnState.portalAccessData = state.ClientsReducer.data;
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  if (state.ClientsReducer.action === "GET_CLIENT_CARDS") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.cardData = state.ClientsReducer.data;
    } else {
      returnState.cardData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SAVE_CLIENT_CARD") {
    if (state.ClientsReducer.data.status !== 200) {
      returnState.saveCCData = [];
    } else {
      returnState.saveCCData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "RESEND_WELCOME_EMAIL") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.resendEmailData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "RESET_CLIENT_PORTAL_PASSWORD") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.resetPortalPasswordData = state.ClientsReducer.data;
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
    }
  }

  if (state.ClientsReducer.action === "DELETE_CLIENT") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.deleteData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "EXPORT_CLIENT_PDF") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.exportClientPDFData = state.ClientsReducer.data;
    } else {
      returnState.exportClientPDFData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SEND_POST_INSTRUCTION") {
    uiNotification.clear();

    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    }
  }

  if (state.ProcedureReducer.action === "SAVE_HEALTH_PROCEDURE_PRESCRIPTION") {
    returnState = {};
    if (state.ProcedureReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = [];
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = state.ProcedureReducer.data;
    }
  }

  if (
    state.ProcedureReducer.action === "DELETE_HEALTH_PROCEDURE_PRESCRIPTION"
  ) {
    returnState = {};
    if (state.ProcedureReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = [];
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = state.ProcedureReducer.data;
    }
  }

  if (state.ProcedureReducer.action === "SEND_HEALTH_PROCEDURE_PRESCRIPTION") {
    returnState = {};
    if (state.ProcedureReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = [];
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ProcedureReducer.data.message],
      );
      returnState.healthProcedurePrescription = state.ProcedureReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_MEMBERSHIP_DETAILS") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.membershipData = state.ClientsReducer.data;
    } else {
      returnState.membershipData = state.ClientsReducer.data;
      returnState.membershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "ADD_MONTHLY_MEMBERSHIP") {
    if (state.ClientsReducer.data.status !== 200) {
      if (state.ClientsReducer.data.message === "third_party_error") {
        uiNotification.error(state.ClientsReducer.data.data);
        returnState.addMembershipData = { status: 400, data: moment().unix() };
        returnState.showLoader = false;
      } else {
        uiNotification.error(
          languageData.global[state.ClientsReducer.data.message],
        );
        returnState.addMembershipData = state.ClientsReducer.data;
      }
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.addMembershipData = state.ClientsReducer.data;
      returnState.showLoader = false;
    }
  }

  if (state.ClientsReducer.action === "CANCEL_MEMBERSHIP") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message] ||
        state.ClientsReducer.data.message,
      );
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message] ||
        state.ClientsReducer.data.message,
      );
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "APPLY_DISCOUNT_COUPON") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.couponData = state.ClientsReducer.data;
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.couponData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "UPDATE_MEMBERSHIP_CC") {
    if (state.ClientsReducer.data.status !== 200) {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message]
          ? languageData.global[state.ClientsReducer.data.message]
          : state.ClientsReducer.data.message,
      );
    } else {
      uiNotification.clear();
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
    }
  } else if (state.SettingReducer.action === "SIGN_PROCEDURE") {
    if (state.SettingReducer.data.status !== 201) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.MDSignData = state.SettingReducer.data;
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
      returnState.MDSignData = state.SettingReducer.data;
    }
  }
  if (state.MembershipWalletReducer.action === "GET_MEMBERSHIP_MULTI_TIER") {
    if (state.MembershipWalletReducer.data.status != 200) {
      returnState.showLoader = false;
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.MembershipWalletReducer.data.message],
      );
    } else {
      returnState.membershipTypeData = state.MembershipWalletReducer.data.data;
    }
  }
  if (state.ClientsReducer.action === "PAST_DUE_INVOICE_DATA") {
    if (state.ClientsReducer.data.status != 200) {
      returnState.showLoader = false;

      if (state.ClientsReducer.data.message === "third_party_error") {
        uiNotification.clear();
        uiNotification.error(state.ClientsReducer.data.data);
      } else {
        uiNotification.clear();
        uiNotification.error(
          languageData.global[state.ClientsReducer.data.message],
        );
      }
    } else {
      uiNotification.clear();
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.membershipData = state.ClientsReducer.data;
      returnState.membershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "UPDATE_MEMBERSHIP_STATUS") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status != 200) {
      if (state.ClientsReducer.data.message === "third_party_error") {
        uiNotification.error(state.ClientsReducer.data.data);
        returnState.addMembershipData = { status: 400, data: moment().unix() };
        returnState.showLoader = false;
      } else {
        returnState.showLoader = false;
        uiNotification.error(
          languageData.global[state.ClientsReducer.data.message] ||
          state.ClientsReducer.data.message,
        );
      }
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message] ||
        state.ClientsReducer.data.message,
      );
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "CHANGE_MEMBERSHIP_CLIENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.clear();
      uiNotification.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "SEARCH_WALLET_PRODUCT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.productData = state.ClientsReducer.data;
    } else {
      returnState.productData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_TREAT_TEMPLATES") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.templatesData = state.ClientsReducer.data;
    } else {
      returnState.templatesData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_PLAN_TEMPLATE_DATA") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.templatesDetails = state.ClientsReducer.data;
    } else {
      returnState.templatesDetails = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SAVE_PAYG_TREATMENT_PLAN") {
    returnState.savePaygData = state.ClientsReducer.data;
  }

  if (state.ClientsReducer.action === "GET_PLAN_DATA_BY_ID") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.planDetails = state.ClientsReducer.data;
    } else {
      returnState.planDetails = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SAVE_MONTHLY_TREATMENT_PLAN") {
    if (state.ClientsReducer.data.status !== 200) {
      returnState.savePaygData = state.ClientsReducer.data;
    } else {
      returnState.savePaygData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_START_PROGRAM_DATA") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.programDetails = state.ClientsReducer.data;
    } else {
      returnState.programDetails = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "START_PROGRAM_APPLY_DISCOUNT") {
    returnState.startDiscountData = state.ClientsReducer.data;
  }

  if (state.ClientsReducer.action === "CHANGE_PLAN_CLINIC") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.programDetails = state.ClientsReducer.data;
    } else {
      returnState.programDetails = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "VIEW_PRICE_BREAK_UP") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.priceBreakUpData = state.ClientsReducer.data;
    } else {
      returnState.priceBreakUpData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "START_TREATMENT_PROGRAM") {
    returnState.startProgram = state.ClientsReducer.data;
  }

  if (state.ClientsReducer.action === "CANCEL_TREATMENT_PLAN") {
    returnState.cancelPlanData = state.ClientsReducer.data;
  }

  if (state.ClientsReducer.action === "SAVE_PLAN_AS_TEMPLATE") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.saveAsTemplateData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.saveAsTemplateData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "UPDATE_PLAN_CARD") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message]
          ? languageData.global[state.ClientsReducer.data.message]
          : state.ClientsReducer.data.message,
      );
      returnState.updatePlanCardData = state.ClientsReducer.data;
    } else {
      returnState.updatePlanCardData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_PRESCRIBE_ONLY_DETAILS") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.presOnlyDetails = state.ClientsReducer.data;
    } else {
      returnState.presOnlyDetails = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "UPDATE_PRESCRIBE_ONLY") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.updatePresOnlyData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.updatePresOnlyData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "PERFORM_TREATMENT_ACTIONS") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.performActionData = state.ClientsReducer.data;
    } else {
      returnState.performActionData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_NEW_MEMBERSHIP_DETAILS") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
      returnState.newMembership = true;
    }
  }

  if (state.ClientsReducer.action === "ADD_NEW_MEMBERSHIP_DATA") {
    if (state.ClientsReducer.data.status === 200) {
      returnState.membershipData = state.ClientsReducer.data;
      returnState.membershipDataTime = new Date();
    }
  }
  if (state.ClientsReducer.action === "GET_MEMBERSHIP_AGREEMENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.agreementData = state.ClientsReducer.data;
    } else {
      returnState.agreementData = state.ClientsReducer.data;
      returnState.agreementDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "GET_SELECTED_QUESTIONNAIRE_DETAILS") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
    } else {
      returnState.selectedQuestionnaireData = state.ClientsReducer.data.data;
      returnState.selectedQuestionnaireDataTime = new Date();
    }
  }
  if (state.ClientsReducer.action === "SAVE_CLIENT_CLINIC") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.clientClinicData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.clientClinicData = state.ClientsReducer.data;
      returnState.clientClinicDataTime = new Date();
    }
  }
  if (state.ClientsReducer.action == "UPDATE_DRAW_DAY") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.clear();
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.newMembershipData = state.ClientsReducer.data;
      returnState.newMembershipDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "SAVE_HEALTH_PRO_PRES") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.newPresData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.newPresData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SAVE_PHARMACY") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.pharmacyData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.pharmacyData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GENERATE_PRESC_PDF") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.generatePDFData = state.ClientsReducer.data;
    } else {
      returnState.generatePDFData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "FAX_PRESC_PDF") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.faxData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.faxData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "DOWNLOAD_MEMBERSHIP_AGREEMENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.downloadMembershipAgreementData =
        state.ClientsReducer.data.data;
    }
  }

  if (state.ClientsReducer.action === "CLIENT_PROCEDURES_DATA") {
    if (state.ClientsReducer.data) {
      if (state.ClientsReducer.data.status !== 200) {
        returnState.procedureDataText = false;
      } else {
        returnState.clientProcedureData = state.ClientsReducer.data.data;
        returnState.clientProcedureDataTime = new Date();
      }
    } else {
      returnState.procedureDataText = false;
    }
  }

  if (state.ClientsReducer.action === "READ_CLIENT_PROFILE_AND_PROOCURE_DATA") {
    if (state.ClientsReducer.clientProfileData) {
      if (state.ClientsReducer.clientProfileData.status !== 200) {
        uiNotification.error(
          languageData.global[state.ClientsReducer.clientProfileData.message],
        );
        returnState.showLoader = false;
      } else {
        returnState.clientData = state.ClientsReducer.clientProfileData.data;
      }
    } else {
      returnState.showLoader = false;
    }

    if (state.ClientsReducer.clientProcedureData) {
      if (state.ClientsReducer.clientProcedureData.status !== 200) {
        uiNotification.clear();
        uiNotification.error(
          languageData.global[state.ClientsReducer.clientProcedureData.message],
        );
        returnState.procedureDataText = false;
      } else {
        returnState.clientProcedureData =
          state.ClientsReducer.clientProcedureData.data;
        returnState.clientProcedureDataTime = new Date();
      }
    } else {
      returnState.procedureDataText = false;
    }
  }

  if (state.ClientsReducer.action === "IS_RELOAD_CLIENT_PROCEDURES") {
    if (state.ClientsReducer.isReloadClientProcedure === true) {
      returnState.isReloadClientProcedure = true;
    }
  }

  if (state.ClientsReducer.action === "VERIFY_PORTAL_EMAIL") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.verifyPortalEmailData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_CLEARENT_KEY") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.clearentKey = state.ClientsReducer.data;
      returnState.clearentKeyTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "GET_SCRIPT_SURE_PATIENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.scriptSureData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SAVE_SCRIPT_SURE_PATIENT") {
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.scriptSureData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_APPOINTMENT_TIMELINE") {
    uiNotification.clear();
    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.appointmentData = state.ClientsReducer.data;
      returnState.appointmentDataTime = new Date();
    }
  }

  if (state.ClientsReducer.action === "SEND_POST_INSTRUCTION_FOR_APPOINTMENT") {
    uiNotification.clear();

    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "SEND_PRE_INSTRUCTION_FOR_APPOINTMENT") {
    uiNotification.clear();

    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.sendPostInsData = state.ClientsReducer.data;
    }
  }
  if (state.ClientsReducer.action === "CLOSE_PRESCRIPTION_IFRAME") {
    uiNotification.clear();

    if (state.ClientsReducer.data.status !== 200) {
      uiNotification.error(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.closeIframeData = state.ClientsReducer.data;
    } else {
      uiNotification.success(
        languageData.global[state.ClientsReducer.data.message],
      );
      returnState.closeIframeData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_CLIENT_DETAIL") {
    if (state.ClientsReducer.clientProfileData) {
      if (state.ClientsReducer.clientProfileData.status !== 200) {
        uiNotification.error(
          languageData.global[state.ClientsReducer.clientProfileData.message],
        );
        returnState.showLoader = false;
      } else {
        returnState.clientData = state.ClientsReducer.clientProfileData.data;
      }
    } else {
      returnState.showLoader = false;
    }
  }

  if (state.ClientNotesReducer.action === "GET_CLIENT_NOTES") {
    returnState.customerNotes =
      state.ClientNotesReducer?.data?.data?.patient_notes || [];
    returnState.customerNotesFetchedAt = new Date();
  }

  returnState.ClientsReducer = state.ClientsReducer;
  returnState.planDetailsStatus = state.ClientsReducer.planDetailsStatus;
  returnState.paymentDetailData = state.ClientsReducer.paymentDetail;
  returnState.isQualiphyEnabled = state.ClientsReducer.isQualiphyEnabled;
  returnState.stripeIntent = state.StripeReducer.stripeIntent;
  returnState.cardList = state.StripeReducer.cardList;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetMembershipDetailsData,
      getMembershipPaymentDetails,
      getClientProfileDetail,
      fireClient,
      doNotDisturb,
      changePortaAccess,
      getClientCardData,
      saveClientCard,
      resendWelcomeEmail,
      resetPortalPassword,
      deleteClient,
      exportClientPDF,
      sendPostInstructions,
      saveProcedurePrescription,
      deleteProcedurePrescription,
      sendProcedurePrescription,
      emptyProcedureReducer,
      getMembershipData,
      addMonthyMembership,
      cancelMembership,
      applyCouponCode,
      updateMembershipCC,
      signProcedure,
      exportEmptyData,
      emptyMembershipWallet,
      getMembershipMultiTier,
      pastDueInvoiceData,
      changeMembershipClinic,
      updateMembershipHoldStatus,
      searchProduct,
      getTreatmentTemplates,
      getTemplateDetails,
      savePAYGTreatmentPlan,
      getPlanDataByID,
      saveMonthlyTreatmentPlan,
      getProgramData,
      applyStartProgramDiscount,
      changeTreatmentPlanClinic,
      viewPriceBreakUp,
      startTreatmentProgram,
      cancelTreatmentPlan,
      savePlanAsTemplate,
      updatePlanCard,
      getPrescribeOnlyDetails,
      updatePrescribeOnly,
      doThisAction,
      getNewMembershipData,
      newMembershipType,
      fetchAgreement,
      changeMembershipDrawDay,
      saveClinicOfClient,
      saveNewPrescription,
      savePharmacy,
      generatePrescriptionPDF,
      faxPrescriptionPDF,
      downloadMembershipAgreement,
      readClientProfileData,
      writeClientProcedureData,
      readClientProcedureData,
      readClientProfileAndProcedureData,
      verifyPortalEmail,
      checkScriptSurePatient,
      createScriptSurePatient,
      getClearentKey,
      getAppointmentData,
      sendPreInstructionsInAppointment,
      onClosePrescriptionIframe,
      getMembershipPaymentDetailsWithCoupon,
      geClientNotes,
      getSetupIntent,
      getSetupIntentForAccount,
      getClientCardDataStripe,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withGlobalSettings(withCurrentAccountQuery(ClientProfile))));
